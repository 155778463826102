import {handlePlanDate} from "../../utils/estimateUtils";
import {ROLECODES, RoleName} from "../../utils/getRoleName";

interface StatusPanelParams{
    complaintTopics: any[];
    complaintCardObject: any;
    roleObject: {roleId: number, roleName: RoleName};
    setComplaintTopicHandler: any;
	openControlPanelButtonEnable: boolean;
	setOpenControlPanelHandler: any;
}

const StatusPanel = (params: StatusPanelParams): JSX.Element => {
  const {complaintTopics, 
		 complaintCardObject, 
		 roleObject, 
		 setComplaintTopicHandler, 
		 openControlPanelButtonEnable,
		 setOpenControlPanelHandler} = params;
  let complaintTopicsObject: any[] = [];
  let complaintReason: string = "";
  if(typeof complaintTopics[Symbol.iterator] === "function"){
    const complaintTopicsObjectForeman = complaintTopics.filter((item: any) => item.id === 1);
    const complaintTopicsObjectBrigadier = complaintTopics.filter((item: any) => item.id === 2);
    if(roleObject?.roleId === ROLECODES.FOREMAN) complaintTopicsObject = complaintTopicsObjectForeman;        
    if(roleObject?.roleId === ROLECODES.BRIGADIER) complaintTopicsObject = complaintTopicsObjectBrigadier;

    complaintReason = complaintTopicsObjectForeman[0]?.items.filter((item: any) => item.id === complaintCardObject.topicId)[0]?.name ||
                    complaintTopicsObjectBrigadier[0]?.items.filter((item: any) => item.id === complaintCardObject.topicId)[0]?.name;
        
  }
  const complaintTopicsArray = complaintTopicsObject[0]?.items || []; 
  return (
    <div className="bar__status">
		<div>
            <div className="theme-complaint">{complaintReason}</div>
			<div className="theme-complaint"></div>
			<select name={`themes__${roleObject.roleName}`} className="selected" onChange={setComplaintTopicHandler}>
                <option selected disabled>(выберите тему спора)</option>
                {complaintTopicsArray.map((item: any) => {
                    return(<option value={item.id} key={item.id}>{item.name}</option>);
                })}
			</select>
		</div>
		<div className="text__status--complaint"></div>
		<button
			name="control__manage-complaint"
			type="button"
			title="Выводит сектор управления Спором"
			disabled={!openControlPanelButtonEnable}
			onClick={setOpenControlPanelHandler}
		>Ответить</button>
		<div>
			<span>Период спора</span>
			<span>{handlePlanDate(complaintCardObject.startDate, false)}</span>
			<span className="symbol__mdash--before"></span>
			<span className="symbol__hellip--before"></span>
			<span>{handlePlanDate(complaintCardObject.endDate, false)}</span>

		</div>
		<div>
			<span>Период простоя</span>
			<span>{handlePlanDate(complaintCardObject.downtimeStartDate, false)}</span>
			<span className="symbol__mdash--before"></span>
			<span className="symbol__hellip--before"></span>
			<span>{handlePlanDate(complaintCardObject.downtimeEndDate, false)}</span>
		</div>
		<div title="Продолжительность простоя">
			<span>Простой</span>
			<span className="symbol__mdash--before"></span>
			<span>
				<span>{complaintCardObject.downtime}</span> <span>дн.</span>
			</span>
		</div>
	</div>
  );
};

export default StatusPanel;