import React, {useCallback, useState} from 'react';
import styles from './MutualListBuilding.module.scss';
import {IBillingBuilding} from "../../../../../../types/Billing";
import {formatPrice} from "../../../../../../utils/formatPrice";
import MutualListEstimate from "../MutualListEstimate/MutualListEstimate";

interface MutualListBuildingProps {
  building: IBillingBuilding
}

function MutualListBuilding({building}: MutualListBuildingProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div className={styles.buildingSection}>
      <div className={`${styles.buildingRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <a href="/_" target="_blank" title="Карточка объекта">{building.name}</a>
          <span>{building.comment}</span>
        </div>
        <div></div>
        <div>{building.paidPlatform ? formatPrice(building.paidPlatform) : ''}</div>
        <div>{building.paidBrigade ? formatPrice(building.paidBrigade) : ''}</div>
        <div>{building.actPlatform ? formatPrice(building.actPlatform) : ''}</div>
        <div>{building.actBrigade ? formatPrice(building.actBrigade): ''}</div>
      </div>

      {isOpen && (
        <>
          {building.subItems.map(estimate => (
            <MutualListEstimate key={estimate.entityId} estimate={estimate}/>
          ))}
        </>
      )}
    </div>
  );
}

export default MutualListBuilding;