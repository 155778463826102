import React, {useCallback, useState} from 'react';
import styles from './MutualListContract.module.scss';
import {IBillingContract} from "../../../../../../types/Billing";
import {formatPrice} from "../../../../../../utils/formatPrice";
import {parseDateString, parseInputDate} from "../../../../../../utils/estimateUtils";

interface MutualListContractProps {
  contract: IBillingContract
}

function MutualListContract({contract}: MutualListContractProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div className={styles.contractSection}>
      <div className={`${styles.contractRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <a href="/_" target="_blank" title="Карточка наряда">{contract.name}</a>
        </div>
        <div></div>
        <div>{contract.paidPlatform ? formatPrice(contract.paidPlatform) : ''}</div>
        <div>{contract.paidBrigade ? formatPrice(contract.paidBrigade) : ''}</div>
        <div>{contract.actPlatform ? formatPrice(contract.actPlatform) : ''}</div>
        <div>{contract.actBrigade ? formatPrice(contract.actBrigade): ''}</div>
      </div>

      {isOpen && (
        <div className={styles.billsList}>
          {contract.subItems.map(item => (
            <div className={styles.billsRow} key={item.entityId}>
              <div className="symbol__bull--before"></div>
              <div>{item.name}</div>
              <div title={parseInputDate(item.date)}>{parseDateString(item.date)}</div>
              <div>{item.paidPlatform ? formatPrice(item.paidPlatform) : ''}</div>
              <div>{item.paidBrigade ? formatPrice(item.paidBrigade) : ''}</div>
              <div>{item.actPlatform ? formatPrice(item.actPlatform) : ''}</div>
              <div>{item.actBrigade ? formatPrice(item.actBrigade): ''}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MutualListContract;