import React, {useState} from 'react';
import styles from './Invoices.module.scss';
import {IBilling} from "../../../../types/Billing";
import {formatPrice} from "../../../../utils/formatPrice";
import {handleDate} from "../../../../utils/estimateUtils";

interface InvoicesProps {
  billingData: IBilling
}

function Invoices({billingData}: InvoicesProps) {
  const [selectedItem, setSelectedItem] = useState<string>('1');

  const handleSelectItem = (itemId: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedItem(itemId);
  };

  return (
    <div>
      <div className={styles.invoicesCaptions}>
        <div title="Номер и дата документа">Счёт</div>
        <div title="Дата документа">Дата</div>
        <div>Сумма</div>
        <div title="Документ, на основании которого выставлен Счёт">Основание</div>
      </div>

      {billingData.paymentInvoices?.map(invoice => (
        <div
          className={`${styles.invoice} ${selectedItem === invoice.entityId ? styles.selected : ''}`}
          onClick={handleSelectItem(invoice.entityId)}
        >
          <div>
            <a href={`/invoice/${invoice.entityId}`} target="_blank" title="Открыть файл PDF">{invoice.name}</a>
          </div>
          <div>{handleDate(invoice.date)}</div>
          <div>{formatPrice(invoice.amount)}</div>
          <div>{invoice.comment}</div>
        </div>
      ))}
    </div>
  );
}

export default Invoices;