import React, {useState} from 'react';
import Modal from "../../../../../Modal/Modal";
import styles from './CreateBankAccount.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {IProfileState} from "../../../../../../redux/profile/types";
import {saveBankAccount} from "../../../../../../redux/profile/profileActions";
import {isValidPaymentAccount, isValidCorrespondentAccount, isValidBik} from "../../../../../../utils/validators";

export interface IAccountData {
  bankName: string
  paymentAccount: string
  correspondentAccount: string
  bik: string
  escrow: boolean
}

interface CreateBankAccountProps {
  onClose: () => void
}

function CreateBankAccount({onClose}: CreateBankAccountProps) {
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const [creating, setCreating] = useState<boolean>(false);
  const [newAccountData, setNewAccountData] = useState<IAccountData>({
    bankName: '',
    paymentAccount: '',
    correspondentAccount: '',
    bik: '',
    escrow: false
  });

  const dispatch = useDispatch<any>();

  const handleChangeField = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAccountData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const handleChangeEscrow = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAccountData(prev => ({
      ...prev,
      escrow: !prev.escrow
    }));
  };

  const handleCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (creating) {
      return;
    }

    setCreating(true);

    dispatch(saveBankAccount({
      contractorId: profile.data?.contractor.id,
      ...newAccountData
    })).then(() => {
      onClose();
    });
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose();
  };

  const isAccountInList = (paymentAccount: string): boolean => {
    const bankAccounts = profile.data?.bankAccounts?.map(bankAccount => bankAccount.paymentAccount);
    return !!bankAccounts && bankAccounts.includes(paymentAccount);
  }

  const isValid = newAccountData.bankName
    && isValidPaymentAccount(newAccountData)
    && isValidCorrespondentAccount(newAccountData)
    && isValidBik(newAccountData.bik)
    && !isAccountInList(newAccountData.paymentAccount);

  return (
    <Modal title="Добавление счета">
      <div className={styles.createAccountModal}>
        <div className={styles.createAccountModalNotification}>
          <p>
            Все поля реквизитов банка должны быть заполнены.
          </p>
          <p>
            Счёт с типом «эскроу» может быть только один.<br/>
            Флаг «Эскроу-счёт» недоступен, если эскроу-счёт уже есть.
          </p>
        </div>
        <form className={styles.createAccountModalContent}>
          <label>Банк<br/>(юридическое наименование)</label>
          <input
            className={!newAccountData.bankName ? styles.error : ""}
            type="text"
            placeholder="(обязательное)"
            value={newAccountData.bankName}
            onChange={handleChangeField('bankName')}
          />

          <label>БИК</label>
          <input
            className={!isValidBik(newAccountData.bik) ? styles.error : ""}
            type="text"
            placeholder="(обязательное)"
            value={newAccountData.bik}
            onChange={handleChangeField('bik')}
          />

          <label>Расчётный<br/>счёт</label>
          <input
            className={!isValidPaymentAccount(newAccountData) ? styles.error : ""}
            type="text"
            placeholder="(обязательное)"
            value={newAccountData.paymentAccount}
            onChange={handleChangeField('paymentAccount')}
          />
          <span>
            {isAccountInList(newAccountData.paymentAccount) ? "Этот счет уже есть в списке" : ""}
          </span>

          <label>Корреспондентский<br/>счёт</label>
          <input
            className={!isValidCorrespondentAccount(newAccountData) ? styles.error : ""}
            type="text"
            placeholder="(обязательное)"
            value={newAccountData.correspondentAccount}
            onChange={handleChangeField('correspondentAccount')}
          />

          <label>Эскроу-счёт</label>
          <input
            type="checkbox"
            disabled={profile.data?.hasEscrow}
            checked={newAccountData.escrow}
            onChange={handleChangeEscrow}
          />

          <hr/>

          <button type="button" disabled={!isValid || creating} onClick={handleCreate}>Добавить</button>
          <button type="button" onClick={handleClose}>Отмена</button>
        </form>
      </div>
    </Modal>
  );
}

export default CreateBankAccount;