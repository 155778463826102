import {useState} from 'react';
import {UploadFile} from "../../../utils/uploadFileType";

interface ComplaintMessageParams{
    setCloseControlPanelHandler: any,
    uploadFiles: UploadFile[];
    setUploadFilesHandler: any;
    setPostMessageHandler: any;
    sendPostMessageHandler: any;
    isSending: boolean;
    sendError: boolean;
}

const ComplaintMessage = (params: ComplaintMessageParams): JSX.Element => {
  const {setCloseControlPanelHandler, 
         uploadFiles, 
         setUploadFilesHandler, 
         setPostMessageHandler,
         sendPostMessageHandler,
         isSending,
         sendError} = params;
  const [sendButtonEnable, setSendButonEnable] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>();
  const [comment, setComment] = useState<string>("");
  const fileAddLoadHandler = () => {
    if(getUploadFileId(uploadFiles, getFileId(file)) >= 0) return;
    const uploadFile: UploadFile = {file: file, comment: comment};
    uploadFiles.push(uploadFile);
    setUploadFilesHandler(uploadFiles);
  }
  const setFileNameHandle = (e: any) => {
    setFile(e.target.files[0]);
  }
  const changeCommentHandler = (e: any) => {
    const uploadFileId = getUploadFileId(uploadFiles, e.target.id);
    if(uploadFileId >= 0){
        uploadFiles[uploadFileId].comment = e.target.value;
        setUploadFilesHandler(uploadFiles);
    }
  }
  const deleteFileHandler = (e: any) => {
    const uploadFileId = getUploadFileId(uploadFiles, e.target.id);
    if(uploadFileId >= 0){
        uploadFiles.splice(uploadFileId, 1);
        setUploadFilesHandler(uploadFiles);
    }
  }
  const messageTextChangeHandle = (e: any) => {
    setSendButonEnable(e.target.value);
    setPostMessageHandler(e.target.value);
  }
  return (
    <div className={`manage-complaint__message 
                    ${isSending ? "manage-complaint__message--sending" : ""}
                    ${sendError ? "manage-complaint__message--senderror" : ""}`}>
    <div>
        <textarea placeholder="(введите текст сообщения)" onChange={messageTextChangeHandle}></textarea>
        <button
            name="control__manage-add"
            type="button"
            title="Добавление «реплики»"
            disabled={!sendButtonEnable}
            onClick={sendPostMessageHandler}
        >Добавить сообщение и выбранные файлы</button>
        <button
            name="control__manage-close"
            type="button"
            title="Сворачивание сектора без сохранения изменений"
            onClick={setCloseControlPanelHandler}
        >Отмена</button>
    </div>
    <div>
        <form>
            <input
                name="input__manage-file"
                type="file" 
                onChange={setFileNameHandle}/>
            <span className="symbol__colon--after">Комментарий</span>
            <input
                name="input__manage-comment-to-load"
                type="text"
                placeholder="(необязательно)" 
                onChange={(e) => setComment(e.target.value)}/>
            <button
                className="bttn__small"
                name="control__manage-file-to-load"
                type="button"
                title="Добавить файл в список загрузки"
                disabled = {!file}
                onClick = {fileAddLoadHandler}
            >К загрузке</button>
        </form>
        <div>
            {uploadFiles.map((uploadFile: UploadFile, key) => {
                return (<div className="control__manage-files" key={key}>
                    <span className="symbol__bull--before"></span>
                    <span className="limit__text--length">{uploadFile.file?.name}</span>
                    <input
                        name="input__manage-file-comment"
                        type="text"
                        placeholder="(необязательно)" 
                        id={getFileId(uploadFile.file)}
                        value={uploadFile.comment}
                        onChange={changeCommentHandler}/>
                    <button
                        className="bttn__small"
                        name="control__manage-delete-file"
                        type="button"
                        id={getFileId(uploadFile.file)}
                        title="Удалить файл из списка загрузки"
                        onClick={deleteFileHandler}
                    >Удалить</button>
                </div>)})
            }
        </div>
    </div>
</div>
  );
};

export default ComplaintMessage;

const getUploadFileId = (uploadFiles: UploadFile[], id: any): number => {
    for(let i = 0; i < uploadFiles.length; i++){
        if(getFileId(uploadFiles[i].file) === id) return i;
    }
    return -1;
}

const getFileId = (file: File | undefined): string => {
    if(!file) return "";
    return file.name + String(file.lastModified);
}