import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Specification} from "./Specification/Specification";
import {TechnicalTask} from "./TechnicalTask/TechnicalTask";
import {DrawingWorkingDocumentation} from "./DrawingWorkingDocumentation/DrawingWorkingDocumentation";
import {TechnicalTaskBreakdown} from "./TechnicalTaskBreakdown/TechnicalTaskBreakdown";
import {Contract} from "./Contract/Contract";
import {History} from "./History/History";
import {StatusBar} from "./StatusBar/StatusBar";

import style from './estimateManagement.module.scss';
import {Equipment} from "./Equipment/Equipment";
import FooterList from "../../components/BuildingsList/FooterList";
import {ModalAddBreakdown} from "./StatusBar/ModalAddBreakdown/ModalAddBreakdown";
import {useDispatch, useSelector} from "react-redux";
import {
  getEstimateById,
  getStatusesEstimate, setModal,
  setModeSuccess
} from "../../redux/estimate/actionEstimate";
import {useParams} from "react-router";
import {ModalChangeRequisites} from "./StatusBar/ModalChangeRequisites/ModalChangeRequisites";
import {getNameOfEstimate, parseDateString} from "../../utils/estimateUtils";
import {getBuildings} from "../../redux/actions/actionContractor";
import {WorkSchedule} from "./WorkSchedule/WorkSchedule";
import {getRoleTitle} from "../../utils/getRoleName";
import estimateJobParams from '../estimateJob-page/estimateJobParams';

const EstimateManagement = () => {
  const state = useSelector((state: any) => state.estimateReducer);
  const buildings = useSelector((state: any) => state.contractorReducer);
  const user = useSelector((state: any) => state.userReducer.user);
  const {buildingsObject, contractorInfo, estimateTypes} = buildings
  const {isLoading, estimate, modal, mode, filesAdded, modeEstimator } = state
  const dispatch = useDispatch<any>()
  const {params} = useParams<any>();
  const [modalChange, setModalChange] = useState(false);
  const [editMode, setEditReq] = useState<boolean>(false);
  const [object, setObject] = useState<any>(null);

  const id = estimateJobParams(params).id;
  useEffect(() => {
    dispatch(getStatusesEstimate());
    if (!estimate?.info.length) {
      // if (user?.role === 1) {
      // } else {
      //   dispatch(getEstimatorEstimate(id))
      // }
      dispatch(getEstimateById(id));
      dispatch(getBuildings());
    }
  }, [user, dispatch, id, estimate?.info.length]);


  useEffect(() => {
    if (estimate?.info?.id && buildingsObject.length !== 0) {
      setObject(getNameOfEstimate(buildingsObject, estimate.info.id));
    }
  }, [estimate, buildingsObject])

  useEffect(() => {
    if (estimate) {
      const findMode = modeEstimator.find((el: { currentStateCode: number; }) => el.currentStateCode === estimate?.info?.estimateStatus);
      dispatch(setModeSuccess(findMode));
    }
  }, [mode, estimate, modeEstimator, dispatch])

  const setEditMode = (payload: boolean) => {
    dispatch(setModeSuccess(payload))
  }

  const setModalAddBreakdown = (payload: boolean) => {
    dispatch(setModal(payload))
  }

  if (isLoading || !mode) {
    return <div><h1>LOADING...</h1></div>;
  }

  return (
        <div className={style.main}>
          <div className={style.header}>
            <span className={style.blockName}>Карточка сметы{mode?.visible && ': редактирование'}</span>
            <span className={style.blockNameSecond}>{getRoleTitle(user?.role)}</span>
          </div>
          <div className={style.barRequest}>
            <div>
              <div className="limit__text--length">
                <a className={`link-light ${style.linkObject}`} href="/_" target="_blank"
                   title="Карточка объекта (переход)">{object?.building?.name || ''}</a>
              </div>
              <div className={`limit__text--length ${style.addressObject}`}>
                {object?.building?.address || ''}
              </div>
            </div>
            <div>
              <div className={style.nameEstimate}>
                {estimate.info.title}
              </div>
              <div className={style.typeEstimate}>
                {estimate?.info?.types || estimate?.info?.type}
              </div>
            </div>
            <div title="Примечание к Смете" className={style.note}>
              {estimate.info.estimateNote}
            </div>
            <div title="Даты: Старт — Финиш" className={style.PlannedDates}>
              <span style={{paddingLeft: '10px'}}>Плановые сроки</span>
              <div>
                <span style={{fontSize: '14px', fontWeight: 'bold'}}>{parseDateString(estimate.documents[0]?.estimate?.workStartPlan)}</span>
                <span style={{paddingLeft: '10px', fontWeight: 'bold', paddingRight: '10px'}}
                      className="symbol__mdash--before"></span>
                <span style={{fontSize: '14px', fontWeight: 'bold'}}>{parseDateString(estimate.documents[0]?.estimate?.workEndPlan ?? estimate.info.jobPlanDate)}</span>
              </div>
            </div>
          </div>
          <div>
            <StatusBar
                employees={contractorInfo.employees}
                bankAccounts={contractorInfo.bankAccounts}
                id={id}
                filesAdded={filesAdded}
                setModalChange={setModalChange}
                data={estimate.info}
                modeStatus={mode}
                setEditMode={setEditReq}
                modeEstimator={modeEstimator}
            />
          </div>
          <div className={style.blocks}>
            <div>
              <Specification modeStatus={editMode} state={editMode} estimate={estimate}/>
              <TechnicalTask modeStatus={editMode} state={editMode} estimate={estimate}/>
              <DrawingWorkingDocumentation modeStatus={editMode} state={editMode} estimate={estimate}/>
              <WorkSchedule modeStatus={editMode} state={editMode} estimate={estimate}/>
            </div>
            <div>
              <TechnicalTaskBreakdown state={editMode} estimate={estimate}
                                      estiamateCode={estimate.info.estimateStatus}/>
              <Contract estiamateCode={estimate.info.estimateStatus} state={editMode} estimate={estimate}/>
              <History state={mode?.visible} estimate={estimate}/>
            </div>
          </div>
          <Equipment estimate={estimate.info.content ? estimate.info.content : []}/>
          <ModalAddBreakdown estimateTypes={estimateTypes} estimate={estimate} isOpen={modal} setModal={setModalAddBreakdown}/>
          <ModalChangeRequisites isOpen={modalChange} setModal={setModalChange}/>
        </div>
  )
}

export {EstimateManagement};