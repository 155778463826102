import style from "../../pages/EstimateManagement/Specification/specification.module.scss";
import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {saveEstimateFile, setNewFiles} from "../../redux/estimate/actionEstimate";

const DownloadFiles = ({estimate, type}: any) => {

  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [fileResponse, setFileResponse] = useState<any>([]);
  const dispatch = useDispatch<any>();

  const fileRef = useRef<any>();

  const setNewFile = async (e: any) => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('files', e.target.files[0]);
    const result = await dispatch(saveEstimateFile(formData));
    setLoading(true);
    setFileResponse(result);
  };

  function randomString(len: number) {
    const charSet =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  const addDocument = async () => {
    if (file) {
      const data = {
        description: description,
        documentType: type,
        url: '123',
        originalFileName: file?.name,
        fileId: fileResponse[0].fileId,
        contentType: fileResponse[0].contentType,
        estimateId: `${estimate.info.id}`,
        json: 'json',
        added: true,
        id: randomString(36),
      }
      dispatch(setNewFiles(data));
      fileRef.current.value = '';
      setFile('');
      setDescription('');
      setLoading(false);
      setFileResponse([]);
    }
  }

  return (
      <div>
        <form className={style.form}>
          <input type="file" ref={fileRef} onChange={setNewFile} />
          <div style={{margin: '8px 0 6px 16px', display: 'flex'}}>
            <span className={style.span}>Комментарий: </span>
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} className={style.textarea} placeholder="(указание необязательно)"></textarea>
          </div>
          <button disabled={!loading} onClick={addDocument} className="bttn__small" type="button">Добавить</button>
        </form>
      </div>
  )
};

export { DownloadFiles }