import {useState} from 'react';
import {getComplaintSide} from "../../utils/getComplaints";
import FileCard from "./FileCard";

const Parties = (params: any): JSX.Element => {
  const [openFilePanel, setOpenFilePanel] = useState<boolean>(false);
  const {authorFio, RespondentFio, messages, respondentRole} = params.complaintCardObject;
  const toggleFilePanelHandler = () => {
    setOpenFilePanel(!openFilePanel);
  }
  let initiatorDocuments = [];
  let respondentDocuments = [];
  if(messages){  
    initiatorDocuments = messages.filter(
        (item: any) => {return (getComplaintSide(item.message.authorRole, respondentRole) === "initiator")}
    );
    respondentDocuments = messages.filter(
        (item: any) => {return (getComplaintSide(item.message.authorRole, respondentRole) === "responder")}
    );
  };

  return (
	<div className="complaint__parties">
		<div>
			<span className="symbol__colon--after">Инициатор</span>
			<span>{authorFio}</span>
		</div>
		<div>
			<span className="symbol__colon--after">Ответчик</span>
			<span>{RespondentFio}</span>
		</div>
		<div className={`complaint__files ${openFilePanel ? "open" : ""}`}>
			<div className="section__header" onClick={toggleFilePanelHandler}>
				<span className="circle-triangle" title="Развернуть / Свернуть"></span>
				<span>Файлы сторон</span>
			</div>
			<div className="section__files--initiator">
        {initiatorDocuments.map((message: any) => {
          return message.documents.map((document: any, key: number) => {
            return (<FileCard fileCardObject = {document} key = {key}></FileCard>)
          })
        })}
			</div>
			<div className="section__files--responder">
        {respondentDocuments.map((message: any) => {
          return message.documents.map((document: any, key: number) => {
            return (<FileCard fileCardObject = {document} key = {key}></FileCard>)
          })
        })}
			</div>
		</div>
	</div>
  );
};

export default Parties;