import React, { Fragment, Suspense, useEffect, useState } from "react";
import Config from "../../../config.js";
import Input from "react-phone-number-input/input";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import style from './contractor-profile.component.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {addNewContractorBankAccount, getContractorInfo} from "../../../redux/actions/actionContractor";
import {getUser} from "../../../redux/user/actionUser";
import {baseNotification} from "../../../utils/estimateUtils";
import axios from "axios";

interface Foreman {
  contractorEmployeeId: string;
  lastName: string;
  firstName: string;
  secondName: string;
  email: string;
  phone: string;
  login: string;
}

interface BankAccount {
  id: string;
  bankName: string;
  paymentAccount: string;
  correspondentAccount: string;
  bik: string;
  escrow: boolean;
  deletedAt: string;
}

interface Employee {
  contractorEmployeeId: string;
  userId: string;
  lastName: string;
  firstName: string;
  secondName: string;
  email: string;
  phone: string;
  login: string;
  role: number;
  contractorId: string;
  deletedAt: string;
}

interface State {
  login: string;
  email: string;
  role: number;
  active: boolean;
  phone: string;
  lastName: string;
  firstName: string;
  secondName: string;
  id: string;
  createdAt: string;
  deletedAt: string;
  deletedBy: string;
  deletedReason: string;
  inn: string;
  kpp: string;
  ogrn: string;
  oktmo: string;
  okpo: string;
  legalAddress: string;
  actualAddress: string;
  signatoryLastName: string;
  signatoryFirstName: string;
  signatorySecondName: string;
  signatoryPost: string;
  signatoryReason: string;
  companyName: string;
  companyEmail: string;
  companyPhone: string;
  status: number;
  rating: number;
  latitude: number;
  longitude: number;
  contractorId: string;
  employees: Employee[];
  bankAccounts: BankAccount[];
  hasEscrow: boolean;
}

export default function ContractorProfile(): JSX.Element {
  const token = JSON.parse(localStorage.getItem("user") as string);
  const [newForemanData, setNewForemanData] = useState<Foreman>({
    contractorEmployeeId: "",
    lastName: "",
    firstName: "",
    secondName: "",
    login: "",
    email: "",
    phone: "",
  });
  const [newBankAccount, setNewBankAccount] = useState<BankAccount>({
    id: "",
    bankName: "",
    paymentAccount: "",
    correspondentAccount: "",
    bik: "",
    escrow: false,
    deletedAt: "",
  });
  const [foremanReg, setForemanReg] = useState<boolean>(false);
  const [addNewBankAccount, setAddNewBankAccount] = useState<boolean>(false);
  const [sameAddress, setSameAddress] = useState<boolean>(true);
  const [screenType, setScreen] = useState<string>("0");
  const contractor = useSelector((state: any) => state.contractorReducer.contractorInfo);
  const [fieldDisabled, setFielDisabled] = useState<boolean>(true);
  const [rerender, setRerender] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [foremenIDs, setForemenIDs] = useState<string>("");
  const [bankAccountIDs, setBankAccountIDs] = useState<string>("");
  const [isForemenDeleteDisabled, setForeDisabled] = useState<boolean>(true);
  const [isBankDeleteDisabled, setBankDisabled] = useState<boolean>(true);

  let user: any = {};
  const [values, setValues] = useState<State>({
    login: "",
    email: "",
    role: 0,
    active: false,
    phone: "",
    lastName: "",
    firstName: "",
    secondName: "",
    id: "",
    createdAt: "",
    deletedAt: "",
    deletedBy: "",
    deletedReason: "",
    inn: "",
    kpp: "",
    ogrn: "",
    oktmo: "",
    okpo: "",
    legalAddress: "",
    actualAddress: "",
    signatoryLastName: "",
    signatoryFirstName: "",
    signatorySecondName: "",
    signatoryPost: "",
    signatoryReason: "",
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    status: 0,
    rating: 1,
    latitude: 0,
    longitude: 0,
    contractorId: "",
    employees: [],
    bankAccounts: [],
    hasEscrow: false,
  });

  const [tempValues, setTempValues] = useState<State>(values);
  const handleRadioChange = (event: string) => {
    setScreen(event);
  };

  const sendContractorInfo = async () => {
    const result = await dispatch(getContractorInfo());
    await getInfo(result);
  }

  // @ts-ignore
  useEffect(() => {
    (async () => {
      //dispatch(getUser());
      await sendContractorInfo();
    })()
  }, []);

  const getInfo = async (contractorInfo?: any) => {
    console.log('contractorInfo', contractorInfo);
    // const response = await fetch(
    //   Config.BACKEND_ADDRESS + "Contractor/GetContractorInfo",
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token.token}`,
    //     },
    //     credentials: "include",
    //   }
    // );
    // const result = await response.json();
    // if (response.status === 200) {
    //   localStorage.setItem("contractor", JSON.stringify(result));
    //   user = JSON.parse(localStorage.getItem("contractor") as string);
    setValues({
      ...values,
      login: contractorInfo.responsible.login,
      email: contractorInfo.responsible.email,
      role: contractorInfo.responsible.role,
      active: contractorInfo.responsible.role,
      phone: contractorInfo.responsible.phone,
      lastName: contractorInfo.responsible.lastName,
      firstName: contractorInfo.responsible.firstName,
      secondName: contractorInfo.responsible.secondName,
      id: contractorInfo.responsible.id,
      createdAt: contractorInfo.responsible.createdAt,
      deletedAt: contractorInfo.responsible.deletedAt,
      deletedBy: contractorInfo.responsible.deletedBy,
      deletedReason: contractorInfo.responsible.deletedReason,
      inn: contractorInfo.contractor.inn,
      kpp: contractorInfo.contractor.kpp,
      ogrn: contractorInfo.contractor.ogrn,
      oktmo: contractorInfo.contractor.oktmo,
      okpo: contractorInfo.contractor.okpo,
      legalAddress: contractorInfo.contractor.legalAddress,
      actualAddress: contractorInfo.contractor.actualAddress,
      signatoryLastName: contractorInfo.contractor.signatoryLastName,
      signatoryFirstName: contractorInfo.contractor.signatoryFirstName,
      signatorySecondName: contractorInfo.contractor.signatorySecondName,
      signatoryPost: contractorInfo.contractor.signatoryPost,
      signatoryReason: contractorInfo.contractor.signatoryReason,
      companyName: contractorInfo.contractor.name,
      companyEmail: contractorInfo.contractor.email,
      companyPhone: contractorInfo.contractor.phone,
      status: contractorInfo.contractor.status,
      rating: contractorInfo.contractor.rating,
      latitude: contractorInfo.contractor.latitude,
      longitude: contractorInfo.contractor.longitude,
      contractorId: contractorInfo.contractor.id,
      employees: contractorInfo.employees,
      bankAccounts: contractorInfo.bankAccounts,
      hasEscrow: contractorInfo.hasEscrow,
    });
    setRerender(!rerender);
  };

  async function saveContractorInfo() {
    const response = await fetch(
        Config.BACKEND_ADDRESS + "Contractor/SaveContractor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          },
          credentials: "include",
          body: JSON.stringify({
            id: values.contractorId,
            inn: values.inn,
            kpp: values.kpp,
            ogrn: values.kpp,
            oktmo: values.oktmo,
            okpo: values.okpo,
            legalAddress: values.legalAddress,
            actualAddress: values.actualAddress || values.legalAddress,
            signatoryLastName: values.signatoryLastName,
            signatoryFirstName: values.signatoryFirstName,
            signatorySecondName: values.signatorySecondName,
            signatoryPost: values.signatoryPost,
            signatoryReason: values.signatoryReason,
            name: values.companyName,
            email: values.email,
            phone: values.companyPhone,
            status: values.status,
            rating: values.rating,
            latitude: values.latitude,
            longitude: values.longitude,
          }),
        }
    );
  }

  const handleChange = (prop: keyof State) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleForemanChange = (prop: keyof Foreman) => (event: any) => {
    setNewForemanData({ ...newForemanData, [prop]: event.target.value });
  };

  const handleBankChange = (prop: keyof BankAccount) => (event: any) => {
    setNewBankAccount({ ...newBankAccount, [prop]: event.target.value });
  };

  const handleForemenSelect = (prop: string) => {
    // let newIDs: string[] = foremenIDs
    // const index = newIDs.indexOf(prop)
    // if (index > -1) {
    // newIDs.splice(index, 1)
    // } else {
    //   newIDs.push(prop)
    // }
    if (prop === foremenIDs) {
      setForemenIDs("");
      setForeDisabled(true);
    } else {
      setForemenIDs(prop);
      setForeDisabled(false);
    }
    console.log(prop);
  };

  const handleBankAccountSelect = (prop: string) => {
    // let newIDs: string[] = bankAccountIDs
    // const index = newIDs.indexOf(prop)
    // if (index > -1) {
    //     newIDs.splice(index, 1)
    // } else {
    //   newIDs.push(prop)
    // }
    // setBankAccountIDs(newIDs);
    // if (newIDs.length > 0) {
    //   setBankDisabled(false)
    // } else {
    //   setBankDisabled(true)
    // }
    if (prop === bankAccountIDs) {
      setBankAccountIDs("");
      setBankDisabled(true);
    } else {
      setBankAccountIDs(prop);
      setBankDisabled(false);
    }
    console.log(prop);
  };

  function checkPhone() {}

  async function deleteForeman() {
    const response = await fetch(
        Config.BACKEND_ADDRESS + "Contractor/DeleteContractorEmployee",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          },
          credentials: "include",
          body: JSON.stringify({
            id: foremenIDs,
            deletedReason: "deleted by contractor",
          }),
        }
    ).then(() => getInfo());
  }

  async function deleteBankAccount() {
    const response = await fetch(
        Config.BACKEND_ADDRESS + "Contractor/DeleteContractorBankAccount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          },
          credentials: "include",
          body: JSON.stringify({
            id: bankAccountIDs,
            deletedReason: "deleted by contractor",
          }),
        }
    ).then(() => getInfo());
  }

  async function registerForeman() {
    try {
      await axios.post(
          Config.BACKEND_ADDRESS + "Contractor/SaveContractorEmployee",
          JSON.stringify({
            contractorId: values.contractorId,
            lastName: newForemanData?.lastName,
            firstName: newForemanData?.firstName,
            secondName: newForemanData?.secondName,
            email: newForemanData?.email,
            phone: newForemanData?.phone,
            login: newForemanData?.login,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.token}`,
            },
          }
      );
      await sendContractorInfo();
      baseNotification("Успешно", "Прораб добавлен", "success");
    } catch (e :any) {
      console.log(e);
      return baseNotification("Ошибка", e?.response?.data, "danger");
    }
  }

  async function addBankAccount() {
    const data = {
      contractorId: values.contractorId,
      bankName: newBankAccount.bankName,
      paymentAccount: newBankAccount.paymentAccount,
      correspondentAccount: newBankAccount.correspondentAccount,
      bik: newBankAccount.bik,
      escrow: newBankAccount.escrow
    }
    await dispatch(addNewContractorBankAccount(data));
  }

  const showModeForemen = (item: Employee, index: number): JSX.Element => {
    return (
        <div>
          <label
              className="container__line"
              title="Для выбора кликните по строке"
              key={index}
          >
            <div style={{display: 'flex'}}>
              <div>
                <input
                    type="radio"
                    name="line__select-foreman"
                    onClick={() => handleForemenSelect(item.contractorEmployeeId)}
                />
              </div>
              <div className={style.positionSpan}>
            <span>
              {item.lastName} {item.firstName} {item.secondName}
            </span>
                <span>{item.login}</span>
                <span>{item.email}</span>
                <span>{item.phone}</span>
              </div>
            </div>
          </label>
        </div>
    );
  };

  const ShowForemen = (): JSX.Element => {
    return (
        <Fragment>
          {values.employees.map(function (item: Employee, index: number) {
            if (item.deletedAt === null) {
              return <>{showModeForemen(item, index)}</>;
            } else {
              return <></>;
            }
          })}
        </Fragment>
    );
  };

  const showModeBank = (item: BankAccount, index: number): JSX.Element => {
    return (
        <>
          <label
              className="container__line"
              title="Для выбора кликните по строке"
          >
            <input
                type="radio"
                name="line__select-bank"
                onClick={() => handleBankAccountSelect(item.id)}
            />
            <span className="line__bank">
            <span>{item.bankName}</span>
            <span>{item.paymentAccount}</span>
            <span>{item.correspondentAccount}</span>
            <span>{item.bik}</span>
            <span className={item.escrow ? "escrow" : ""}></span>
          </span>
          </label>
        </>
    );
  };

  const ShowBank = (): JSX.Element => {
    return (
        <Fragment>
          {contractor.bankAccounts.map(function (item: BankAccount, index: number) {
            if (item.deletedAt === null) {
              return <>{showModeBank(item, index)}</>;
            } else {
              return <></>;
            }
          })}
        </Fragment>
    );
  };
  console.log(values.employees)
  return (
      <main className={style.main}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className={style.container}>
            <div onClick = {() => handleRadioChange('0')} className={`${screenType === '0' ? style.labelBlockActive : style.labelBlock}`}>
              <input
                  type="radio"
                  style={{display: 'none'}}
                  name="tab-control"
                  id="profile__tab--1"
                  value="0"
                  defaultChecked
              />
              <label className={`${screenType === '0' ? style.labelBlockActiveColor : style.labelBlockColor}`} htmlFor="profile__tab--1">
                Учётные данные
                <span></span>
              </label>
            </div>

            <div onClick = {() => handleRadioChange('1')}  className={`${screenType === '1' ? style.labelBlockActive : style.labelBlock}`}>
              <input
                  type="radio"
                  style={{display: 'none'}}
                  name="tab-control"
                  id="profile__tab--2"
                  value="1"
              />
              <label className={`${screenType === '1' ? style.labelBlockActiveColor : style.labelBlockColor}`} htmlFor="profile__tab--2">Прорабы</label>
            </div>

            <div onClick = {() => handleRadioChange('2')}  className={`${screenType === '2' ? style.labelBlockActive : style.labelBlock}`}>
              <input
                  type="radio"
                  style={{display: 'none'}}
                  name="tab-control"
                  id="profile__tab--3"
                  value="2"
              />
              <label className={`${screenType === '2' ? style.labelBlockActiveColor : style.labelBlockColor}`} htmlFor="profile__tab--3">Банковские счета</label>
            </div>
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          {screenType === "0" && (
              <form id="profile__content--1" className={`${style.whiteBackground} ${style.form}`}>
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Аккаунт</legend>

                  <div className={style.allField}>
                    <label className={style.label}>Логин</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="account-login"
                        placeholder="ContractorLogin"
                        value={values.login}
                        disabled
                    />

                    <label>Телефон</label>
                    <Input
                        className={style.inputForm}
                        name="tel"
                        country="RU"
                        withCountryCallingCode={true}
                        useNationalFormatForDefaultCountryValue
                        international={true}
                        error
                        value={values.phone}
                        disabled={fieldDisabled}
                        onChange={(value) => {
                          setValues({ ...values, phone: String(value) });
                        }}
                        placeholder="8 (000) 000-00-00"
                    />

                    <label>E-mail</label>
                    <input
                        className={style.inputForm}
                        type="email"
                        name="account-email"
                        placeholder="user138@gmail.ru"
                        value={values.email}
                        onChange={handleChange("email")}
                        disabled={fieldDisabled}
                    />
                  </div>
                </fieldset>

                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Ответственный от организации</legend>

                  <div className={style.allField}>
                    <label>Фамилия</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="account-surname"
                        placeholder="Подрядчиков"
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                        disabled={fieldDisabled}
                    />

                    <label>Имя</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="account-name"
                        placeholder="Юридиксий"
                        value={values.firstName}
                        onChange={handleChange("firstName")}
                        disabled={fieldDisabled}
                    />

                    <label>Отчество</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="account-patronymic"
                        placeholder="Лицоответственнович"
                        value={values.secondName}
                        onChange={handleChange("secondName")}
                        disabled={fieldDisabled}
                    />
                  </div>
                </fieldset>

                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Реквизиты организации</legend>

                  <div className={style.allField}>
                    <label>Наименование</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="company-name"
                        placeholder="Подрядчиков и компания"
                        value={values.companyName}
                        onChange={handleChange("companyName")}
                        disabled={fieldDisabled}
                    />

                    <label>ИНН</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="company-INN"
                        value={values.inn}
                        onChange={handleChange("inn")}
                        disabled={fieldDisabled}
                    />

                    <label>КПП</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="company-KPP"
                        value={values.kpp}
                        onChange={handleChange("kpp")}
                        disabled={fieldDisabled}
                    />

                    <label>ОКПО</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="company-OKPO"
                        value={values.okpo}
                        onChange={handleChange("okpo")}
                        disabled={fieldDisabled}
                    />

                    <label>ОГРН</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="company-OGRN"
                        value={values.ogrn}
                        onChange={handleChange("ogrn")}
                        disabled={fieldDisabled}
                    />

                    <label>ОКТМО</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="company-OKTMO"
                        value={values.oktmo}
                        onChange={handleChange("oktmo")}
                        disabled={fieldDisabled}
                    />

                    <div style={{display: 'flex', alignItems: 'center', width: "100%"}}>
                      <label>Адрес (юрид.)</label>
                      <input
                          style={{width: '100%', marginLeft: '28px'}}
                          className={style.inputForm}
                          type="text"
                          name="company-address"
                          value={values.legalAddress}
                          placeholder="СПб, Антоновская ул., д. 14, к. 2 литер А, пом. 1н офис 18"
                          onChange={handleChange("legalAddress")}
                          disabled={fieldDisabled}
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Подписант</legend>

                  <div className={style.allField}>
                    <label>Фамилия</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="office-surname"
                        placeholder="Подрядчиков"
                        value={values.signatoryLastName}
                        onChange={handleChange("signatoryLastName")}
                        disabled={fieldDisabled}
                    />

                    <label>Имя</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="office-name"
                        placeholder="Юридиксий"
                        value={values.signatoryFirstName}
                        onChange={handleChange("signatoryFirstName")}
                        disabled={fieldDisabled}
                    />

                    <label>Отчество</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="office-patronymic"
                        placeholder="Лицоответственнович"
                        value={values.signatorySecondName}
                        onChange={handleChange("signatorySecondName")}
                        disabled={fieldDisabled}
                    />

                    <label>Должность</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="office-job"
                        placeholder="Директор департамента"
                        value={values.signatoryPost}
                        onChange={handleChange("signatoryPost")}
                        disabled={fieldDisabled}
                    />

                    <label>Основание</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="office-basis"
                        placeholder="Устав организации"
                        value={values.signatoryReason}
                        onChange={handleChange("signatoryReason")}
                        disabled={fieldDisabled}
                    />

                    <label>Адрес (факт.)</label>
                    <input
                        className={style.inputForm}
                        type="text"
                        name="office-address"
                        placeholder="СПб, Антоновская ул., д. 14, к. 2 литер А, пом. 1н офис 18"
                        value={sameAddress ? values.legalAddress : values.actualAddress}
                        onChange={handleChange("actualAddress")}
                        disabled={fieldDisabled}
                    />

                    <div style={{display: 'flex', justifyContent: 'space-between', width: "100%"}}>
                      <div style={{alignItems: 'center', justifyContent: 'center'}}>
                        <input
                            style={{margin: '0'}}
                            className={style.inputForm}
                            type="checkbox"
                            name="office-match"
                            checked={sameAddress}
                            onChange={() => {
                              setSameAddress(!sameAddress);
                            }}
                            disabled={fieldDisabled}
                        />
                        <label className="clickable">
                          Совпадает с юридическим
                        </label>
                      </div>
                      <div>
                        <label>Телефон офиса</label>
                        <Input
                            className={style.inputForm}
                            name="office-tel"
                            country="RU"
                            withCountryCallingCode={true}
                            useNationalFormatForDefaultCountryValue
                            international={true}
                            value={values.companyPhone}
                            disabled={fieldDisabled}
                            onChange={(value) => {
                              setValues({ ...values, companyPhone: String(value) });
                            }}
                            placeholder="8 (931) 543-21-23"
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className={style.buttons}>
                  <button
                      type="button"
                      disabled={fieldDisabled}
                      onClick={() => {
                        setValues(tempValues);
                        setFielDisabled(true);
                      }}
                  >
                    Отмена
                  </button>
                  <button
                      type="button"
                      disabled={!fieldDisabled}
                      onClick={() => {
                        setTempValues(values);
                        setFielDisabled(false);
                      }}
                  >
                    Изменить
                  </button>
                  <button
                      type="button"
                      disabled={fieldDisabled}
                      onClick={() => {
                        setFielDisabled(true);
                        saveContractorInfo();
                      }}
                  >
                    Сохранить
                  </button>
                </div>
              </form>
          )}
          {screenType === "1" && (
              <div className={style.whiteBackground}>
                <div className={style.tabs}>
                  <Dialog
                      open={foremanReg}
                      className={`modal__window creating__foreman ${style.container}`}
                  >
                    <DialogTitle>Регистрация прораба</DialogTitle>
                    <DialogContent>
                      <div className="modal__back"></div>
                      <DialogContentText>
                        <div>
                          Все поля с учётными данными Прораба
                          <br />
                          должны быть заполнены.
                          <br />
                          При нажатии на кнопку «Отправить»
                          <br />
                          на указанный e-mail Прорабу будет отправлена
                          <br />
                          ссылка для подтверждения им регистрации.
                        </div>
                      </DialogContentText>
                      <form>
                        <div>
                          <label>Фамилия</label>
                          <input
                              type="text"
                              name="foreman-surname"
                              placeholder="(обязательное)"
                              onChange={handleForemanChange("lastName")}
                          />
                        </div>
                        <div>
                          <label>Имя</label>
                          <input
                              type="text"
                              name="foreman-name"
                              placeholder="(обязательное)"
                              onChange={handleForemanChange("firstName")}
                          />
                        </div>
                        <div>
                          <label>Отчество</label>
                          <input
                              type="text"
                              name="foreman-patronymic"
                              placeholder="(обязательное)"
                              onChange={handleForemanChange("secondName")}
                          />
                        </div>
                        <div>
                          <label>Логин</label>
                          <input
                              type="text"
                              name="foreman-login"
                              placeholder="(обязательное)"
                              onChange={handleForemanChange("login")}
                          />
                        </div>

                        <div>
                          <label>E-mail</label>
                          <input
                              type="email"
                              name="foreman-email"
                              placeholder="(обязательное)"
                              onChange={handleForemanChange("email")}
                          />
                        </div>
                        <div>
                          <label>Телефон</label>
                          <input
                              type="tel"
                              name="foreman-tel"
                              placeholder="+7 (___) ___-__-__"
                              onChange={handleForemanChange("phone")}
                          />
                        </div>
                      </form>
                    </DialogContent>
                    <DialogActions>
                      <button
                          type="button"
                          className="control__modal--close"
                          // disabled={foremanRegDisabled}
                          onClick={() => {
                            registerForeman();
                            setForemanReg(false);
                          }}
                      >
                        Отправить
                      </button>
                      <button
                          type="button"
                          className="control__modal--close"
                          onClick={() => setForemanReg(false)}
                      >
                        Отмена
                      </button>
                    </DialogActions>
                  </Dialog>

                  <div id="profile__content--2">
                    <div style={{display: 'flex'}}>
                      <button
                          type="button"
                          className={`bttn__small control__creating--foreman ${style.tabsButtons}`}
                          title="Регистрация пользователя с ролью «Прораб»"
                          onClick={() => {
                            setForemanReg(true);
                          }}
                      >
                        Добавить
                      </button>

                      <button
                          type="button"
                          className="bttn__small control__lock--foreman"
                          title="Блокировка учётной записи Прораба"
                          onClick={deleteForeman}
                          disabled={isForemenDeleteDisabled}
                      >Блокировать</button>
                    </div>

                    <div className={style.positionSpan}>
                      <div>
                        <span>Фамилия Имя Отчество</span>
                      </div>
                      <span>Логин</span>
                      <span>E-mail</span>
                      <span>Телефон</span>
                    </div>

                    <div>
                      <form>
                        <Suspense fallback={<div>Loading...</div>}>
                          {ShowForemen()}
                        </Suspense>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
          )}

          {screenType === "2" && (
              <div className={style.whiteBackground}>
                <div className={style.tabs}>
                  <Dialog
                      open={addNewBankAccount}
                      className={`modal__window creating__foreman ${style.container}`}
                  >
                    <DialogTitle>Регистрация прораба</DialogTitle>
                    <DialogContent>
                      <div className="modal__back"></div>
                      <DialogContentText>
                        <div>
                          Все поля с учётными данными Прораба
                          <br />
                          должны быть заполнены.
                          <br />
                          При нажатии на кнопку «Отправить»
                          <br />
                          на указанный e-mail Прорабу будет отправлена
                          <br />
                          ссылка для подтверждения им регистрации.
                        </div>
                      </DialogContentText>
                      <form>
                        <div>
                          <label>Банк (юридическое наименование)</label>
                          <input
                              type="text"
                              name="bank-name"
                              placeholder="(обязательное)"
                              onChange={handleBankChange("bankName")}
                          />
                        </div>
                        <div>
                          <label>Расчётный счет</label>
                          <input
                              type="text"
                              name="bank-calc"
                              placeholder="(обязательное)"
                              onChange={handleBankChange("paymentAccount")}
                          />
                        </div>
                        <div>
                          <label>Корресп. счет</label>
                          <input
                              type="text"
                              name="bank-corr"
                              placeholder="(обязательное)"
                              onChange={handleBankChange("correspondentAccount")}
                          />
                        </div>
                        <div>
                          <label>БИК</label>
                          <input
                              type="text"
                              name="bank-bik"
                              placeholder="(обязательное)"
                              onChange={handleBankChange("bik")}
                          />
                        </div>

                        <div>
                          <label>Эскроу счет</label>
                          <input
                              type="checkbox"
                              name="bank-escrow"
                              disabled={values.hasEscrow}
                              placeholder="(обязательное)"
                              onChange={() =>
                                  setNewBankAccount({
                                    ...newBankAccount,
                                    escrow: !newBankAccount.escrow,
                                  })
                              }
                          />
                        </div>
                      </form>
                    </DialogContent>
                    <DialogActions>
                      <button
                          type="button"
                          className="control__modal--close"
                          onClick={async () => {
                            await addBankAccount()
                            setAddNewBankAccount(false);
                          }}
                      >
                        Добавить
                      </button>
                      <button
                          type="button"
                          className="control__modal--close"
                          onClick={() => setAddNewBankAccount(false)}
                      >
                        Отмена
                      </button>
                    </DialogActions>
                  </Dialog>
                  <div id="profile__content--3">
                    <div style={{display: 'flex'}}>
                      <button
                          type="button"
                          className={`bttn__small control__adding--bank ${style.tabsButtons}`}
                          title="Добавление банковского счёта"
                          onClick={() => setAddNewBankAccount(true)}
                      >
                        Добавить
                      </button>
                      <button
                          type="button"
                          className="bttn__small control__lock--bank"
                          title="Блокировка использования банковского счёта"
                          onClick={deleteBankAccount}
                          disabled={isBankDeleteDisabled}
                      >Блокировать
                      </button>
                    </div>

                    <div className={style.positionSpan}>
                      <span>Банк</span>
                      <span>Расчётный счёт</span>
                      <span>Корреспондентский счёт</span>
                      <span>БИК</span>
                      <span>!</span>
                    </div>

                    <div>
                      <Suspense fallback={<div>Loading...</div>}>
                        {ShowBank()}
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>
      </main>
  );
}