import React from 'react';
import styles from './MutualCaptions.module.scss';

function MutualCaptions() {
  return (
    <div className={styles.captions}>
      <div title="Объекты бизнес-процесса: Стройка, Сметы, Договоры и т. д.">Объект / Документы</div>
      <div>Дата<br/>документа</div>
      <div>Оплата<br/>Платформе</div>
      <div>Оплата<br/>Бригады</div>
      <div>Акт<br/>Платформа</div>
      <div>Акт<br/>Бригада</div>
    </div>
  );
}

export default MutualCaptions;