import Config from "../../config.js";
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import lineart from "../../assets/lineart.svg";
import {Link, useHistory, useLocation} from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { NewFooter } from "../../components/footer/NewFooter";
import "../../styles/na_invite.css";
import {useQuery} from "../../hooks/useQuery";
import {useDispatch, useSelector} from "react-redux";
import {fetchInviteRegistrationData, finishInviteRegistration} from "../../redux/auth/authActions";
import {Box, Modal} from "@mui/material";
import style from './invite-page.module.scss';

interface State {
  password: string;
  code: string;
  passwordRepeat: string;
  showPassword: boolean;
}

const validatePassword = (password: string): boolean => {
  if (password) {
    const re = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    if (re.test(password)) {
      return password.length < 8;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export default function Invite(): JSX.Element | null {
  const dispatch = useDispatch<any>();
  const query = useQuery();
  const history = useHistory();
  const registerData = useSelector((state: any) => state.authReducer.data);
  const [values, setValues] = useState<State>({
    password: '',
    code: '',
    passwordRepeat: '',
    showPassword: false
  });
  
  const registrationId: string | null = query.get('id');
  
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [smsError, setSMSError] = useState<boolean>(false);
  
  useEffect(() => {
    if (registrationId) {
      dispatch(fetchInviteRegistrationData(registrationId))
    }
  }, [registrationId]);
  
  async function submit(e: SyntheticEvent) {
    e.preventDefault();
    const { password, code } = values;
    const { login, phone } = registerData;
    const res = await dispatch(finishInviteRegistration({ code, password, login, phone, registrationId }));
    if (res?.status === 200) {
      setShowSuccess(true);
      setTimeout(() => history.push("/login"), 5000)
    }
  }
  
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
 
  
  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__invite non-authorized";
  }
  
  const passError: boolean = validatePassword(values.password);
  const passRepeatError: boolean = validatePassword(values.passwordRepeat);
  
  return (
    <>
      <Navbar />
      <main>
        <Modal
          open={showSuccess}>
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
          }}>
            Регистрация успешна!
            Вы будете перенаправлены на страницу входа через 5 секунд.
          </Box>
        </Modal>
        <h2>Завершение регистрации</h2>
        {registerData ? (
          <>
            <form className="account__mode--pass">
              <div className={style.items}>
                <h3>Логин</h3>
                <input
                  type="text"
                  autoComplete="off"
                  value={registerData?.login}
                  disabled
                />
              </div>
      
              <div className={style.items}>
                <h3>Телефон</h3>
                <input
                  type="text"
                  autoComplete="off"
                  value={registerData?.phone}
                  disabled
                />
              </div>
      
              <div className={style.items}>
                <h3>Код из СМС</h3>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="(не указано)"
                  onChange={handleChange("code")}
                  value={values.code}
                />
              </div>
      
              <div className={style.items}>
                <h3>Пароль</h3>
                <input
                  type="password"
                  autoComplete="off"
                  placeholder="(не указано)"
                  className={passError ? "error__box" : ""}
                  onChange={handleChange("password")}
                  value={values.password}
                  disabled={!values.code}
                />
              </div>
      
              <div className={style.items}>
                <h3>Пароль (проверка)</h3>
                <input
                  type="password"
                  placeholder="(не указано)"
                  className={passRepeatError ? "error__box" : ""}
                  onChange={handleChange("passwordRepeat")}
                  value={values.passwordRepeat}
                  disabled={!values.password}
                />
              </div>
      
              <button
                disabled={
                  !values.password || !values.code || !values.passwordRepeat || passError || passRepeatError || values.password !== values.passwordRepeat
                }
                onClick={submit}
                className={style.registerBtn}
              >
                Зарегистрироваться
              </button>
            </form>
    
            <div className="pic__back">
              <img src={lineart} alt="Фоновая картинка" />
            </div>
    
            <div
              className="info__box"
            >
              {smsError && (
                <>
                  <h3>Неверный код</h3>
                  <p>
                    Код не соответствует отправленному в СМС!
                    <br />
                    Введите код из СМС или
                    <a href="/_" title="Отправка нового кода">
                      запросите новый код
                    </a>
                    .
                  </p>
          
                  <h3>СМС не приходит</h3>
                  <p>Отправить код повторно.</p>
                </>
              )}
              <>
                <h3>Код</h3>
                <p>
                  Код подтверждения регистрации действителен 10 минут.
                  <br/>
                  Если СМС не получено в течение нескольких минут, нажмите на
                  <a href="/_" title="Инициация отправки повторного СМС"> эту ссылку</a>.
                </p>
        
                <h3>Пароль</h3>
                <p>
                  Длинна: Не менее 8-и символов.
                  <br/>
                  Должен содержать: буквы обоих регистров, цифры и любые символы (например, пунктуации).
                  <br/>
                  Пароль в обоих полях должен быть одинковым.
                </p>
              </>
            </div>
          </>
        ) : (
          <div>
            Данные не найдены
          </div>
        )}
      </main>
      <NewFooter />
    </>
  );
}
