import React from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import styles from './DateRangePicker.module.scss';

interface DateRangePickerProps {
  className?: string,
  startDate: Date | null,
  endDate: Date | null,
  onChangeDates: Function
}

function DateRangePicker({className, startDate, endDate, onChangeDates} : DateRangePickerProps) {
  return (
    <div className={`${styles.dateRange} ${className}`}>
      <ReactDatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        locale={ru}
        dateFormat="dd.MM.yy"
        onChange={(update) => {
          onChangeDates(update);
        }}
      />
    </div>
  );
}

export default DateRangePicker;