import Config from "../../config.js";
import React, { useState } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";

interface State {
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  phone: string;
}

export default function PersonalInfo() {
  const user = JSON.parse(localStorage.getItem("user") as string);
  const [values, setValues] = useState<State>({
    name: user.name || "",
    surname: user.surname || "",
    patronymic: user.patronymic || "",
    email: user.email || "",
    phone: user.phone || "",
  });
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [infoDisabled, setInfoDisabled] = useState<boolean>(true);
  const [alert, setAlert] = useState<JSX.Element>(<></>);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const history = useHistory();

  const saveInfo = async () => {
    const name = values.name;
    const surname = values.surname;
    const patronymic = values.patronymic;
    const username = user.username;
    // if (!hasErr.email && !hasErr.check && !hasErr.pass) {
    const response = await fetch(
      Config.BACKEND_ADDRESS + "api/user/saveUserInfo",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          name,
          surname,
          patronymic,
          username,
        }),
      }
    );
    // const result = await response.json()
    if (response.ok) {
      const response = await fetch(
        Config.BACKEND_ADDRESS + "api/user/getCurrentUser",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            username,
          }),
        }
      );
      const result = await response.json();
      if (result.status === "200") {
        localStorage.setItem("user", JSON.stringify(result));
        const user = JSON.parse(localStorage.getItem("user") as string);
        setValues({
          ...values,
          name: user.name,
          surname: user.surname,
          patronymic: user.patronymic,
        });
      }
      setAlert(
        <Alert
          severity="success"
          onClose={() => {
            setAlert(<></>);
          }}
        >
          Данные успешно изменены
        </Alert>
      );
      editMode(false);
      // props.onSuccess()
      // setShowVer(true)
    } else if (response.status === 401) {
      await fetch(Config.BACKEND_ADDRESS + "api/auth/refresh", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }).then((response) => {
        if (response.ok) {
          saveInfo();
        } else {
          history.push("/");
        }
      });
    } else {
      setAlert(
        <Alert
          severity="error"
          onClose={() => {
            setAlert(<></>);
          }}
        >
          {response.status}
        </Alert>
      );
    }
  };
  const editMode = (v: boolean) => {
    setEditFlag(v);
    setInfoDisabled(!v);
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className="w-30"
            label="Фамилия"
            variant="standard"
            type="text"
            value={values.surname}
            disabled={infoDisabled}
            // error={checkError}
            onChange={handleChange("surname")}
          />
        </div>
        <div>
          <TextField
            className="w-30"
            label="Имя"
            variant="standard"
            type="text"
            value={values.name}
            disabled={infoDisabled}
            // error={checkError}
            onChange={handleChange("name")}
          />
          {editFlag && <Button onClick={saveInfo}>Сохранить</Button>}
        </div>
        <div>
          <TextField
            className="w-30"
            label="Отчество"
            variant="standard"
            type="text"
            value={values.patronymic}
            disabled={infoDisabled}
            // error={checkError}
            onChange={handleChange("patronymic")}
          />
          {!editFlag && (
            <Button
              onClick={() => {
                editMode(true);
              }}
            >
              Изменить
            </Button>
          )}
          {editFlag && (
            <Button
              onClick={() => {
                editMode(false);
              }}
            >
              Отменить
            </Button>
          )}
        </div>
        <div>
          <TextField
            className="w-30"
            label="Почтовый адрес"
            variant="standard"
            type="text"
            value={values.email}
            disabled
            // error={checkError}
            onChange={handleChange("email")}
          />
        </div>
        <div>
          <TextField
            className="w-30"
            label="Телефон"
            variant="standard"
            type="text"
            value={values.phone}
            disabled
            // error={checkError}
            onChange={handleChange("phone")}
          />
        </div>
      </Box>
      {alert}
    </>
  );
}
