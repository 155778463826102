import React from "react";
import "../../styles/na_register.css";

export default function Navbar(): JSX.Element {
  return (
    <>
      <nav>
        <div>
          <a className="link-light" href="/help#about" target="_blank" title="Откроется на новой вкладке">О нас</a>
        </div>
        <div>
          <a className="link-light" href="/forum" target="_blank" title="Откроется на новой вкладке">Форум</a>
        </div>
        <div>
          <a className="link-light" href="/help#docs" target="_blank" title="Откроется на новой вкладке">Нормативная
            документация</a>
        </div>
      </nav>
    </>
  );
}
