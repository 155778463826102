import React, {useCallback, useState} from 'react';
import styles from './PlanningListBuilding.module.scss';
import {IBillingPlanBuilding} from "../../../../../../types/Billing";
import {formatPrice} from "../../../../../../utils/formatPrice";
import PlanningListEstimate from "../PlanningListEstimate/PlanningListEstimate";

interface PlanningListBuildingProps {
  building: IBillingPlanBuilding
}

function PlanningListBuilding({building}: PlanningListBuildingProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div className={styles.buildingSection}>
      <div className={`${styles.buildingRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <a href="/_" target="_blank" title="Карточка объекта">{building.name}</a>
          <span>{building.comment}</span>
        </div>
        <div></div>
        <div></div>
        <div>{formatPrice(building.toPrepay)}</div>
        <div>{formatPrice(building.planTotal)}</div>
      </div>

      {isOpen && (
        <>
          {building.subItems.map(estimate => (
            <PlanningListEstimate key={estimate.entityId} estimate={estimate}/>
          ))}
        </>
      )}
    </div>
  );
}

export default PlanningListBuilding;