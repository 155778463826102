import {downloadFileItem} from "../../utils/downloadItem";
const FileCard = (params: any): JSX.Element => {
  if(!params.fileCardObject) return (<></>);	
  const { id, originalFileName, description } = params.fileCardObject;
  const downloadFile = () => {
	  downloadFileItem(id, originalFileName);
  }
  return (
		<div>
			<span className="symbol__bull--before"></span>
			<span className="limit__text--length"><a onClick={downloadFile}>{originalFileName}</a></span>
			<span className="limit__text--length">{description}</span>
		</div>
  );
};

export default FileCard;