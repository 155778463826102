import {getComplaintSide, getComplaintStatus, getQuestionSide} from "../../utils/getComplaints";
import {handlePlanDate, handleTime} from "../../utils/estimateUtils";
import {downloadFileItem} from "../../utils/downloadItem";

const Replicas = (params: any): JSX.Element => {
  const {messages, respondentRole, status, downtime} = params.complaintCardObject;
  if(!messages) return(<></>);
  const messageCount = messages.length;
  return (
    <div className="complaint__replicas">
        {messages.map((messageObject: any, key: number) => {
            const message = messageObject?.message;
            const documents = messageObject?.documents;
            const complaintSide = getComplaintSide(message.authorRole, respondentRole);
            const questionSide = getQuestionSide(message, respondentRole);
            const autoTextStyle = ["overrule", "satisfied","end"].includes(getComplaintStatus(status)) ? 
                                 "text__complaint--" + getComplaintStatus(status) : 
                                  downtime ? "text__complaint--downtime" : "";
            return (    
            <div className={`replica__${complaintSide}${questionSide}`} key={key}>
                <div>
                    <span>{handlePlanDate(message?.createdAt)}</span>
                    <span>{handleTime(message?.createdAt)}</span>
                </div>
                {complaintSide === "arbitrator" ? <div><span></span></div> : null}
                <div className={key === messageCount - 1 ? autoTextStyle : ""}>{message?.text}</div>
                {complaintSide !== "arbitrator" ? 
                    <div>
                        {documents.map((document: any, key: number) => {
                            if(!document) return null;
                            const {id, originalFileName, description} = document;
                            const downloadFile = () => {
                                downloadFileItem(id, originalFileName);
                            }
                            return (
                                <span className="symbol__bull--before" key={key}>
                                    <a onClick={downloadFile}>{originalFileName}({description})</a>
                                </span>
                            );
                        })}
                    </div> : 
                    null
                }
            </div>);
        })}
    </div>

  );
};

export default Replicas;