import axios from "axios";
import Config from "../config";

export const downloadFileItem = async (id: string, fileName: string) => {
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + `File/GetFileByDocumentId?id=${id}`)
    downloadFile(result.config.url, fileName);
  } catch (e) {
    console.log(e);
  }
};

function downloadFile(url: any, filename: any) {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;

  a.target = "_blank";
  a.download = filename || 'download';
  const clickHandler = () => {
    a.removeEventListener('click', clickHandler);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}