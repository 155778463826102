import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import moment from "moment";
import { createObject } from "../../../redux/actions/actionContractor";
import {useDispatch} from "react-redux";
import {DateInput} from "../../DateInput/DateInput";
import {parseDate} from "../../../utils/estimateUtils";
import { useHistory } from "react-router-dom";
import styles from './modal.module.scss';

const initialObjectData = {
    estimateName: '',
    estimateTypeId: '',
    workStartPlan: '',
    workEndPlan:'',
    buildingName: '',
    buildingAddress: '',
    developer: "",
    customer: "",
    mainContractor: "",
    coordinates: "",
    longitude: 0,
    latitude: 0,
    objectRole: 0,
}

const objectValid = {
    estimateName: false,
    estimateTypeId: false,
    workStartPlan: false,
    workEndPlan: false,
    workData: false,
    buildingName: false,
    buildingAddress: false,
    objectRole: false,
    longitude: true,
    latitude: true,
    coordinates: true,
}

export const ModalObject = ({isOpen, selectedTypeValue, setModal, types, setEstimateType}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState(initialObjectData);
    const [valid, setValid] = useState(objectValid)
    let element = document.getElementById("root");
    if (element !== null) {
        element.classList.value = "page__building--list role__contractor modal__open--creating-building";
    }

    useEffect(() => {
        const {workStartPlan, workEndPlan} = data;
        if (workEndPlan && workStartPlan){
            setValid({
                ...valid,
                workData: moment(parseDate(workEndPlan)).isAfter(parseDate(workStartPlan), [
                    "day",
                    "month",
                    "year"
                ])
            })
        }
    }, [data.workStartPlan, data.workEndPlan])

    const onChange = (e) => {
        const {name, value} = e.target;
        if (data.hasOwnProperty(name)) {
            setData({
                ...data,
                [name]: value,
            })
        }

        const currErrors = {...valid}
        switch (name) {
            case 'buildingName':
            case 'buildingAddress':
            case 'estimateName':
            case 'estimateTypeId':
            case 'objectRole':
                currErrors[name] = !!value.trim();
                break;
            case 'longitude':
                currErrors[name] = value <= 180 && value >= 0 && value !== "";
                break;
            case 'latitude':
                currErrors[name] = value <= 90 && value >= 0 && value !== "";
                break;
            case 'coordinates':
                const valueDate = value.split(' ');
                currErrors[name] = valueDate[0] <= 180 && valueDate[1] <= 90 && value !== "";
                break;
            case 'workStartPlan':
            case 'workEndPlan':
                currErrors[name] = moment(value, 'DD.MM.YYYY', true).isValid();
                break;
            default:
                return;
        }
        setValid(currErrors);
    }
    const submit = async () => {
        const createResult = await dispatch(createObject(data));
        if (createResult.status === 200) {
            history.push(`/estimate-management/${createResult.data.estimates[0].id}`);
            setModal(false);
        }
    }

    useEffect(() => {
        if (data.coordinates) {
            setData({
                ...data,
                ['longitude']: +data.coordinates.split(' ')[0],
                ['latitude']: +data.coordinates.split(' ')[1]
            })
        }
    }, [data.coordinates])

    useEffect(() => {
      if (selectedTypeValue) {
        setData({
          ...data,
          estimateTypeId: selectedTypeValue?.id
        })
        setValid({
          ...valid,
          estimateTypeId: true
        })
      }
    }, [selectedTypeValue]);

    const handleDisabledButton = () => {
        return !Object.keys(valid).every((item) => valid[item]);
    }

    if (!isOpen) return null;
    return ReactDOM.createPortal(
        <div id="modal__box">
            <div className="modal__back"/>
            <div className="modal__window creating__building">

                <h3>Создание Объекта</h3>

                <div>
                    Все обязательные поля должны быть заполнены.
                </div>

                <form>
                    <label>Название</label>
                    <input type="text" name="buildingName" value={data.buildingName} onChange={onChange} placeholder="(обязательное)"/>

                    <label>Адрес</label>
                    <input type="text" name={"buildingAddress"} value={data.buildingAddress} onChange={onChange} placeholder="(обязательное)"/>

                    <label>Застройщик</label>
                    <input value={data.developer} name="developer" onChange={onChange} type="text" placeholder="(не обязательное)"/>

                    <label>Генподрядчик</label>
                    <input value={data.customer} name="customer" onChange={onChange} type="text" placeholder="(не обязательное)"/>

                    <label>Заказчик</label>
                    <input value={data.mainContractor} name="mainContractor" onChange={onChange} type="text" placeholder="(не обязательное)"/>

                    <section style={{display:"flex", width: "100%"}}>
                        <label style={{marginRight:"38px"}}>Геокоординаты</label>
                        <section style={{display:"flex"}}>
                            <input style={{border:!valid.coordinates ? '1px solid red' : '', width:"400px"}} type="text" onChange={onChange} name="coordinates" placeholder="(не обязательное)" value={data.coordinates}/>
                        </section>
                    </section>
                    <label htmlFor="" />
                    <label title="Ваши полномочия на этом Объекте">Роль на Объекте</label>
                    <select value={data.objectRole} name="objectRole" onChange={onChange} className="">
                        <option value="" hidden>(обязательное)</option>
                        <option value={1}>Застройщик</option>
                        <option value={2}>Генподрядчик</option>
                        <option value={3}>Заказчик</option>
                        <option value={4}>Подрядчик</option>
                    </select>
                    <p>Смета</p>
                    <label>Название</label>
                    <input type="text" name="estimateName" value={data.estimateName} onChange={onChange} placeholder="(обязательное)"/>

                    <label>Примечание</label>
                    <input name="estimateNote" value={data.estimateNote} onChange={onChange} type="text" placeholder="(не обязательное)"/>

                    <label className={styles.label} onClick={() => setEstimateType(true)}></label>
                    <input type="text" value={selectedTypeValue.name} disabled name="estimateTypeId" onChange={onChange} />

                    <label>Плановые сроки</label>
                    <label className="symbol__colon" style={{fontFamily:"thin", display:"flex", alignItems:"center"}}>Старт:
                        <DateInput valid={valid} onChange={onChange} value={data.workStartPlan} field={"workStartPlan"} />
                    </label>
                    <label  className="symbol__colon" style={{fontFamily:"thin", display:"flex", alignItems:"center", marginRight: '66px'}}>Финиш:
                        <DateInput valid={valid} onChange={onChange} value={data.workEndPlan} field={"workEndPlan"} />
                    </label>

                    <button type="button" className="control__modal--close" onClick={submit} disabled={handleDisabledButton()}>Создать</button>
                    {valid.workEndPlan && valid.workStartPlan && !valid.workData && (
                        <span style={{gridArea:"13/2/14/3", textAlign:"center"}}>Выберите корректный период</span>
                    )}
                    <button type="button" style={{justifySelf:"end", alignSelf:"end"}} className="control__modal--close" onClick={() => setModal(false)}>Отмена</button>
                </form>
            </div>
        </div>,
        document.body);
};