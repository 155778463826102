import React, { useState } from 'react';
import {DateInput} from "../../DateInput/DateInput";
import { ModalEstimateType } from '../../../pages/EstimateManagement/StatusBar/ModalTypeEstimate/ModalEstimateType';

export const ModalDataEstimate = ({ data, onChange, types, submit, valid, handleDisabledButton, setModal, selectedTypeValue, setSelectedTypeValue }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <label>Название</label>
            <input type="text" name="estimateName" value={data.estimateName} onChange={onChange} placeholder="(обязательное)"/>

            <label>Примечание</label>
            <input name="estimateNote" value={data.estimateNote} onChange={onChange} type="text" placeholder="(не обязательное)"/>

            <label><span onClick={() => setIsOpen(true)} className="control__adding--estimate-type">Тип сметы</span></label>
            <input type="text" disabled value={selectedTypeValue?.name}/>

            <label>Плановые сроки</label>
            <label className="symbol__colon--after">Старт</label>
            <DateInput valid={valid} onChange={onChange} value={data.workStartPlan} field={"workStartPlan"} />
            <label className="symbol__colon--after">Финиш</label>
            <DateInput valid={valid} onChange={onChange} value={data.workEndPlan} field={"workEndPlan"} />

            <button type="button" className="control__modal--close" onClick={submit} disabled={handleDisabledButton()}>Создать</button>
            {valid.workEndPlan && valid.workStartPlan && !valid.workData && (
                <span style={{gridColumn:"2/5", justifySelf:"start"}}>Выберите корректный период</span>
            )}
            <button type="button" className="control__modal--close" onClick={() => setModal(false)}>Отмена</button>
            <ModalEstimateType
              setSelectedTypeValue={setSelectedTypeValue}
              selectedTypeValue={selectedTypeValue}
              types={types}
              isOpen={isOpen}
              setOpen={setIsOpen}
            />
        </>
    );
};
