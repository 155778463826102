import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getJobPlan } from '../../redux/jobPlan/jobPlanActions';
import {IJobPlanState} from '../../redux/jobPlan/types';
import JobPlan from '../../components/JobPlan/JobPlan';
import estimateJobParams from '../estimateJob-page/estimateJobParams';

export default function JobPlanPage() {
  const { params } = useParams<any>();
  const dispatch = useDispatch<any>();
  const jobPlan = useSelector<any>(state => state.jobPlan) as IJobPlanState;
  const user = useSelector<any>(state => state.userReducer.user) as any;
  const id = estimateJobParams(params).id;

  useEffect(() => {
    if (id && user) {
      dispatch(getJobPlan(id));
    }
  }, [id, user, dispatch]);

  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.add("page__jobplan--card", "role__foreman", "status__jobplan--done");
  }

  return (
    <>
      <div>
        {jobPlan.loading && (
          <div><h1>LOADING...</h1></div>
        )}
        {!jobPlan.loading && (jobPlan.error || !jobPlan.data) ? (
          <div><h1>ERROR...</h1></div>
        ) : (
          <>
            {jobPlan.data && (
              <JobPlan jobPlan={jobPlan.editMode ? jobPlan.dataCopy : jobPlan.data}/>
            )}
          </>
        )}
      </div>
    </>
  );
}