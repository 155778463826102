import { combineReducers } from "redux";

import contractorReducer from "./reducers/ContractorReducer";
import estimateReducer from './estimate/estimateReducer';
import userReducer from "./user/userReducer";
import jobPlan from "./jobPlan/jobPlanReducer";
import order from './order/orderReducer';
// import roles from './roles/rolesReducer';
import authReducer from "./auth/authReducer";
import ordersList from './ordersList/ordersListReducer';
import billing from './billing/billingReducer';
import statuses from './statuses/statusesReducer';
import profile from './profile/profileReducer';

const Reducers = combineReducers({
  contractorReducer,
  estimateReducer,
  authReducer,
  userReducer,
  jobPlan,
  order,
  // roles,
  ordersList,
  billing,
  statuses,
  profile,
});

export default Reducers;
