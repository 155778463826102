import React from 'react';
import {IBillingBuilding} from "../../../../types/Billing";
import MutualListBuilding from "./components/MutualListBuilding/MutualListBuilding";

interface MutualListProps {
  items: IBillingBuilding[]
}

function MutualList({items}: MutualListProps) {
  return (
    <>
      {items.map(building => (
        <MutualListBuilding building={building} key={building.entityId}/>
      ))}
    </>
  );
}

export default MutualList;