import {useEffect, useState} from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import "../../styles/role_foreman.css";
import {useDispatch, useSelector} from "react-redux";
import {getComplaintTopics, getComplaintList} from "../../redux/actions/actionContractor";
import {getRoleName, RoleName} from "../../utils/getRoleName";

import {ListCaptionItem} from "../../components/ComplainList/Types";
import Caption from "../../components/ComplainList/Caption";
import ListCaption from "../../components/ComplainList/ListCaption";
import FilterPanel from "../../components/ComplainList/FilterPanel";
import ComplaintListPanel from "../../components/ComplainList/ComplaintListPanel";

type Filters = {
  building_address?: string,
  estimate_order?: string,
  theme?: number,
  replica?: string,
  status?: number,
  dateFrom?: Date,
  dateTo?: Date,
  responder?: number,
  [key: string]: any,
}

const textFilterTimeoutDelay = 300;
let textFilterTimeout: any = null;
let prevTextFilter: any = null;
let localFilters: Filters = {};

const ComplaintList = () => {
  const [roleName, setRoleName] = useState<RoleName>("none");
  const [filters, setFilters] = useState<Filters>({});
  const user = useSelector((state: any) => state.userReducer.user);
  
  const changeHandler = (e: any) => {
    localFilters = {...localFilters};
    if(e.target){   
      const filterField = e.target.name.replace("filter__","");   
      localFilters[filterField] = e.target.value;
      if(e.target.type === "text"){
        if((prevTextFilter === filterField) && textFilterTimeout) clearTimeout(textFilterTimeout);
        prevTextFilter = filterField;
        textFilterTimeout = setTimeout(() => setFilters(localFilters), textFilterTimeoutDelay);
      }else{
        setFilters(localFilters);
      }
    }else{
      const newDateFrom = e[0];
      const newDateTo = e[1];
      localFilters.dateFrom = newDateFrom ? newDateFrom : undefined;
      localFilters.dateTo = newDateTo ? newDateTo : undefined;   
      setFilters(localFilters);
    }
  }

  const { complaintList, 
          complaintTopics, 
          isLoading, 
          error} = useSelector((state: any) => state.contractorReducer);
  const listCaptions: ListCaptionItem[] = 
                      [{captions:["Название объекта", "Адрес объекта"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"building_address"},
                       {captions:["Название сметы", "Название наряда"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"estimate_order"},
                       {captions:["Тема спора"], 
                        title:"", 
                        filtertype:"complaintTopic", 
                        captionId:"theme"},
                       {captions:["Претензия (начало)"], 
                        title:"", 
                        filtertype:"text", 
                        captionId:"replica"},
                       {captions:["Статус", "Инициатор"], 
                        title:"", 
                        filtertype:"status", 
                        captionId:"status"},
                       {captions:["Период спора", "Период простоя"], 
                        title:"", 
                        filtertype:"period", 
                        captionId:"date"},
                       {captions:["Ответчик", "Время"], 
                        title:"По чьей вине простой / Продолжительность", 
                        filtertype:"responder", 
                        captionId:"responder"}
                      ];
  
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getComplaintTopics());
  }, []);

  useEffect(() => {
    dispatch(getComplaintList(filters));
   },[filters]);

  useEffect(() => {
    setRoleName(getRoleName(user?.role));
  }, [user]);

  if (error) {
    return <div className="page__error-message"><p>Ошибка загрузки списка споров: {error.message}</p></div>;
  }

  let element = document.getElementById("root");
  if (element !== null) element.classList.value = getRootClassList(roleName);           

  return (
    <>
      <main>
        <Caption/>
        <ListCaption listCaptions = {listCaptions}/>
        <FilterPanel listCaptions = {listCaptions} 
                     changeHandler = {changeHandler} 
                     complaintTopics = {complaintTopics}
                     dateFrom = {filters.dateFrom}
                     dateTo = {filters.dateTo}/>
        {isLoading && <div className="page__loading-message"><p>Загрузка...</p></div>}
        <ComplaintListPanel complaintList = {complaintList} complaintTopics = {complaintTopics}/>
      </main>
      <NewFooter/>
    </>
  )
}     
export {ComplaintList};

const getRootClassList = (roleName: string): string => {
  let res = `page__complaint--list role__${roleName}`;
  return res;
}
