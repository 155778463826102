var commit = window._env_.GIT_COMMIT;
var branch = window._env_.GIT_BRANCH;
var author = window._env_.GIT_AUTHOR;
var date = window._env_.GIT_DATE;
var subject = window._env_.GIT_SUBJECT;

var buildInfo = {
  commit: commit,
  branch: branch,
  author: author,
  date: date,
  subject: subject,
  info:
    commit +
    "@" +
    branch +
    " by '" +
    author +
    "' at '" +
    date +
    "': " +
    subject,
};

export default buildInfo;
