import React, {useState} from 'react';
import style from './chainItems.module.scss';
import {ChainSubitems} from "./ChainSubitems/ChainSubitems";

const ChainItems = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openItems, setOpenItems] = useState<boolean>(false);

  const arr = [1, 2, 3];

  return (
      <React.Fragment>
        <div className={`${style.main}`}>
          <div className={`${style.container}`}>
          <div className={`${style.nameContainer}`}>
            <div className={`${openItems ? "open" : ""}`} style={{marginLeft: '6px', display: 'flex', alignItems: "center"}}>
              <span onClick={() => setOpenItems(!openItems)}
                    className="circle-triangle"
                    title="Развернуть / Свернуть">
              </span>
            </div>
            <div className={style.name}>
              Название Цепочки с очень длинным именем Название Цепочки с очень длинным именем Название Цепочки с очень длинным именем
              {open &&
                  <button className="bttn__small" type="button" name="control__chain--run"
                          title="Подбор бригад для всех Нарядов">Запустить
                  </button>
              }
            </div>
          </div>
          <div className="text__status--chain"></div>
          <div className={style.counter}>0 000 000</div>
          <div className={style.graph}>
            <div className="graph__box--chain" style={{marginLeft: "0"}}>
              <div>
                <div className={style.graphDate}>
                  <span className={style.date}>00 фев -</span>
                  <span className={style.date}> 00 мар</span>
                </div>
                <div style={{width: "200px", height: '14px', background: 'var(--back-chain-graph)'}}>&emsp;</div>
                {open && <input style={{height: '14px', width: '200px'}} type="date" name="chain__date-start"/>}
              </div>
            </div>
          </div>
        </div>
        {openItems && arr.map((el: number) => <ChainSubitems />)}
        </div>
      </React.Fragment>
  )
}

export {ChainItems};