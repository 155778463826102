import React, {useCallback, useState} from 'react';
import styles from './ChainBox.module.scss';
import {IJobPlanChain, IJobPlanChainOrder} from '../../../../types/JobPlan';
import {formatPrice} from '../../../../utils/formatPrice';
import {getDaysDiff, parseDateString, parseInputDate} from '../../../../utils/estimateUtils';
import moment, {Moment} from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {IStatus} from '../../../../types/Status';
import {changeChainStartDate, setSelectedItem, updateEntityStatus} from '../../../../redux/jobPlan/jobPlanActions';

interface ChainBoxProps {
  startEndDates: [Moment, Moment]
  chains: IJobPlanChain[]
  jobPlanStatus: number,
  orderAjusts: object,
  setOrderAjusts: Function,
  canEditPlan: boolean,
}


function ChainBox({startEndDates, chains, jobPlanStatus, orderAjusts, setOrderAjusts, canEditPlan}: ChainBoxProps) {
  const dateDifference = startEndDates[1].valueOf() - startEndDates[0].valueOf();
  const [openedChains, setOpenedChains] = useState<string[]>([]);
  const [openedOrders, setOpenedOrders] = useState<string[]>([]);
  
  const editMode = useSelector<any>(state => state.jobPlan.editMode) as boolean;
  const orderStatuses = useSelector<any>(state => state.jobPlan.orderStatuses) as IStatus[];
  const updatingCopyEntitiesId = useSelector<any>(state => state.jobPlan.updatingCopyEntitiesId) as string[];
  const selectedItem = useSelector<any>(state => state.jobPlan.selectedItem?.id) as string;
  const dispatch = useDispatch<any>();

  const handleToggleChain = (chainId: string) => (e: React.MouseEvent<HTMLElement>) => {
    if (openedChains.includes(chainId)) {
      setOpenedChains(prev => ([...prev].filter(item => item !== chainId)));
    } else {
      dispatch(setSelectedItem(chainId, null, 'chain'));
      setOpenedChains(prev => ([...prev, chainId]));
    }
  };

  const handleToggleOrder = (orderId: string, chainId: string) => (e: React.MouseEvent<HTMLElement>) => {
    if (openedOrders.includes(orderId)) {
      setOpenedOrders(prev => ([...prev].filter(item => item !== orderId)));
    } else {
      dispatch(setSelectedItem(orderId, chainId, 'order'));
      setOpenedOrders(prev => ([...prev, orderId]));
    }
  };

  const handleClickOrderRow = (orderId: string, chainId: string) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(setSelectedItem(orderId, chainId, 'order'));
  };

  const handleChangeDate = (chainId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeChainStartDate(chainId, moment(e.target.value).toISOString()));
  };

  const getGraphParams = (startDate: string, endDate: string) => {
    const start = 100 * (moment(startDate).valueOf() - startEndDates[0].valueOf()) / dateDifference;
    const end = 100 * (startEndDates[1].valueOf() - moment(endDate).valueOf()) / dateDifference;

    let className = '';

    if (start < 5 && end > 70) {
      className = styles.alignLeft;
    } else if (start > 70 && end < 5) {
      className = styles.alignRight;
    }

    return { start, end, className };
  };

  const getOrderStatusText = (status: number) => {
    if (!orderStatuses) {
      return '';
    }

    const statusData = orderStatuses.filter(item => item.currentStateCode === status)?.[0];

    if (statusData) {
      return statusData.currentStateText;
    }
  };

  const getChainStatusClassname = (chain: IJobPlanChain) => {
    if (chain.orders.some(item => item.status === 39 || item.status === 37 || item.status === 57)) {
      return styles.error;
    }

    if (chain.orders.some(item => item.status === 61 || item.status === 62)) {
      return styles.warning;
    }

    return  '';
  };

  const getOrderStatusClassname = (status: number) => {
    if (status === 39 || status === 37 || status === 57) {
      return styles.error;
    }

    if (status === 61 || status === 62) {
      return styles.warning;
    }

    return '';
  };

  const handleClickAction = useCallback((chainId: string, actionCode: number, actionText: string, chainOrders: any) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(updateEntityStatus({
      entityId: chainId,
      newState: actionCode,
      actionText,
      message: '',
      chainOrders: chainOrders,
    }, true));
  }, [dispatch]);

  const renderChainAction = (chainId: string, chainOrders: IJobPlanChainOrder[]) => {
    if (editMode) {
      return null;
    }
    if (chainOrders.some(item => item.status === 39) || chainOrders.filter(item => item.status !== 38).length === 0) {
      const status = orderStatuses.filter(item => item.currentStateCode === 39 && !!item.actionText)?.[0];
      return (
        <button
          className={`bttn__small ${styles.chainRowBtn}`}
          type="button"
          title={status?.actionText}
          onClick={handleClickAction(chainId, status?.actionCode, status?.actionText, chainOrders)}
        >
          {status?.actionText}
        </button>
      );
    }

    return null;
  };

  const ajustChangeHandler = (e: any) => {
    if(e?.target?.id){
        let newAjust: any = {};
        newAjust[e.target.id] = {
          ajustText: e.target.value,
          chainId: e.target.dataset.chainid,
          workStartPlan: e.target.dataset.workstartplan,
          workEndPlan: e.target.dataset.workendplan,
        }
        Object.assign(orderAjusts, newAjust);
        setOrderAjusts(JSON.parse(JSON.stringify(orderAjusts)));
    }
  }

  const ajustFocusHandler = (e: any) => {
    if(e?.target?.value === ""){
      e.target.value = `Цепочка: ${e.target.dataset.chainname}\rНаряд: ${e.target.dataset.ordername}\r`;
      ajustChangeHandler(e);
    }
  }
  
  return (
    <>
      {chains.map(chain => {
        const graphParams = getGraphParams(chain.workStartPlan, chain.workEndPlan);

        return (
          <div className={styles.chainBox} key={chain.id}>
            {/* Цепочка: Строка. */}
            <div className={`${styles.chainRow} ${getChainStatusClassname(chain)} item__to-highlight ${openedChains.includes(chain.id) ? 'open' : ''} ${selectedItem === chain.id ? 'highlighted' : ''}`}>

              {/* Кнопка раскрытия Цепочки. */}
              <div>
                <span
                    className="circle-triangle"
                    title="Развернуть / Свернуть"
                    onClick={handleToggleChain(chain.id)}
                />
              </div>

              {/* Название. */}
              <div>
                {chain.name}
                {/* Алгоритм вывода по статусам определён в CSS. */}
                {canEditPlan && renderChainAction(chain.id, chain.orders)}
              </div>

              {/* Состояние Цепочки (состояние Нарядов), текст в CSS. */}
              <div className={styles.chainRowStatus}>{chain.statusText}</div>

              {/* Сумма. */}
              <div>{formatPrice(chain.cost)}</div>

              {/* Область вывода диаграммы Цепочки (1). */}
              <div className={styles.graphBoxWrap}>

                {/* Бокс диаграммы Цепочки. */}
                <div className={styles.graphBox} style={{left: `${graphParams.start}%`, right: `${graphParams.end}%`}}>

                  {/* Блок дат Цепочки. */}
                  <div className={`${styles.graphBoxDates} ${graphParams.className}`}>

                    {/* Дата начала в режиме чтения. */}
                    {!editMode && (
                      <span className="chain__date-start">{parseDateString(chain.workStartPlan)}</span>
                    )}

                    {/* Дата начала в режиме редактирования. */}
                    {editMode && (
                      <input
                        type="date"
                        name="chain__date-start"
                        className={styles.dateSelect}
                        onChange={handleChangeDate(chain.id)}
                        value={parseInputDate(chain.workStartPlan)}
                        disabled={updatingCopyEntitiesId.includes(chain.id)}
                      />
                    )}

                    {/* Дата окончания: автоматический расчёт. */}
                    <span className="symbol__mdash--before">{parseDateString(chain.workEndPlan)}</span>

                  </div>

                  {/* Размер и положение Индикатора. */}
                  <div className={styles.graphBoxLine}></div>

                </div>

              </div>

            </div>

            {/* Цепочка: Наряды. */}
            {openedChains.includes(chain.id) && (
              <div className={styles.chainBoxOrders}>

                {chain.orders.map(order => {
                  const orderGraphParams = getGraphParams(order.workStartPlan, order.workEndPlan);
                  return (
                    <React.Fragment key={order.id}>
                      {/* Наряд (1): Строка. */}
                      <div 
                        className={`${styles.orderRow} ${getOrderStatusClassname(order.status)} item__to-highlight ${openedOrders.includes(order.id) ? 'open' : ''} ${selectedItem === order.id ? 'highlighted' : ''}`}
                        onClick={handleClickOrderRow(order.id, chain.id)}
                      >

                        {/* Кнопка раскрытия Наряда. */}
                        <div>
                          <span
                            className="circle-triangle"
                            title="Развернуть / Свернуть"
                            onClick={handleToggleOrder(order.id, chain.id)}
                          />
                        </div>

                        <div>
                          <a href={`/order/${order.id}`} title="Карточка наряда">{order.orderName}</a>
                        </div>

                        <div className={styles.areaDayBrigade}>
                          <div><span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span> дн.</div>
                          {/* Статус Наряда. */}
                          <div className={styles.orderRowStatus}>{getOrderStatusText(order.status)}</div>
                          <div>{order.brigadeName ? order.brigadeName : '(бригада не подобрана)'}</div>
                        </div>

                        <div>{formatPrice(order.cost)}</div>

                        {/* Область вывода диаграммы Наряда. */}
                        <div className={styles.graphBoxWrap}>

                          {/* Бокс диаграммы Наряда. */}
                          <div className={styles.graphBox} style={{left: `${orderGraphParams.start}%`, right: `${orderGraphParams.end}%`}}>

                            {/* Блок дат. */}
                            <div className={`${styles.graphBoxDates} ${orderGraphParams.className}`}>
                              <span className="symbol__mdash--after">{parseDateString(order.workStartPlan)}</span>
                              <span>{parseDateString(order.workEndPlan)}</span>
                            </div>

                            {/* Полоса Индикатора: ширина и положение. */}
                            <div className={`${styles.graphBoxLine} ${styles.light}`}></div>

                          </div>

                        </div>

                      </div>

                      {/* Наряд (1): Работы. */}
                      {openedOrders.includes(order.id) && (
                        <div className={styles.jobPrices}>

                          {/* Наряд (1): Замечания. */}
                          {/*
                            Содержание области выводится в статусах Разбивки:
                              «Внесение замечаний»
                              для всех Нарядов (доступно внесение замечаний).
                              «Замечания по разбивке»
                              для Нарядов со статусом «Замечания по наряду» (доступен только просмотр).
                          */}

                          <div className={styles.ajustArea}>
                            {((jobPlanStatus === 52) || ((jobPlanStatus === 51) && !canEditPlan)) && order.ajustText && (
                              <>
                                <div className={styles.ajustAreaHead}>Замечания по Наряду</div>
                                <textarea className={styles.ajustAreaField} readOnly value={order.ajustText}/>
                              </>
                            )}
                            {jobPlanStatus === 51 && canEditPlan && (
                              <>
                                <div className={styles.ajustAreaHead}>Замечания по Наряду</div>
                                <textarea className={styles.ajustAreaField} 
                                          id={order.id} 
                                          data-ordername = {order.orderName}
                                          data-workstartplan = {order.workStartPlan}
                                          data-workendplan = {order.workEndPlan}
                                          data-chainid = {chain.id}
                                          data-chainname = {chain.name}
                                          onChange = {ajustChangeHandler} 
                                          onFocus = {ajustFocusHandler}/>
                              </>
                            )}
                          </div>

                          {/* Наряд (1): Список Работ. */}
                          <div className="list__childs--highlight">

                            {order.jobPrice.map((item, index) => {
                              return (
                                <div className={styles.jobPricesRow} key={index}>
                                  <div className="symbol__bull--before"></div>
                                  <div>{item.workName}</div>
                                  <div>{item.count}</div>
                                  <div>{item.unit}</div>
                                  <div>{item.price}</div>
                                  <div>{formatPrice(item.price * item.count)}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}

export default ChainBox;