import React, {useCallback, useMemo} from 'react';
import styles from './StatusBar.module.scss';
import {baseNotification, parseDateString} from '../../../../utils/estimateUtils';
import {IJobPlanData} from '../../../../types/JobPlan';
import {formatPrice} from '../../../../utils/formatPrice';
import {Moment} from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {IStatus} from '../../../../types/Status';
import {
  cancelEditJobPlan,
  openCreateChainWindow,
  saveNewDates,
  startEditJobPlan,
  updateEntityStatus
} from '../../../../redux/jobPlan/jobPlanActions';
import { ISelectedJobPlanItem } from '../../../../redux/jobPlan/types';
import {useParams} from "react-router";
import { SliderValueLabel } from '@mui/material';

interface StatusBarProps {
  startEndDates: [Moment, Moment]
  jobPlanData: IJobPlanData
  canEditPlan: boolean,
  orderAjusts: object
}

function StatusBar({startEndDates, jobPlanData, canEditPlan, orderAjusts}: StatusBarProps) {
  const { id } = useParams<any>();
  const estimateStatuses = useSelector<any>(state => state.jobPlan.estimateStatuses) as IStatus[];
  const editMode = useSelector<any>(state => state.jobPlan.editMode) as boolean;
  const selectedItem = useSelector<any>(state => state.jobPlan.selectedItem) as ISelectedJobPlanItem;
  const dispatch = useDispatch<any>();

  const statusText = useMemo(() => {
    if (!estimateStatuses) {
      return '';
    }

    const status = estimateStatuses.filter(item => item.currentStateCode === jobPlanData.status)?.[0];
    if (status) {
      return status.currentStateText;
    }
  }, [estimateStatuses, jobPlanData.status]);

  const actions = useMemo(() => {
    if (editMode) {
      return  [];
    }

    if (!estimateStatuses) {
      return [];
    }
    const estimateStatusesFiltered = estimateStatuses.filter(item => item.currentStateCode === jobPlanData.status && !!item.actionText);
    estimateStatusesFiltered.forEach(item => {
      if(item.currentStateCode === 51){
        const isAjustPresent = !!Object.entries(orderAjusts).filter(([key, value]) => !!value.ajustText).length;
        item.visible = isAjustPresent;
      } 
    });
    return estimateStatusesFiltered;
  }, [estimateStatuses, editMode, jobPlanData.status, orderAjusts]);

  const handleClickAction = useCallback((actionCode: number, actionText: string) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(updateEntityStatus({
      entityId: id,
      newState: actionCode,
      actionText,
      comment: ''
    }, false, orderAjusts));
  }, [dispatch, jobPlanData.id]);

  const handleStartEdit = useCallback(() => {
    dispatch(startEditJobPlan());
  }, [dispatch]);

  const handleCancelEdit = useCallback(() => {
    dispatch(cancelEditJobPlan());
  }, [dispatch]);

  const handleSaveDates = useCallback(() => {
    dispatch(saveNewDates());
  }, [dispatch]);

  const handleCreateChain = useCallback(() => {
    if (!selectedItem || selectedItem.entityType !== 'order') {
      baseNotification("Выберите наряд с которого будет начинаться новая цепочка", "", "danger");
      return;
    }

    dispatch(openCreateChainWindow());
  }, [selectedItem, dispatch]);

  return (
    <div className={styles.statusBar}>
      <div>{statusText}</div>

      <div>

        {canEditPlan && (actions.map(actionItem => (
          <button
              key={actionItem.actionCode}
              className="bttn__small"
              type="button"
              title={actionItem.actionText}
              onClick={handleClickAction(actionItem.actionCode, actionItem.actionText)}
              disabled={!actionItem.visible}
          >
            {actionItem.actionText}
          </button>
        )))}
        {!canEditPlan &&
          <div>
            <span className={styles.noChange}>изменение сметы недоступно</span>
          </div>
        }

        {/*Кнопка выводится только в статусе «Разбивка выполнена».*/}
        {/*<button className="bttn__small" name="control__status--adjust-add" type="button"*/}
        {/*        title="Перевод в статус «Внесение замечаний»">Замечания…*/}
        {/*</button>*/}

        {/*Кнопка выводится только в статусе «Разбивка выполнена».*/}
        {/*<button className="bttn__small" name="control__status--accept" type="button"*/}
        {/*        title="Перевод в статус «Разбивка утверждена»">Утвердить*/}
        {/*</button>*/}


        {/*Кнопка выводится в статусе «Внесение замечаний».*/}
        {/*Подразумевается, что по умолчанию «disabled»,*/}
        {/*а доступна при изменении хотя бы одного поля замечания.*/}
        {/*Нажатие кнопки должно переводить Разбивку в статус «Замечания по разбивке»*/}
        {/*с сохранением внесённых замечаний.*/}
        {/*<button className="bttn__small" name="control__status--adjust" type="button"*/}
        {/*        title="Перевод в статус «Замечания по разбивке» с сохранением замечаний">Отправить*/}
        {/*</button>*/}


        {/*Кнопка выводится в статусе «Внесение замечаний».*/}
        {/*Нажатие кнопки должно возвращать Разбивку в статус «Разбивка выполнена»*/}
        {/*без сохранения внесённых замечаний.*/}
        {/*<button className="bttn__small" name="control__status--done" type="button"*/}
        {/*        title="Возврат в статус «Разбивка выполнена» без сохранений">Отмена*/}
        {/*</button>*/}

        {/*Ссылка выводится в режиме просмотра.*/}
        {/*Скрывается в режиме редактирования вне зависимости от статуса.*/}
        {!editMode && (
          <a className="link-light" href="/_" title="Открыть страницу внесения изменений">История…</a>
        )}

        {/*Кнопка выводится в режиме редактирования в статусах*/}
        {/*«Разбивка утверждена» и «В работе».*/}
        {/*Предполагается, что по умолчанию «disabled»,*/}
        {/*становится доступной при выделении строки Наряда.*/}
        {/*Нажатие кнопки открывает модальное окно «Создание Цепочки» (JS).*/}
        {canEditPlan && editMode && (
          <button
            className="bttn__small"
            name="chain__new"
            type="button"
            title="Создание Цепочки, начинающейся с выделенного Наряда"
            onClick={handleCreateChain}
          >
            Новая Цепочка
          </button>
        )}
      </div>

      {/*Сумма Разбивки.*/}
      <div>{formatPrice(jobPlanData.cost)}</div>

      {/*Дата начала первого Наряда.*/}
      <div>{parseDateString(startEndDates[0])}</div>

      {/*Кнопки выводятся в статусах «Разбивка утверждена» и «В работе».*/}
      <div>

        {/*Выводится в режиме просмотра.*/}
        {canEditPlan && !editMode && jobPlanData.status === 53 && (
          <button
            className="bttn__small"
            type="button"
            title="Войти в режим изменения дат"
            onClick={handleStartEdit}
          >
            Изменение дат…
          </button>
        )}

        {canEditPlan && editMode && (
          <>
            {/*Выводится в режиме редактирования.*/}
            <button
              className="bttn__small"
              type="button"
              title="Сохранить изменения"
              onClick={handleSaveDates}
            >
              Сохранить
            </button>

            {/*Выводится в режиме редактирования.*/}
            <button
              className="bttn__small"
              type="button"
              title="Выйти из режима редактирования"
              onClick={handleCancelEdit}
            >
              Выйти
            </button>
          </>
        )}
      </div>

      {/*Дата окончания последнего Наряда.*/}
      <div>{parseDateString(startEndDates[1])}</div>
    </div>
  );
}

export default StatusBar;