import React, {useState} from 'react';
import style from './chainSubitems.module.scss';
import {ChainItemsSubitems} from "../ChainItemsSubitems/ChainItemsSubitems";

const ChainSubitems = () => {
  const [openButton, setOpenButton] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const arr = [1, 2, 3];

  return (
      <React.Fragment>
        <div className={style.main}>
          <div className={style.item}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div className={`${open ? 'open' : ''}`} style={{paddingLeft: '4px'}}><span onClick={() => setOpen(!open)} className={`circle-triangle`} title="Развернуть / Свернуть"></span></div>
              <div style={{width: '336px', paddingLeft: '8px'}}>
                Монтаж стальной трубы 1 эт, разводка
                {openButton &&
                    <button className="bttn__small" type="button" name="control__chain--run"
                            title="Подбор бригад для всех Нарядов">Запустить
                    </button>
                }
              </div>
            </div>
            <div className={style.info}>
              <div>
                <span>00</span> дн.
                <span style={{marginLeft: '4px'}}>Cформирован</span>
              </div>
              <div>(бригада не подобрана)</div>
            </div>
            <div>000 000</div>
            <div style={{width: '565px', display: 'flex', justifyContent: 'flex-start'}}>
              <div className="graph__box--chain" style={{marginLeft: "0"}}>
                <div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <span className={style.date}>00 фев -</span>
                    <span className={style.date}>00 мар</span>
                  </div>
                  <div style={{width: "130px", height: '14px', background: 'var(--back-chain-graph)'}}>&emsp;</div>
                  {openButton &&
                      <input style={{height: '14px'}} type="date" name="chain__date-start"/>
                  }
                </div>
                <div style={{width: "200px"}}></div>
              </div>
            </div>
          </div>
          {open && arr.map((el: number) => <ChainItemsSubitems />)}
        </div>
      </React.Fragment>
  )
}

export {ChainSubitems};