import React, {useEffect, useState} from "react";
import Header from "../../components/header/header"
import Table from "../../components/BuildingsList/Table";
import FooterList from "../../components/BuildingsList/FooterList";
import "../../styles/role_contractor.css";
import {useDispatch, useSelector} from "react-redux";
import {getBuildings, getEstimateTypes, getEstimateWork} from "../../redux/actions/actionContractor";
import "../../styles/na_list-contractor.css";
import ObjectPageHead from "../../components/ObjectPageHead/ObjectPageHead";

const BuildingsListPage = ({tab = 0}) => {
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;

  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__building--list role__contractor";
  }
  const dispatch = useDispatch<any>();
  const {buildingsObject, estimateWorkFlow, isLoading, error} = useSelector(
    (state: any) => state.contractorReducer
  );

  useEffect(() => {
    if (!estimateWorkFlow) {
      dispatch(getEstimateWork());
    }
  }, [estimateWorkFlow]);

  useEffect(() => {
    dispatch(getBuildings());
  }, []);

  if (isLoading || !userRoleCode) return <span>Loading...</span>;
  if (error?.code) return <span>Ошибка</span>;

  return (
    <>
      <Header
        buildingsCount={buildingsObject.buildingsCounter}
        complaintsCount={buildingsObject.complaintsCounter}
        ordersCount={buildingsObject.ordersCounter}
        isList
        tab={tab}
      />
      <main style={{marginBottom: "30px"}}>
        <ObjectPageHead title="Список объектов" roleCode={userRoleCode}/>
        <Table buildings={buildingsObject.buildings}/>
      </main>
      <FooterList buildings={buildingsObject.buildings}/>
    </>
  );
};

export default BuildingsListPage;
