import {
  SET_MODAL_STATUS,
  SET_ESTIMATE_SUCCESS,
  SET_ESTIMATE_START,
  SET_MODE_SUCCESS,
  SET_MODAL_CHANGE_REQUISITES_SUCCESS, SET_NEW_FILES_SCUCCESS,
  SET_FILES_SUCCESS,
  DELETE_ITEM_SUCCESS,
  SET_DESCRIPTION_ITEM_SUCCESS,
  SET_ERROR,
  CHANGE_FILES_TO_CURRENT,
  SET_ADDED_FILES_SUCCESS,
  SET_STATUSES_SUCCESS,
  SET_MESSAGE_ITEM_SUCCESS,
  SET_ADD_MESSAGE_TO_MAIN,
  SET_ESTIMATE_WORK_FLOW,
  SAVE_MESSAGE_TO_ADD_FILE_SUCCESS,
} from './actionEstimate';

const initialState = {
  modal: false,
  estimate: {
    info: {},
    documents: []
  },
  isLoading: true,
  changeModal: false,
  filesAdded: [],
  statuses: [],
  addMessage: null,
  mode: false,
  modeEstimator: [],
  error: null,
}

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_MODAL_STATUS:
      return {...state, modal: payload}
    case SET_ESTIMATE_START:
      return {...state, isLoading: true}
    case SET_MODAL_CHANGE_REQUISITES_SUCCESS:
      return {...state, changeModal: payload}
    case SET_MODE_SUCCESS:
      return {...state, mode: payload}
    case SET_ESTIMATE_WORK_FLOW:
      return {...state, modeEstimator: payload}
    case SET_MESSAGE_ITEM_SUCCESS:
      return {...state, addMessage: payload}
    case SAVE_MESSAGE_TO_ADD_FILE_SUCCESS:
      return {
        ...state,
      estimate: {...state.estimate,
        info: {...state.estimate.info, lastMessages: [...payload, ...state.estimate.info.lastMessages]}
      }
      }
    case SET_STATUSES_SUCCESS: {
      return {
        ...state, statuses: payload,
        estimate:
            {
              ...state.estimate,
              info: {...state.estimate.info, estimateStatus: payload.newState}
            }
      }
    }
    case SET_ERROR:
      return {...state, error: payload}
    case SET_DESCRIPTION_ITEM_SUCCESS:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          documents: state.estimate.documents.map((el) => {
            if (el.document.id === payload.id) {
              el.document.description = payload.description
            }
            return el
          })
        }
      }
    case SET_ADD_MESSAGE_TO_MAIN:
      return {
        ...state,
        estimate: {...state.estimate, info: {...state.estimate.info, lastMessages: [payload, ...state.estimate.info.lastMessages]}}
      }
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        filesAdded: state.filesAdded.filter((el) => el.id === payload),
        estimate: {...state.estimate, documents: state.estimate.documents.filter((el) => el.document.id !== payload)}
      }
    case SET_FILES_SUCCESS:
      return {
        ...state,
        estimate: {...state.estimate, documents: [...state.estimate.documents, ...payload]}
      }
    case CHANGE_FILES_TO_CURRENT:
      return {
        ...state,
        estimate: {
          ...state.estimate, documents: state.estimate.documents.map((el) => {
            if (el.document.added) {
              if (payload.find((item) => item.document.originalFileName === el.document.originalFileName)) {
                el = payload.find((item) => item.document.originalFileName === el.document.originalFileName)
              }
            }
            return el
          })
        }
      }
    case SET_ADDED_FILES_SUCCESS:
      return {
        ...state,
        filesAdded: payload
      }
    case SET_NEW_FILES_SCUCCESS:
      return {
        ...state,
        filesAdded: [...state.filesAdded, payload],
        estimate: {...state.estimate, documents: [...state.estimate.documents, {document: payload}]}
      }
    case SET_ESTIMATE_SUCCESS:
      return {...state, estimate: payload, isLoading: false}
    default:
      return {...state};
  }
};

export default reducer;