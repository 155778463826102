import axios from "axios";
import Config from "../../config";
import {GET_ORDER_STATUSES_ERROR, GET_ORDER_STATUSES_START, GET_ORDER_STATUSES_SUCCESS} from "./statusesActionTypes";

const getOrderStatusesStart = () => ({
  type: GET_ORDER_STATUSES_START
});

const getOrderStatusesError = () => ({
  type: GET_ORDER_STATUSES_ERROR
});

const getOrderStatusesSuccess = (payload) => ({
  type: GET_ORDER_STATUSES_SUCCESS,
  payload
});

export const getOrderStatuses = () => async (dispatch) => {
  dispatch(getOrderStatusesStart());

  try {
    const token = JSON.parse(localStorage.getItem("user"));

    const orderStatuses = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetOrderWorkFlow?Id=`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(getOrderStatusesSuccess(orderStatuses.data));
  } catch (e) {
    dispatch(getOrderStatusesError());
  }
};