import React from 'react';
import styles from './Statistics.module.scss';
import {useSelector} from "react-redux";
import {IProfileState} from "../../../../redux/profile/types";
import {IProfileData} from "../../../../types/Profile";

function Statistics() {
  const profile = useSelector<any>(state => state.profile.data) as IProfileData;

  return (
    <div className={styles.statistics}>
      <div className={styles.statisticsLeft}>
        <div className={styles.statisticsCaptions}>
          <span title="Объект бизнес-процесса">Объект БП</span>
          <span>Всего</span>
          <span>В работе</span>
          <span>Завершено</span>
        </div>
        <div className={styles.statisticsRow}>
          <span>Объекты (стройки)</span>
          <span>{profile.statistics?.buildingsTotal || ''}</span>
          <span>{profile.statistics?.buildingsActive || ''}</span>
          <span>{profile.statistics?.buildingsDone || ''}</span>
        </div>
        <div className={styles.statisticsRow}>
          <span>Разбивки</span>
          <span>{profile.statistics?.jobPlansTotal || ''}</span>
          <span>{profile.statistics?.jobPlansActive || ''}</span>
          <span>{profile.statistics?.jobPlansDone || ''}</span>
        </div>
        <div className={styles.statisticsRow}>
          <span>Наряды</span>
          <span>{profile.statistics?.ordersTotal || ''}</span>
          <span>{profile.statistics?.ordersActive || ''}</span>
          <span>{profile.statistics?.ordersDone || ''}</span>
        </div>
      </div>
      <div className={styles.statisticsRight}>
        <span>Рейтинг</span>
        <span className="symbol__bracket--round">высший 5.0</span>
        {/*
            Класс вкладки подставляется согласно рейтингу:
              низкий  — от 0.1 до 3.0 — low
              средний — от 3.1 до 4.1 — medium
              высокий — от 4.2 до 5.0 — high
        */}
        <span className={`
          ${profile.contractor.rating <= 3 ? styles.low : ''}
          ${profile.contractor.rating > 3 && profile.contractor.rating <= 4.1 ? styles.medium : ''}
          ${profile.contractor.rating > 4.1 ? styles.high : ''}
        `}>
          {profile.contractor.rating}
        </span>
      </div>
    </div>
  );
}

export default Statistics;