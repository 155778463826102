import { ContactSupportOutlined } from "@mui/icons-material";
import axios from "axios";
import Config from "../../config";
import {parseDateTimeString, baseNotification} from "../../utils/estimateUtils";
import {
  GET_PROFILE_DATA_ERROR,
  GET_PROFILE_DATA_START,
  GET_PROFILE_DATA_SUCCESS, GET_PROFILE_STATUSES_ERROR,
  GET_PROFILE_STATUSES_START, GET_PROFILE_STATUSES_SUCCESS
} from "./profileActionsTypes";

const getProfileDataStart = () => ({
  type: GET_PROFILE_DATA_START
});

const getProfileDataSuccess = (payload) => ({
  type: GET_PROFILE_DATA_SUCCESS,
  payload,
});

const getProfileDataError = (payload) => ({
  type: GET_PROFILE_DATA_ERROR,
  payload,
});

export const getProfileData = () => async (dispatch) => {
  dispatch(getProfileDataStart());

  const storageUserData = JSON.parse(localStorage.getItem("user"));

  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + "Contractor/GetContractorInfo", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storageUserData.token}`,
      },
      withCredentials: true
    });
    dispatch(getProfileDataSuccess(result.data));
    return result.data;
  }
  catch (e) {
    dispatch(getProfileDataError(e.data));
    return e;
  }
};

const getProfileStatusesStart = () => ({
  type: GET_PROFILE_STATUSES_START
});

const getProfileStatusesSuccess = (payload) => ({
  type: GET_PROFILE_STATUSES_SUCCESS,
  payload,
});

const getProfileStatusesError = (payload) => ({
  type: GET_PROFILE_STATUSES_ERROR,
  payload,
});

export const getProfileStatuses = () => async (dispatch) => {
  dispatch(getProfileStatusesStart());

  const storageUserData = JSON.parse(localStorage.getItem("user"));

  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + "Contractor/GetContractorWorkFlow", {
    // const result = await axios.get(Config.BACKEND_ADDRESS + "Contractor/GetWorkFlow/Card", {  
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storageUserData.token}`,
      },
      withCredentials: true
    });
    dispatch(getProfileStatusesSuccess(result.data));
    return result.data;
  }
  catch (e) {
    dispatch(getProfileStatusesError(e.data));
    return e;
  }
};

export const saveBankAccount = (data) => async (dispatch) => {
  const storageUserData = JSON.parse(localStorage.getItem("user"));

  try {
    const response = await axios.post(Config.BACKEND_ADDRESS + "Contractor/SaveContractorBankAccount", data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storageUserData.token}`,
        },
      });
    dispatch(getProfileData());
    console.log('response', response.data);
    return response.data;
  } catch (e) {
    const errorMsg = e.response.data ?? e.message;
    baseNotification(`Ошибка: ${errorMsg}`, "", "danger");
    return e;
  }
};

export const saveEmployee = (data) => async (dispatch) => {
  const storageUserData = JSON.parse(localStorage.getItem("user"));

  try {
    const response = await axios.post(Config.BACKEND_ADDRESS + "Contractor/SaveContractorEmployee", data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storageUserData.token}`,
        },
      });
    dispatch(getProfileData());
    return response.data;
  } catch (e) {
    return new Promise.reject(e.response);
  }
};

export const saveProfile = (data, changeProfileState = {}) => async (dispatch) => {
  
  const storageUserData = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.post(Config.BACKEND_ADDRESS + "Contractor/SaveContractor2", data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storageUserData.token}`,
        },
      });
    if(Object.keys(changeProfileState).length > 0){
      const changeProfileData = {
        "entityId": data.contractor.id,
        "newState": changeProfileState.newState,
        "actionText": changeProfileState.actionText,
        "comment": "",
        "date": parseDateTimeString(new Date()),
      };
      await axios.post(Config.BACKEND_ADDRESS + "Contractor/FlowContractor", changeProfileData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storageUserData.token}`,
        },
      });
    }
    dispatch(getProfileData());
    return response.data;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

export const changeProfileState = async (data) => {
  return Promise.reject("test error0");
}

export const saveProfilePassword = (password, userId, code) => async (dispatch) => {
  const storageUserData = JSON.parse(localStorage.getItem("user"));

  try {
    const response = await axios.post(Config.BACKEND_ADDRESS + "api/auth/setpassword", {
        password,
        userId,
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storageUserData.token}`,
        },
      });
    return response.data;
  } catch (e) {
    return new Promise.reject(e.response);
  }
};



