import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import ObjectPageHead from '../ObjectPageHead/ObjectPageHead';
import CaptionsBar from './components/CaptionsBar/CaptionsBar';
import Filters from './components/Filters/Filters';
import OrderRow from './components/OrderRow/OrderRow';
import {IOrderListItem} from '../../types/Order';
import { IOrdersListFilters } from '../../pages/OrdersListPage/OrdersListPage';
import { IMeta } from '../../types/Meta';
import Pagination from '../Pagination/Pagination';
import styles from './OrdersList.module.scss';

interface OrdersListProps {
  filters: IOrdersListFilters
  changeFilterField: Function
  page: number
  changePage: (page: number) => void
}

function OrdersList({filters, changeFilterField, page, changePage}: OrdersListProps) {
  const ordersLoading = useSelector<any>(state => state.ordersList.loading) as boolean;
  const orders = useSelector<any>(state => state.ordersList.orders) as IOrderListItem[];
  const meta = useSelector<any>(state => state.ordersList.meta) as IMeta;
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;

  const filteredOrders = useMemo(() => {
    return orders;
  }, [orders]);

  return (
    <div className={`${styles.ordersList} ${ordersLoading ? styles.loading : ''}`}>
      <ObjectPageHead title={`Список нарядов`} roleCode={userRoleCode}/>
      <CaptionsBar/>
      <Filters
        filters={filters}
        changeFilterField={changeFilterField}
      />
      {filteredOrders.map(order => (
        <OrderRow key={order.id} order={order}/>
      ))}
      <Pagination
        totalRecords={meta?.total}
        pageLimit={meta?.count}
        pageNeighbours={2}
        currentPage={page}
        onPageChanged={changePage}
        disabled={ordersLoading}
      />
    </div>
  );
}

export default OrdersList;