import React from "react";
import ObjectRow from "./ObjectRow/ObjectRow";
import {useSelector} from "react-redux";
import ForemanObjectRow from "./ForemanObjectRow/ForemanObjectRow";
import { ROLECODES } from "../../utils/getRoleName";

const Table = ({buildings}: any): JSX.Element => {
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;

  if (!buildings?.length) {
    return (
      <span>Объекты отсутствуют</span>
    );
  }

  //Objects for "Foreman" role
  if (userRoleCode === ROLECODES.FOREMAN) {
    return (
      <>
        {buildings.map((building: any) => (
          <ForemanObjectRow key={building.building.id} building={building}/>
        ))}
      </>
    );
  }

  return (
    <>
      {buildings.map((building: any) => (
        <ObjectRow key={building.building.id} building={building}/>
      ))}
    </>
  );
};

export default Table;
