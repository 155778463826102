import React from "react";
import lineart from "../../assets/lineart.svg";
import Navbar from "../../components/navbar/navbar";
import { useHistory } from "react-router-dom";
import { NewFooter } from "../../components/footer/NewFooter";
import "../../styles/na_home.css";

export default function Home() {
  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__home non-authorized";
  }
  const history = useHistory();
  return (
    <>
      <main>
        <div>
          <p>
            Найди работу
            <br />и оформи
            <br />
            надежную сделку
            <br />
            онлайн
          </p>
          <p>
            Платформа для оформления заказов
            <br />
            на выполнение строительных работ
            <br />
            для заказчиков и исполнителей
          </p>
        </div>

        <button
          title="Откроется страница входа в систему"
          onClick={() => {
            history.push("/login");
          }}
        >
          Войти
        </button>
        <button
          title="Откроется страница регистрации"
          onClick={() => {
            history.push("/register");
          }}
        >
          Зарегистрироваться
        </button>

        <div className="pic__back">
          <img src={lineart} alt="Фоновая картинка" />
        </div>
      </main>
      <NewFooter props = {{hideExitButton: true}}/>
    </>
  );
}
