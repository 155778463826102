import {
  GET_BUILDINGS_FAIL,
  GET_BUILDINGS_START,
  GET_BUILDINGS_SUCCESS,
  GET_ESTIMATE_WORK_FLOW_FAIL,
  GET_ESTIMATE_WORK_FLOW_START, GET_ESTIMATE_WORK_FLOW_SUCCESS,
  GET_ESTIMATETYPES_FAIL,
  GET_ESTIMATETYPES_START,
  GET_ESTIMATETYPES_SUCCESS,
  GET_COMPLAINTCARD_FAIL,
  GET_COMPLAINTCARD_START,
  GET_COMPLAINTCARD_SUCCESS,
  SET_COMPLAINT_DATA_SEND_FAIL,
  SET_COMPLAINT_DATA_SEND_START,
  SET_COMPLAINT_DATA_SEND_SUCCESS,
  GET_PARENT_INFO_BY_ORDERID_FAIL,
  GET_PARENT_INFO_BY_ORDERID_START,
  GET_PARENT_INFO_BY_ORDERID_SUCCESS,
  GET_COMPLAINT_TOPICS_FAIL,
  GET_COMPLAINT_TOPICS_START,
  GET_COMPLAINT_TOPICS_SUCCESS,
  GET_COMPLAINTLIST_FAIL,
  GET_COMPLAINTLIST_START,
  GET_COMPLAINTLIST_SUCCESS,
  GET_CONTRACTOR_INFO_SUCCESS, GET_CONTRACTOR_INFO_START,
  SET_ERROR,
  SET_NEW_BANK_ACCOUNT_SUCCESS
} from "../actions/actionContractor";

const initialState = {
  buildingsObject: {},
  estimateTypes: null,
  estimateWorkFlow: null,
  estimate: null,
  contractorInfo: {},
  complaintCardObject: {},
  complaintTopics: {},
  complaintList: {},
  login: false,
  isLoading: true,
  error: false,
  isSending: false,
  sendError: false,
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BUILDINGS_START:
    case GET_ESTIMATETYPES_START:
    case GET_ESTIMATE_WORK_FLOW_START:
    case GET_COMPLAINTCARD_START:
    case GET_PARENT_INFO_BY_ORDERID_START:
    case GET_COMPLAINT_TOPICS_START:
    case GET_COMPLAINTLIST_START:
      return { ...state, isLoading: true, error: null };
    case GET_BUILDINGS_FAIL:
    case GET_ESTIMATETYPES_FAIL:
    case GET_ESTIMATE_WORK_FLOW_FAIL:
    case GET_COMPLAINTCARD_FAIL:
    case GET_PARENT_INFO_BY_ORDERID_FAIL:
    case GET_COMPLAINT_TOPICS_FAIL:
    case GET_COMPLAINTLIST_FAIL:
      case GET_COMPLAINTLIST_FAIL:
      return { ...state, error: payload, isLoading: false };
    case SET_COMPLAINT_DATA_SEND_START:
      return { ...state, isSending: true, sendError: null};
    case SET_COMPLAINT_DATA_SEND_FAIL:
      return { ...state, isSending: false, sendError: payload};
    case GET_BUILDINGS_SUCCESS:
      return {
        ...state,
        buildingsObject: payload,
        isLoading: false,
        error: null,
      };
    case GET_CONTRACTOR_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contractorInfo: payload
      }
    case GET_CONTRACTOR_INFO_START:
      return {
        ...state,
        isLoading: true
      }
    case GET_ESTIMATETYPES_SUCCESS:
      return {
        ...state,
        estimateTypes: payload,
        isLoading: false,
        error: null,
      }
    case SET_COMPLAINT_DATA_SEND_SUCCESS:
      return{
        ...state,
        isSending: false,
        sendError: null,
      }
    case GET_COMPLAINTCARD_SUCCESS:
    case GET_PARENT_INFO_BY_ORDERID_SUCCESS:
      // test statuses
      // payload.status = 68;
      return{
        ...state,
        complaintCardObject: payload,
        isLoading: false,
        error: null,        
      }
    case GET_COMPLAINT_TOPICS_SUCCESS:
      return{
        ...state,
        complaintTopics: payload,
        isLoading: false,
        error: null,        
      }  
    case GET_COMPLAINTLIST_SUCCESS:
      return{
        ...state,
        complaintList: payload,
        isLoading: false,
        error: null,        
      } 
    case GET_ESTIMATE_WORK_FLOW_SUCCESS:
      return {
        ...state,
        estimateWorkFlow: payload,
        isLoading: false,
        error: null,
      }
    case SET_NEW_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        contractorInfo: {...state.contractorInfo, bankAccounts: [...payload, state.contractorInfo.bankAccounts]}
      }
    case SET_ERROR:
      return {
        ...state,
        error: payload
      }
    default:
      return { ...state };
  }
};

export default reducer;