import {useEffect, useState} from 'react';
import "../../styles/role_foreman.css";
import {useDispatch, useSelector} from "react-redux";
import {getComplaintCard, 
        getParentInfoByOrderId, 
        getComplaintTopics, 
        getContractorInfo} from "../../redux/actions/actionContractor";
import {useLocation} from "react-router-dom";
import {getRoleName, ROLECODES, RoleName} from "../../utils/getRoleName";
import {getComplaintStatus, COMPLAINTCODES, ComplaintStatus} from "../../utils/getComplaints";
import {UploadFile} from "../../utils/uploadFileType";
import {GetHandlers, HandlersType} from "./ComplaintsHandlers";

import Caption from "../../components/ComplaintCard/Caption";
import ParentPanel from "../../components/ComplaintCard/ParentPanel";
import StatusPanel from "../../components/ComplaintCard/StatusPanel";
import Parties from "../../components/ComplaintCard/Parties";
import Replicas from "../../components/ComplaintCard/Replicas";
import ManagePanel from "../../components/ComplaintCard/ManagePanel";
import { NewFooter } from "../../components/footer/NewFooter";

const ComplaintCard = () => {
  const [roleName, setRoleName] = useState<RoleName>("none");
  const [complaintTopic, setComplaintTopic] = useState<number>(0);
  const [openControlPanel, setOpenControlPanel] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [postMessage, setPostMessage] = useState<string>("");
  const [downtimeDate, setDowntimeDate] = useState<string>("");
  const user = useSelector((state: any) => state.userReducer.user);
  const { complaintCardObject, 
          complaintTopics, 
          contractorInfo, 
          isLoading, 
          error,
          isSending,
          sendError} = useSelector((state: any) => state.contractorReducer);
  
  const dispatch = useDispatch<any>();
  const id = new URLSearchParams(useLocation().search).get("id");
  let createId = new URLSearchParams(useLocation().search).get("createId");
  if(id) createId = null;

  useEffect(() => {
    dispatch(getParentInfoByOrderId(createId));
    dispatch(getComplaintCard(id));
    dispatch(getComplaintTopics());
    dispatch(getContractorInfo());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRoleName(getRoleName(user?.role));
  }, [user]);

  useEffect(() => {
    setOpenControlPanel(sendError);
  }, [sendError]);

  const handlers: HandlersType = GetHandlers(
                                 dispatch,
                                 setComplaintTopic, 
                                 setOpenControlPanel, 
                                 setUploadFiles, 
                                 setPostMessage,
                                 setDowntimeDate,
                                 createId,
                                 postMessage,
                                 complaintTopic,
                                 id,
                                 complaintCardObject,
                                 uploadFiles,
                                 downtimeDate);

  if (error) {
    return <div className="page__error-message"><p>Ошибка загрузки карточки спора: {error.message}</p></div>;
  }

  if(createId && !((user?.role == ROLECODES.FOREMAN) || (user?.role == ROLECODES.BRIGADIER))){
    return <div className="page__error-message"><p>Этот пользователь не может создавать спор</p></div>;
  }

  let element = document.getElementById("root");
  if (element !== null) element.classList.value = getRootClassList(roleName, complaintCardObject, createId);
  
  const roleObject = {roleId : user?.role, roleName : roleName};             
  const canControlOpen: boolean = getCanControlOpen(roleName, !!createId, complaintCardObject);

  return (
    <>
      <main>
        <Caption/>
        {isLoading && <div className="page__loading-message"><p>Загрузка...</p></div>}
        <ParentPanel   complaintCardObject = {complaintCardObject} contractorInfo = {contractorInfo}/>
        <StatusPanel   complaintTopics = {complaintTopics} 
                       complaintCardObject = {complaintCardObject}
                       roleObject = {roleObject}
                       setComplaintTopicHandler = {handlers.setComplaintTopicHandler}
                       openControlPanelButtonEnable = {!openControlPanel && canControlOpen && !createId}
                       setOpenControlPanelHandler = {handlers.setOpenControlPanelHandler}
                       />
        <Parties complaintCardObject = {complaintCardObject}/>
        <Replicas complaintCardObject = {complaintCardObject}/>
        {canControlOpen &&
          <ManagePanel openStatus = {(openControlPanel || createId) ? "open" : ""}
                       setCloseControlPanelHandler = {handlers.setCloseControlPanelHandler}
                       uploadFiles = {uploadFiles}
                       setUploadFilesHandler = {handlers.setUploadFilesHandler}
                       setPostMessageHandler = {handlers.setPostMessageHandler}
                       sendPostMessageHandler = {handlers.sendPostMessageHandler}
                       changeComplaintStateToArbitrateHandler = {handlers.changeComplaintStateToArbitrateHandler}
                       changeComplaintStateToEndHandler = {handlers.changeComplaintStateToEndHandler}
                       changeComplaintStateToQueryInitHandler = {handlers.changeComplaintStateToQueryInitHandler}
                       changeComplaintStateToQueryRespHandler = {handlers.changeComplaintStateToQueryRespHandler}
                       changeComplaintStateToOverruleHandler = {handlers.changeComplaintStateToOverruleHandler}
                       changeComplaintStateToSatisfiedHandler = {handlers.changeComplaintStateToSatisfiedHandler}
                       changeComplaintStateToStopHandler = {handlers.changeComplaintStateToStopHandler}
                       setDowntimeDateHandler = {handlers.setDowntimeDateHandler}
                       isSending = {isSending}
                       sendError = {!!sendError}
           />
        }
      </main>
      <NewFooter/>
    </>
  )
}     
export {ComplaintCard};
// for test
//<div>{JSON.stringify(sendError)}</div>
const getRootClassList = (roleName: string, complaintCardObject: any, createId: any): string => {
  let res = `page__complaint--card role__${roleName}`;
  res += 
    createId ? " status__complaint--initiate" : " status__complaint--" + getComplaintStatus(complaintCardObject.status);
  if([COMPLAINTCODES.RESPOND, COMPLAINTCODES.RESTART, COMPLAINTCODES.QUERY].includes(complaintCardObject.status))
    res += " complaint__party--initiator";
  if(complaintCardObject.status == COMPLAINTCODES.ARBITRATE)
    res += " complaint__party--arbitrator";
  //test  if(!complaintCardObject.downtime)
  if(complaintCardObject.downtime)
    res += " status__complaint--arbitrate-downtime";
  return res;
}

const getCanControlOpen = (roleName: string, isCreate: boolean, complaintCardObject: any) :boolean => {
  const complaintStatus: ComplaintStatus = getComplaintStatus(complaintCardObject.status);
  if((roleName === "arbitrate") && (complaintStatus == "arbitrate")) return true;
  if (roleName === "foreman"){
    if(isCreate) return true;
    if(["initiate", "respond", "restart"].includes(complaintStatus)) return true;
    if((complaintStatus === "start") && (complaintCardObject.respondentRole === ROLECODES.FOREMAN)) return true;
    if((complaintStatus === "query") && isArbitratorAskForeman(complaintCardObject)) return true;
  } 
  return false;
}

const isArbitratorAskForeman = (complaintCardObject: any): boolean => {
  const messages = complaintCardObject?.messages;
  if(!Array.isArray(messages)) return false;
  const arbitratorMessages = messages.filter((messageItem: any) => messageItem?.message?.authorRole === ROLECODES.ARBITRATE);
  if(!arbitratorMessages) return false;
  if(arbitratorMessages[arbitratorMessages.length - 1]?.message?.respondentRole !== ROLECODES.FOREMAN) return false;
  return true;
}