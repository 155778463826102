import React, {useCallback, useState} from 'react';
import styles from './MutualListEstimate.module.scss';
import {IBillingEstimate} from "../../../../../../types/Billing";
import {formatPrice} from "../../../../../../utils/formatPrice";
import MutualListContract from "../MutualListContract/MutualListContract";

interface MutualListEstimateProps {
  estimate: IBillingEstimate
}

function MutualListEstimate({estimate}: MutualListEstimateProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div className={styles.estimateSection}>
      <div className={`${styles.estimateRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <a href="/_" target="_blank" title="Карточка сметы">{estimate.name}</a>
        </div>
        <div></div>
        <div>{estimate.paidPlatform ? formatPrice(estimate.paidPlatform) : ''}</div>
        <div>{estimate.paidBrigade ? formatPrice(estimate.paidBrigade) : ''}</div>
        <div>{estimate.actPlatform ? formatPrice(estimate.actPlatform) : ''}</div>
        <div>{estimate.actBrigade ? formatPrice(estimate.actBrigade): ''}</div>
      </div>

      {isOpen && (
        <>
          {estimate.subItems.map(contract => (
            <MutualListContract key={contract.entityId} contract={contract}/>
          ))}
        </>
      )}
    </div>
  );
}

export default MutualListEstimate;