import {UploadFile} from "../../utils/uploadFileType";

import ComplaintMessage from "../../components/ComplaintCard/ManagePanels/ComplaintMessage";
import ComplaintInitiator from "../../components/ComplaintCard/ManagePanels/ComplaintInitiator";
import ComplaintArbitrator from "../../components/ComplaintCard/ManagePanels/ComplaintArbitrator";

interface ManagePanelParams{
    openStatus: string;
    setCloseControlPanelHandler: any;
    uploadFiles: UploadFile[];
    setUploadFilesHandler: any;
    setPostMessageHandler: any;
    sendPostMessageHandler: any;
    changeComplaintStateToArbitrateHandler: any,
    changeComplaintStateToEndHandler: any,
    changeComplaintStateToSatisfiedHandler: any,
    changeComplaintStateToQueryInitHandler: any,
    changeComplaintStateToQueryRespHandler: any,
    changeComplaintStateToOverruleHandler: any,
    changeComplaintStateToStopHandler: any,
    setDowntimeDateHandler: any,
    isSending: boolean;
    sendError: boolean;
}

const ManagePanel = (params: ManagePanelParams): JSX.Element => {
  const {openStatus, 
         setCloseControlPanelHandler, 
         uploadFiles, 
         setUploadFilesHandler, 
         setPostMessageHandler,
         sendPostMessageHandler,
         changeComplaintStateToArbitrateHandler,
         changeComplaintStateToEndHandler,
         changeComplaintStateToSatisfiedHandler,
         changeComplaintStateToQueryInitHandler,
         changeComplaintStateToQueryRespHandler,
         changeComplaintStateToOverruleHandler,
         changeComplaintStateToStopHandler,
         setDowntimeDateHandler,
         isSending,
         sendError} = params;
  return (
    <div id="box__manage-complaint" className={openStatus}>
        <ComplaintMessage    setCloseControlPanelHandler = {setCloseControlPanelHandler} 
                             uploadFiles = {uploadFiles}
                             setUploadFilesHandler = {setUploadFilesHandler}
                             setPostMessageHandler = {setPostMessageHandler}
                             sendPostMessageHandler = {sendPostMessageHandler}
                             isSending = {isSending}
                             sendError = {sendError}/>
        <ComplaintInitiator  setCloseControlPanelHandler = {setCloseControlPanelHandler}
                             changeComplaintStateToArbitrateHandler = {changeComplaintStateToArbitrateHandler}
                             changeComplaintStateToEndHandler = {changeComplaintStateToEndHandler}
                             changeComplaintStateToSatisfiedHandler = {changeComplaintStateToSatisfiedHandler}
                             setDowntimeDateHandler = {setDowntimeDateHandler}/>
        <ComplaintArbitrator setCloseControlPanelHandler = {setCloseControlPanelHandler}
                             setPostMessageHandler = {setPostMessageHandler}
                             changeComplaintStateToQueryInitHandler = {changeComplaintStateToQueryInitHandler}
                             changeComplaintStateToQueryRespHandler = {changeComplaintStateToQueryRespHandler}
                             changeComplaintStateToOverruleHandler = {changeComplaintStateToOverruleHandler}
                             changeComplaintStateToSatisfiedHandler = {changeComplaintStateToSatisfiedHandler}
                             changeComplaintStateToStopHandler = {changeComplaintStateToStopHandler}
                             setDowntimeDateHandler = {setDowntimeDateHandler}/>
    </div>
  );
};

export default ManagePanel;