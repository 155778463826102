import { getComplaintStatus } from "../../utils/getComplaints";
import { handlePlanDate } from "../../utils/estimateUtils";
import { ROLECODES } from "../../utils/getRoleName";
import { useHistory } from "react-router-dom";
interface ComplaintListPanelParams{
  complaintList: any,
  complaintTopics: any,
}

const ComplaintListPanel = (params: ComplaintListPanelParams): JSX.Element => {
  const {complaintList, complaintTopics} = params;
  const history = useHistory();
  const getTopicName = (topicId: number): string => {
    const foremanComplaints = complaintTopics[0]?.items;
    const brigadireComplaints = complaintTopics[1]?.items;
    if(!foremanComplaints || !brigadireComplaints) return "";
    const allComplaints = foremanComplaints.concat(brigadireComplaints);
    for (let i = 0; i < allComplaints.length; i++){
      if(allComplaints[i].id === topicId) return allComplaints[i].name;
    }
    return "";
  }

  const getRespondentName = (respondentRole: number): string => {
    if(respondentRole === ROLECODES.BRIGADIER) return "Бригадир";
    if(respondentRole === ROLECODES.FOREMAN) return "Прораб";
    return "";
  }

  if(!complaintList?.complaints) return (<></>);

  const goToPage = (id: string) => {
    history.push(`/complaint-card?id=${id}`);
  };

  return (
    <>
		  {
        complaintList.complaints.map((complaintItem: any, key: any) => {
          if(!complaintItem) return (<></>);
          const complaintStatusName = getComplaintStatus(complaintItem.status);
          const topicName = getTopicName(complaintItem.topicId);
          const respondentName = getRespondentName(complaintItem.respondentRole);
          return(
            <div onClick={() => goToPage(complaintItem.id)}> 
              <div className={`row__complaint animate__clickable status__complaint--${complaintStatusName}`}>
                <div className="limit__text--length">{complaintItem.buildingName}</div>
                <div className="limit__text--length">{complaintItem.buildingAddress}</div>
                <div className="limit__text--length">{complaintItem.estimateTitle}</div>
                <div className="limit__text--length">{complaintItem.orderName}</div>
                <div>{topicName}</div>
            
                <div>
                  {complaintItem?.textMessages ? complaintItem?.textMessages[0] : null}
                </div>
            
                <div className="text__status--complaint"></div>
            
                <div className="limit__text--length">{complaintItem.authorFio}</div>
            
                <div>
                  <span>{handlePlanDate(complaintItem.startDate, false)}</span>
                  <span className="symbol__mdash--before"></span>
                  <span className="symbol__hellip--before"></span>
                  <span>{handlePlanDate(complaintItem.endDate, false)}</span>
                </div>
            
                <div>
                  <span>{handlePlanDate(complaintItem.downtimeStartDate, false)}</span>
                  <span className="symbol__mdash--before"></span>
                  <span className="symbol__hellip--before"></span>
                  <span>{handlePlanDate(complaintItem.downtimeEndDate, false)}</span>
                </div>
            
                <div>
                  <span className="symbol__mdash--before"></span>
                  <span>{respondentName}</span>
                </div>
            
                <div title="Продолжительность простоя">
                  <span className="symbol__mdash--before"></span>
                  <span>
                    <span>{complaintItem.downtime}</span><span>дн.</span>
                  </span>
                </div>
              </div>
            </div>
          );
      })}
	  </>
  );
};
export default ComplaintListPanel;