import React from 'react';
import styles from './ObjectPageHead.module.scss';
import { getRoleTitle } from "../../utils/getRoleName";

interface ObjectPageHeadProps {
  title: string,
  roleCode: string | number | null;
}

function ObjectPageHead({title, roleCode}: ObjectPageHeadProps) {
  return (
    <h2 className={styles.objectPageHead}>
      <span className={styles.objectPageHeadTitle}>{title}</span>
      {roleCode && (
        <span className={styles.objectPageHeadInfoText}>{getRoleTitle(roleCode)}</span>
      )}
    </h2>
  );
}

export default ObjectPageHead;