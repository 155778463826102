import React, {useState} from "react";
import Estimates from "../Estimates";
import {useSelector} from "react-redux";
import {ModalEstimate} from "../Modals/ModalEstimate";

const ObjectRow = ({ building }: any) => {
  const [modal, setModal] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes);

  return (
    <div className={`building__box ${isOpen ? "open" : ""}`}>
      <ModalEstimate buildingId={building.building.id} types={estimateTypes} isOpen={modal} setModal={setModal} />
      <div className="data-building__area">
        <span
          className="circle-triangle"
          title="Развернуть / Свернуть"
          onClick={() => {
            setOpen(!isOpen);
          }}
        />

        <div className="limit__text--length">
          <a href="/_" target="_blank" title="Карточка объекта (переход)">
            {building.building.name}
          </a>
        </div>

        <div className="limit__text--length">{building.building.address}</div>

        <div>
          <span style={{gridColumn: "1/4", justifySelf:"center"}} title="Старт самой ранней Разбивки">{building.periodInfo}</span>
        </div>

        <div>
          <span
            className="symbol__colon--after"
            title="Общая стоимость всех Разбивок"
          >
            Всего
          </span>
          <span>{building.signedEstimatesCost}</span>
        </div>

        <div>
          <span
            className="symbol__colon--after"
            title="«Всего» минус «В работе»"
          >
            Остаток
          </span>
          <span>{building.signedEstimatesCost - building.prepaymentCost}</span>
        </div>

        <div>
          <span
            className="symbol__colon--after"
            title="Cумма авансов по всем договорам (Нарядам) СМР"
          >
            В работе
          </span>
          <span>{building.prepaymentCost}</span>
        </div>

        <div>
          <span
            className="symbol__colon--after"
            title="Сумма по всем закрытым договорам (Нарядам) СМР"
          >
            Выполнено
          </span>
          <span>{building.payedCost}</span>
        </div>

        <div>
          <span className="symbol__colon--after" title="Всего / В работе">
            Смет
          </span>
          <span style={{gridColumn:`${isOpen ? '4/5' : '2/3'}`, justifySelf:`${isOpen ? 'end' : 'center'}`}}>{building.estimatesInfo.replace(/смет:/g, '')}</span>
        </div>
        <button onClick={() => setModal(true)} className="bttn__small control__creating--estimate">
          Новая смета
        </button>
      </div>

      <Estimates estimates={building.estimates} />
    </div>
  );
};

export default ObjectRow;
