import axios from "axios";
import Config from "../../config";
import {baseNotification} from "../../utils/estimateUtils";
import { sendFlowEstimate } from '../actions/actionContractor';

export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
export const SET_ESTIMATE_SUCCESS = 'SET_ESTIMATE_SUCCESS';
export const SET_FILES_SUCCESS = 'SET_FILES_SUCCESS';
export const SET_ESTIMATE_START = 'SET_ESTIMATE_START';
export const SET_MODE_SUCCESS = 'SET_MODE_SUCCESS';
export const SET_MODAL_CHANGE_REQUISITES_SUCCESS = 'SET_MODAL_CHANGE_REQUISITES_SUCCESS';
export const SET_NEW_FILES_SCUCCESS = 'SET_NEW_FILES_SCUCCESS';
export const CHANGE_FILES_TO_CURRENT = 'CHANGE_FILES_TO_CURRENT';
export const SET_STATUSES_SUCCESS = 'SET_STATUSES_SUCCESS';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const SET_DESCRIPTION_ITEM_SUCCESS = 'SET_DESCRIPTION_ITEM_SUCCESS';
export const SET_ADDED_FILES_SUCCESS = 'SET_ADDED_FILES_SUCCESS';
export const SET_MESSAGE_ITEM_SUCCESS = 'SET_MESSAGE_ITEM_SUCCESS';
export const SET_ADD_MESSAGE_TO_MAIN = 'SET_ADD_MESSAGE_TO_MAIN';
export const SAVE_MESSAGE_TO_ADD_FILE_SUCCESS = 'SAVE_MESSAGE_TO_ADD_FILE_SUCCESS';
export const SET_ESTIMATE_WORK_FLOW = 'SET_ESTIMATE_WORK_FLOW';
// export const SAVE_ESTIMATE_SUCCESS = 'SAVE_ESTIMATE_SUCCESS';
export const SET_ERROR = 'SET_ERROR';

export const setModal = (payload) => ({
  type: SET_MODAL_STATUS,
  payload
})

export const setMessageItem = (payload) => ({
  type: SET_MESSAGE_ITEM_SUCCESS,
  payload
})

const setAddMessageToMain = (payload) => ({
  type: SET_ADD_MESSAGE_TO_MAIN,
  payload
})

export const setDescriptionItem = (payload) => ({
  type: SET_DESCRIPTION_ITEM_SUCCESS,
  payload
})

const setEstimateStart = () => ({
  type: SET_ESTIMATE_START,
})

export const setModeSuccess = (payload) => ({
  type: SET_MODE_SUCCESS,
  payload
})

export const setModalChangeRequisites = (payload) => ({
  type: SET_MODAL_CHANGE_REQUISITES_SUCCESS,
  payload
})

const setFilesSuccess = (payload) => ({
  type: SET_FILES_SUCCESS,
  payload
})

export const deleteItemSuccess = (payload) => ({
  type: DELETE_ITEM_SUCCESS,
  payload
})

const setAddedFiles = (payload) => ({
  type: SET_ADDED_FILES_SUCCESS,
  payload
});

const setError = (payload) => ({
  type: SET_ERROR,
  payload
})

const setStatusesSuccess = (payload) => ({
  type: SET_STATUSES_SUCCESS,
  payload
})

const setEstimate = (payload) => ({
  type: SET_ESTIMATE_SUCCESS,
  payload
})

const changeFilesToCurrent = (payload) => ({
  type: CHANGE_FILES_TO_CURRENT,
  payload
});

const setEstimateWorkFlow = (payload) => ({
  type: SET_ESTIMATE_WORK_FLOW,
  payload
})

export const getEstimateById = (id) => async (dispatch) => {
  dispatch(setEstimateStart())
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetEstimate/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimate(result.data));
    return result.data;
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getEstimatorEstimate = (id) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetOrderById?Id=${id}`, {
      headers: {
        Authorization: `Bearer ${token.token}`,
      }
    })
    console.log(result.data);
    dispatch(setEstimate(result.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const saveEstimateSuccess = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/SaveEstimate', data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimate(result.data));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const saveEstimateBreakdownSuccess = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/SaveEstimate', data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimate(result.data));
    await sendFlowEstimate({entityId: data.id, newState: 35})(dispatch);
    dispatch(getEstimateById(data.id));
  } catch (e) {
    dispatch(setError(e))
  }
}

const saveMessageToAddFile = (payload) => ({
  type: SAVE_MESSAGE_TO_ADD_FILE_SUCCESS,
  payload
});

export const saveEstimateFile = (data) => async (dispatch) => {
  try {
    const result = await axios.post(Config.BACKEND_ADDRESS + `File/UploadFile`, data, {headers: {
        'content-type': 'multipart/form-data',
      }});
    return result.data;
  } catch (e) {
    dispatch(setError(e))
  }
}

export const setNewFiles = (payload) => ({
  type: SET_NEW_FILES_SCUCCESS,
  payload
});

export const saveNewMessage = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    await axios.post(Config.BACKEND_ADDRESS + `Contractor/AddEstimateMessage`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`
      },
    })
  } catch (e) {
    dispatch(setError(e))
  }
}

export const saveEstimateDocument = (data) => async (dispatch, getState) => {
  const state = getState();
  const addMessage = state.estimateReducer.addMessage
  const addedFiles = state.estimateReducer.filesAdded
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const arr = [];
    await Promise.all(data.map(async (el) => {
      const result = await axios.post(Config.BACKEND_ADDRESS + `Contractor/SaveEstimateDocument`, {
        description: el.description,
        documentType: el.documentType,
        url: el.url,
        originalFileName: el.originalFileName,
        fileId: el.fileId,
        contentType: el.contentType,
        estimateId: el.estimateId,
        json: el.json
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        }
      })
      arr.push(result.data)
      baseNotification("Успешно", `файл ${result.data.document?.originalFileName} добавлен`, "success");
    }));
    dispatch(changeFilesToCurrent(arr));
    dispatch(setMessageAddFile(addMessage));
    dispatch(setMessageToAddFile(addedFiles));
    dispatch(setAddedFiles([]));
  } catch (e) {
    console.log(e);
    dispatch(setError(e));
    baseNotification("Ошибка", e?.response?.data, "danger");
  }
}

export const deleteEstimateDocument = (data) => async (dispatch) => {
  try {
    // const token = JSON.parse(localStorage.getItem("user"));
    //
    // const result = await axios.post(Config.BACKEND_ADDRESS + `Contractor/DeleteEstimateDocument`, {
    //   estimateId: data.estimateId,
    //   documentId: data.documentId,
    //   deletedReason: "string"
    // }, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token.token}`,
    //   }
    // })

    dispatch(deleteItemSuccess(data.documentId));
    baseNotification("Файл удален", "", "success");
  } catch (e) {
    baseNotification("Ошибка", "", "danger");
  }

}

const setMessageToAddFile = (addedFiles) => (dispatch) => {
  const nameFilesAdded = [];
  addedFiles.map((el) => {
    nameFilesAdded.push({
      documents: null,
      message: {
        text: `добавлен документ ${el.originalFileName}`,
        estimateId: el.estimateId,
        createdAt: new Date().toISOString()
      }
    })
  })
  dispatch(saveMessageToAddFile(nameFilesAdded));
};

const setMessageAddFile = (addMessage) => (dispatch) => {
  try {
    if (addMessage) {
      dispatch(saveNewMessage(addMessage))
      const newMessage = {
        document: null,
        message: {
          text: addMessage.messageText,
          estimateId: addMessage.estimateId,
          createdAt: new Date().toISOString()
        }
      }
      dispatch(setAddMessageToMain(newMessage))
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateStatusEstimate = (data) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/FlowEstimate', data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    })
    dispatch(setStatusesSuccess(data));
    dispatch(getEstimateById(data.entityId));
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getStatusesEstimate = () => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const response =  await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetEstimateWorkFlow`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setEstimateWorkFlow(response.data));
  } catch (e) {
    dispatch(setError(e))
  }
}