import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import style from './modalAddBreakdown.module.scss';
import { useFilter } from '../../../../hooks/useFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounced } from '../../../../hooks/useDebounced';
import {
  deleteItemSuccess, saveEstimateBreakdownSuccess,
  saveEstimateFile,
  setDescriptionItem,
  setNewFiles,
} from '../../../../redux/estimate/actionEstimate';
import moment from 'moment/moment';
import { parseDate } from '../../../../utils/estimateUtils';

const ModalAddBreakdown = ({isOpen, setModal, estimate, estimateTypes}) => {
  const initialState = {
    id: estimate?.info?.id,
    title: estimate?.info?.title,
    foremanId: '',
    workStartPlan: estimate.documents[0]?.estimate?.workStartPlan,
    typeId: 0,
    workEndPlan: estimate.documents[0]?.estimate?.workEndPlan,
    note: estimate?.info?.estimateNote,
    prepayment: '',
    detailing: '',
    offert: false,
    bankAccountId: ''
  }

  const initialValid = {
    foremanId: false,
    bankAccountId: false,
    workStartPlan: true,
    workEndPlan: true,
    prepayment: false,
    detailing: false,
    offert: false,
  }
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [valid, setValid] = useState(initialValid);
  const employees = useSelector((state) => state.contractorReducer.contractorInfo?.employees)?.filter((item) => item?.firstName?.length > 1 && !item.isBlocked && item.role === 3);
  const bankAccounts = useSelector((state) => state.contractorReducer.contractorInfo?.bankAccounts);
  const arr = useFilter(estimate.documents, 4, 'type');

  let element = document.getElementById('root');
  if (element !== null && isOpen) {
    element.classList.value = 'page__building--list role__contractor modal__open--creating-building';
  }

  const getInitialType = () => {
    let item = {}
    if (estimateTypes?.length) {
      for (let i = 0; i < estimateTypes.length; i++) {
        if (estimateTypes[i]?.items?.find((el) => el.name === estimate.info.types)) {
          item = estimateTypes[i]?.items?.find((el) => el.name === estimate.info.types)
          setValues((data) => ({...data, typeId: item?.id}))
        }
      }
    }
  }


  const onChange = (e) => {
    const {name, value} = e.target;
    switch (name) {
      case 'prepayment':
      case 'detailing':
        setValues({
          ...values,
          [name]: +value,
        })
        setValid({
          ...valid,
          [name]: true,
        })
        break;
      case 'workEndPlan':
      case 'workStartPlan':
        setValues({
          ...values,
          [name]: new Date(value).toISOString(),
        })
        setValid({
          ...valid,
          [name]: true,
        })
        break;
      case 'offert':
        setValues({
          ...values,
          offert: !values.offert,
        })
        setValid({
          ...valid,
          offert: !valid.offert,
        })
        break;
      default:
        setValues({
          ...values,
          [name]: value,
        })
        setValid({
          ...valid,
          [name]: true,
        })
    }
  }

  const handleDisabledButton = () => {
    //if (arr.length === 0) return true;
    return !Object.keys(valid).every((item) => valid[item]);
  }

  const saveEstimate = () => {
    dispatch(saveEstimateBreakdownSuccess(values));
    setModal(false);
  };

  const getCalculatedCost = (checkout) => {
    if (checkout) {
      return estimate?.info?.calculationWithCheckCost + estimate?.info?.calculationWithCheckCost * 0.03
    } else {
      return estimate?.info?.calculationWithCheckCost + estimate?.info?.calculationWithoutCheckCost * 0.01
    }
  }

  useEffect(() => {
    getInitialType();
  }, [estimateTypes]);

  useEffect(() => {
    const {workStartPlan, workEndPlan, prepayment, detailing} = values;
    // if (workEndPlan && workStartPlan) {
    //   setValid({
    //     ...valid,
    //     workData: moment(parseDate(workEndPlan)).isAfter(parseDate(workStartPlan), [
    //       'day',
    //       'month',
    //       'year',
    //     ]),
    //   })
    // }
    if (prepayment && detailing) {
      setValues({
        ...values,
        prepayment: +values.prepayment,
        detailing: +values.detailing,
      })
    }
  }, [values.workStartPlan, values.workEndPlan, values.prepayment, values.detailing]);

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div id="modal__box">
      <div className="modal__back" />
      <div className={`modal__window creating__building`} style={{background: 'rgb(234, 232, 230)', height: '690px'}}>
        <h3>Запрос Разбивки</h3>

        <div>
          Заполните поля и выберите необходимые опции.
        </div>

        <form className={style.form} style={{display: 'flex', flexDirection: 'column'}}>
          <fieldset className={style.firstFieldSet}>
            <label style={{marginLeft: '-24px'}}>Прораб</label>
            <select className={style.selected} name="foremanId" value={values.contractorEmployeeId} onChange={onChange} style={{width: '400px'}}>
              <option hidden>(обязательное)</option>
              {employees?.map((item) =>
                <option
                  value={item?.contractorEmployeeId}
                  selected=""
                >
                  {item?.lastName} {item?.firstName} {item?.secondName}
                </option>)
              }
            </select>
          </fieldset>

          <fieldset className={style.secondFieldSet}>
            <label style={{fontSize: '12px', marginLeft: '-6px'}}>Плановые сроки выполнения работ по смете</label>
            <div style={{background: 'none', border: 'none'}}>
              <label className="symbol__colon--after" style={{fontFamily: 'thin', marginRight: '10px'}}>Начало</label>
              <input name="workStartPlan" type="date" onChange={onChange} value={values.workStartPlan?.split('T')[0]}
                     field={'workStartPlan'} />
            </div>
            <div style={{background: 'none', border: 'none', marginRight: '20px'}}>
              <label className="symbol__colon--after"
                     style={{fontFamily: 'thin', marginRight: '10px'}}>Окончание</label>
              <input name="workEndPlan" type="date" onChange={onChange} value={values.workEndPlan?.split('T')[0]}
                     field={'workEndPlan'} />
            </div>
          </fieldset>

          <fieldset className={style.threeFieldSet}>
            <div style={{
              height: '23px',
              border: 'none',
              background: 'rgb(218, 223, 226)',
              padding: '0',
              marginRight: '22px',
            }}>
              <legend style={{width: '550px', marginBottom: '4px'}}>Загрузка файла(ов) Технического задания на
                Разбивку
              </legend>
            </div>
            <DownloadFiles dispatch={dispatch} estimate={estimate} type={4} />
            <div style={{
              margin: '0',
              height: '112px',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              background: 'none',
              border: 'none',
              width: '100%',
              padding: '0',
            }}>
              {arr.map((el) =>
                <ListFilesModal dispatch={dispatch} file={el} />,
              )}
            </div>
          </fieldset>

          <fieldset className={style.foreFieldSet}>
            <label
              style={{fontSize: '12px', width: '170px', fontFamily: 'regular', paddingBottom: '20px'}}>Форма
              авансирования для договоров СМР</label>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              background: 'none',
              border: 'none',
              margin: '0 180px 0 0',
              padding: '0',
              height: '60px'
            }}>
              <label onChange={onChange} style={{width: '190px'}}>
                <div style={{display: 'flex', height: '24px', background: 'none', border: 'none', margin: 0, padding: 0, width: '520px'}}>
                  <input style={{paddingTop: "10px"}} name="prepayment" type="radio" value={1} />
                  <span style={{marginRight: '23px'}}>Эскроу-счёт</span>
                  {values.prepayment === 1 && <BankAccounts setValues={setValues} setValid={setValid} onChange={onChange} accounts={bankAccounts} filtered={true} />}
                </div>
              </label>
              <label onChange={onChange} style={{width: '190px'}}>
                <div style={{display: 'flex', height: '24px', background: 'none', border: 'none', margin: 0, padding: 0, width: '520px'}}>
                  <input style={{paddingTop: "10px"}} name="prepayment" type="radio" value={2} />
                  <span style={{marginRight: '14px'}}
                        className="symbol__quotes">
                  Платформа
                </span>
                  {values.prepayment === 2 && <BankAccounts setValues={setValues} setValid={setValid} onChange={onChange} accounts={bankAccounts} filtered={false} />}
                </div>
              </label>
            </div>
          </fieldset>

          <fieldset style={{marginBottom: '24px'}} className={style.foreFieldSet}>
            <label style={{
              fontSize: '12px',
              width: '140px',
              fontFamily: 'regular',
              paddingBottom: '20px',
              marginRight: '20px',
            }}>Стоимость составления Разбивки</label>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              background: 'none',
              border: 'none',
              margin: '0 180px 0 0',
              padding: '0',
              height: '60px'
            }}>
              <label onChange={onChange} style={{width: '190px'}}>
                <div style={{display: 'flex', height: '24px', background: 'none', border: 'none', margin: 0, padding: 0, width: '520px'}}>
                  <input style={{paddingTop: "10px"}} type="radio" name="detailing" value={1} /><span className="symbol__colon--after">С проверкой объёмов</span>
                  <span> {getCalculatedCost(true)}</span>
                </div>
              </label>
              <label onChange={onChange} style={{width: '190px'}}>
                <div style={{display: 'flex', height: '24px', background: 'none', border: 'none', margin: 0, padding: 0, width: '520px'}}>
                  <input style={{paddingTop: "10px"}} type="radio" name="detailing" value={2} /><span
                  className="symbol__colon--after">Без проверки объёмов</span>
                  <span> {getCalculatedCost(false)}</span>
                </div>
              </label>
            </div>
          </fieldset>

          <fieldset style={{display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0'}}>
            <button onClick={saveEstimate} disabled={handleDisabledButton()} style={{width: '120px', height: '46px'}}
                    className="control__modal--close" type="button">Запросить
              Разбивку
            </button>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              background: 'none',
              border: 'none',
              padding: '0',
              margin: '0',
            }}>
              <label style={{width: '280px', marginTop: '8px'}}>
                Нажимая кнопку «Запросить Разбивку» <br /> вы соглашаетесь с условиями
                <a href="/_" target="_blank" title="Переход к просмотру договора"> оферты</a>
              </label>
              <label onChange={onChange}>
                <input name="offert" type="checkbox" /><span>Согласен</span>
              </label>
            </div>
            <button onClick={() => setModal(false)} style={{marginRight: '12px'}} className="control__modal--close"
                    type="button">Отмена
            </button>
          </fieldset>
        </form>

      </div>
    </div>,
    document.body)
}

const BankAccounts = ({accounts, filtered, onChange, setValues, setValid}) => {
  const filteredAccounts = accounts.filter((item) => item.escrow === filtered);
  useEffect(() => {
    if (filteredAccounts.length !== 0) {
      setValues((data) => ({...data, bankAccountId: filteredAccounts[0]?.id}))
      setValid((data) => ({...data, bankAccountId: true}))
    } else {
      setValues((data) => ({...data, bankAccountId: ''}))
      setValid((data) => ({...data, bankAccountId: false}))
    }
  }, []);

  return (
    <select name="bankAccountId" selected={false} onChange={onChange} style={{width: '240px', height: '24px'}} className={`${filteredAccounts.length ? style.selected : style.nonSelect}`} disabled="">
      {!filteredAccounts.length ? <option>Нет р/счёта. Добавьте р/счёт в Профиле.</option> :
        <>
          {filteredAccounts.map((item) => <option key={item.id} value={item.id} selected="">{item.bankName} ...{item.paymentAccount.slice(item.paymentAccount.length - 4)}</option>)}
        </>
      }
    </select>
  )
}

const DownloadFiles = ({estimate, type, dispatch}) => {
  const [file, setFile] = useState();
  const [description, setDescription] = useState('');
  const [fileResponse, setFileResponse] = useState([]);
  const [filesAdded, setFilesAdded] = useState(false);

  const fileRef = useRef();

  const setNewFile = async (e) => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('files', e.target.files[0]);
    const result = await dispatch(saveEstimateFile(formData));
    setFileResponse(result);
    setFilesAdded(true);
  };

  function randomString(len) {
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  const addDocument = async () => {
    if (file) {
      const data = {
        description: description,
        documentType: type,
        url: '123',
        originalFileName: file?.name,
        fileId: fileResponse[0].fileId,
        contentType: fileResponse[0].contentType,
        estimateId: `${estimate.info.id}`,
        json: 'json',
        added: true,
        id: randomString(36),
      }
      dispatch(setNewFiles(data));
      fileRef.current.value = '';
      setFile('');
      setDescription('');
      setFileResponse([]);
    }
  }

  return (
    <div className="box__fileload" style={{
      display: 'flex',
      flexDirection: 'column',
      background: 'none',
      border: 'none',
      width: '100%',
      margin: '0',
    }}>
      <input style={{alignSelf: 'start'}} type="file" ref={fileRef} onChange={setNewFile} />
      <div
        style={{background: 'none', border: 'none', display: 'flex', width: '100%', textAlign: 'start', margin: '0'}}>
        <span style={{width: '104px', paddingLeft: '24px', fontSize: '12px', fontFamily: 'regular'}}>Комментарий:</span>
        <textarea
          value={description} onChange={(e) => setDescription(e.target.value)}
          style={{width: '434px', marginLeft: '10px', resize: 'none'}}
          placeholder="(указание необязательно)"></textarea>
      </div>
      <button disabled={!filesAdded} style={{alignSelf: 'start', height: '20px', width: '108px'}} onClick={addDocument}
              className="bttn__small"
              type="button">Добавить
      </button>
    </div>
  )
};

const ListFilesModal = ({file, dispatch}) => {
  const deleteItem = () => {
    dispatch(deleteItemSuccess(file.document.id));
  }
  return (
    <div key={file.id} className="row__file" style={{
      padding: '0',
      margin: '0',
      display: 'flex',
      flexDirection: 'column',
      background: 'none',
      border: 'none',
      width: '520px',
    }}>
      <div style={{
        display: 'flex',
        padding: '0',
        margin: '0',
        background: 'none',
        border: 'none',
        width: '100%',
        justifyContent: 'space-between',
      }}>
        <div
          style={{padding: '0', margin: '0', width: '416px', background: 'none', border: 'none', textAlign: 'start'}}>
          <span className="symbol__bull--before"></span>
          <span style={{fontFamily: 'regular'}}>{file.document.originalFileName}</span>
        </div>
        <button onClick={deleteItem} style={{width: '80px'}} className="bttn__small" type="button">Удалить</button>
      </div>
      <TextArea desc={file.document.description} id={file.document.id} />
    </div>
  )
};

const TextArea = ({desc, id}) => {
  const [description, setDescription] = useState(desc);
  const dispatch = useDispatch();
  const deboounced = useDebounced(description, 600);

  useEffect(() => {
    const result = {
      description: description,
      id: id,
    }
    if (deboounced) {
      dispatch(setDescriptionItem(result));
    }
  }, [deboounced])

  return (
    <textarea
      onChange={(e) => setDescription(e.target.value)}
      style={{
        alignSelf: 'start',
        width: '416px',
        height: '38px',
        fontSize: '12px',
        resize: 'none',
        border: '1px solid rgb(196, 196, 196)',
      }}
      placeholder="(указание необязательно)"
    >
        {description}
      </textarea>
  )
};

export { ModalAddBreakdown };