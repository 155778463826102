import React, { useState } from 'react';
import styles from './ForemanObjectRow.module.scss';
import { handleDate } from "../../../utils/estimateUtils";
import { useHistory } from "react-router-dom";
import { SyntheticEvent } from "react";
import getHtmlParentId from "../../../utils/getHtmlParentId";

interface ForemanObjectRowProps {
  building: any
}

function ForemanObjectRow({building}: ForemanObjectRowProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const handleToggleIsOpen = (e: React.MouseEvent<HTMLSpanElement>) => {
    setIsOpen(prev => !prev);
  };

  const goToPage = (event: SyntheticEvent) => {
    const estimateRowElement = event.target as HTMLElement;
    if(estimateRowElement.tagName === "A") return;
    const id = getHtmlParentId(estimateRowElement);
    if(id) history.push(`/estimate-management/${id}`);
  };

  return (
    <div className={`
      ${styles.buildingBox} 
      ${isOpen ? styles.open : ''} 
      ${isOpen ? 'open' : ''} 
      ${building.estimates.some((estimate: any) => estimate.hasWarnings) ? styles.alarm : ''}
    `}>
      <div className={styles.buildingBoxInfoArea}>
        <span
          className="circle-triangle"
          title="Развернуть / Свернуть"
          onClick={handleToggleIsOpen}
        />

        <div className="limit__text--length">
          <a href="/_" target="_blank" title="Карточка объекта">
            {building.building.name}
          </a>
        </div>

        <div className="limit__text--length">
          {building.building.address}
        </div>

        <div>
          {building.periodInfo}
        </div>

        <div>
          <span className="symbol__colon--after" title="Общая стоимость всех Разбивок">Всего</span>
          <span>{building.signedEstimatesCost}</span>
        </div>

        <div>
          <span className="symbol__colon--after" title="«Всего» минус «В работе»">Остаток</span>
          <span>{building.signedEstimatesCost - building.prepaymentCost}</span>
        </div>

        <div>
          <span className="symbol__colon--after" title="Cумма авансов по всем договорам (Нарядам) СМР">В работе</span>
          <span>{building.prepaymentCost}</span>
        </div>

        <div>
          <span className="symbol__colon--after" title="Сумма по всем закрытым договорам (Нарядам) СМР">Выполнено</span>
          <span>{building.payedCost}</span>
        </div>

        <div>
          <span className="symbol__colon--after" title="Всего / В работе">Смет</span>
          <span>{building.estimatesInfo.replace(/смет:/g, '')}</span>
          <span className="symbol__slash--before"></span>
          <span>{building.estimatesInfo.replace(/смет:/g, '')}</span>
        </div>

      </div>
      {isOpen && (
        <div className={styles.estimatesArea}>
          {building.estimates.map((estimate: any) => (
            <div
              key={estimate.id}
              id={estimate.id}
              onClick={goToPage}
              className={`
                ${styles.estimateRow} 
                animate__clickable 
                ${estimate.estimateStatus < 35 ? "status--offer-" : "status__jobplan--done"} 
                ${estimate.hasWarnings ? styles.alarm : ''}
              `}
            >
              <div className="limit__text--length">
                { estimate.estimateStatus >= 50 ?
                <a href={`/estimate-management/${estimate.id}&job`} title="Карточка сметы">
                  {estimate.title}
                </a>
                :
                <span title="Карточка сметы">
                  {estimate.title}
                </span>
                }
              </div>

              <div className="limit__text--length">
                {estimate.estimateNote}
              </div>

              <div className="limit__text--length" title="Прораб (текущий)">
                {estimate.foremanName}
              </div>

              <div className="limit__text--length" title="Тип Сметы">
                {estimate.type}
              </div>

              <div className={styles.estimateRowStatus}>
                {estimate.estimateStatusText}
              </div>

              <div>
                {estimate.requiredJobPlanDate ? handleDate(estimate.requiredJobPlanDate) : ''}
                {/*<span title="Дата начала работ по Разбивке">03 дек 20</span>*/}
                {/*<span className="symbol__mdash--before"></span>*/}
                {/*<span title="Дата окончания работ по Разбивке">27 мар 21</span>*/}
              </div>

              <div>
                <span title="Общая стоимость Разбивки сметы">Всего</span>
                <span>{estimate.signedEstimatesCost}</span>
              </div>

              <div>
                <span title="«Всего» минус «В работе»">Остаток</span>
                <span>{building.signedEstimatesCost - building.prepaymentCost}</span>
              </div>

              <div>
                <span title="Cумма авансов по договорам (Нарядам) СМР">В работе</span>
                <span>{building.prepaymentCost}</span>
              </div>

              <div>
                <span title="Сумма по закрытым договорам (Нарядам) СМР">Выполнено</span>
                <span>{building.payedCost}</span>
              </div>

              <div>
                {/*<span className="symbol__colon--after" title="Дата автоматического утверждения">Срок</span>*/}
                {/*&nbsp;*/}
                <span>
                  {estimate.periodInfo}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ForemanObjectRow;