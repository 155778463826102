interface useFilter {
  document: any,
  documentType: { type: number; };
}

interface getPropsType {
  type: number | string
}

export const useFilter = (arr: any, type: any, params: string) => {
  if (params === 'type') {
    return arr.filter((el: useFilter) => el.document.documentType === type && el.document.id !== '00000000-0000-0000-0000-000000000000');
  }
  if (params === 'equipment') {
    return arr.filter((el: any) => el.systemName === type);
  }
}

export const useEquipmentFilter = (arr: any) => {
  const array: Iterable<any> | null | undefined = [];
  if (arr) {
    // @ts-ignore
    arr.map((el: any) => array.push(el.systemName))
    const newArray = new Set(array);
    return Array.from(newArray)
  }
}