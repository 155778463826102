import React, {useEffect} from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import {useParams} from 'react-router';
import Order from '../../components/Order/Order';
import {useDispatch, useSelector} from 'react-redux';
import {getOrder} from '../../redux/order/orderActions';
import {IOrderState} from '../../redux/order/types';

function OrderPage() {
  const { id } = useParams<any>();
  const dispatch = useDispatch<any>();
  const order = useSelector<any>(state => state.order) as IOrderState;

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    }
  }, [id]);

  return (
    <>
      <main>
        {order.loading && (
          <div><h1>LOADING...</h1></div>
        )}
        {!order.loading && (order.error || !order.order || !order.jobPlan) ? (
          <div><h1>ERROR...</h1></div>
        ) : (
          <>
            {order.order && order.jobPlan && (
              <Order/>
            )}
          </>
        )}
      </main>
      <NewFooter />
    </>
  );
}

export default OrderPage;