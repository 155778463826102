import React, {useEffect, useMemo, useState} from 'react';
import ObjectPageHead from "../ObjectPageHead/ObjectPageHead";
import {useDispatch, useSelector} from "react-redux";
import styles from './Profile.module.scss';
import AccountData from "./components/AccountData/AccountData";
import {getProfileData, getProfileStatuses} from "../../redux/profile/profileActions";
import {IProfileState} from "../../redux/profile/types";
import Statistics from "./components/Statistics/Statistics";
import Employees from "./components/Employees/Employees";
import BankAccounts from "./components/BankAccounts/BankAccounts"

const TABS = [
  {id: 0, title: 'Учетные данные'},
  {id: 1, title: 'Прорабы'},
  {id: 2, title: 'Банковские счета'},
  {id: 3, title: 'Статистика'},
];

function ProfileContractor() {
  const [activeTab, setActiveTab] = useState<number>(TABS[0].id);
  const profile = useSelector<any>(state => state.profile) as IProfileState;

  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;
  const dispatch = useDispatch<any>();

  const profileStatus = useMemo(() => {
    if (!profile.statuses.length || !profile.data?.contractor.status) {
      return null;
    }

    return profile.statuses.find(item => item.currentStateCode === profile.data?.contractor.status);
  }, [profile.statuses, profile.data?.contractor.status]);

  const isTabsDisabled = profileStatus?.currentStateCode !== 73;

  const handleChangeTab = (tab: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getProfileData());
    dispatch(getProfileStatuses());
  }, []);

  if (profile.isError) {
    return (
      <div><h1>ERROR...</h1></div>
    );
  }

  if (!profile.data && profile.isLoading) {
    return (
      <div><h1>LOADING...</h1></div>
    );
  }
  return (
    <>
      <ObjectPageHead title="Профиль пользователя" roleCode={userRoleCode}/>
      <div className={styles.tabs}>
        <span className={`${styles.statusIcon} ${profileStatus?.currentStateCode === 73 ? styles.verified : ''}`}>!</span>
        {TABS.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tab} ${activeTab === tab.id ? styles.isActive : ''}`}
            onClick={handleChangeTab(tab.id)}
            disabled={tab.id !== 0 && isTabsDisabled}
          >
            {tab.title}
            {tab.id === 0 && (
              <span className={`${styles.statusText} ${profileStatus?.currentStateCode === 73 ? styles.verified : ''}`}>{profileStatus?.currentStateText}</span>
            )}
          </button>
        ))}
      </div>
      <div className={styles.content}>
        {activeTab === TABS[0].id && (
          <AccountData/>
        )}
        {activeTab === TABS[1].id && (
          <Employees/>
        )}
        {activeTab === TABS[2].id && (
          <BankAccounts/>
        )}
        {activeTab === TABS[3].id && (
          <Statistics/>
        )}
      </div>
    </>
  );
}

export default ProfileContractor;