import React, {useCallback, useState} from 'react';
import styles from './PlanningListEstimate.module.scss';
import {IBillingPlanEstimate} from "../../../../../../types/Billing";
import {formatPrice} from "../../../../../../utils/formatPrice";
import {parseDateString, parseInputDate} from "../../../../../../utils/estimateUtils";
import {useSelector} from "react-redux";
import {IStatus} from "../../../../../../types/Status";

interface PlanningListEstimateProps {
  estimate: IBillingPlanEstimate
}

function PlanningListEstimate({estimate}: PlanningListEstimateProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const statuses = useSelector<any>(state => state.statuses.orderStatuses.data) as IStatus[];

  const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev);
  }, []);

  const getStatusText = (status: number) => {
    if (!statuses) {
      return '';
    }

    const statusData = statuses.filter(item => item.currentStateCode === status && item.currentStateText)?.[0];

    if (statusData) {
      return statusData.currentStateText;
    }
  };

  return (
    <div className={styles.estimateSection}>
      <div className={`${styles.estimateRow} ${isOpen ? 'open' : ''}`}>
        <div>
          <span
            className="circle-triangle"
            title="Развернуть / Свернуть"
            onClick={handleToggleOpen}
          />
        </div>
        <div>
          <a href="/_" target="_blank" title="Карточка сметы">{estimate.name}</a>
        </div>
        <div></div>
        <div></div>
        <div>{formatPrice(estimate.toPrepay)}</div>
        <div>{formatPrice(estimate.planTotal)}</div>
      </div>

      {isOpen && (
        <div className={styles.contractList}>
          {estimate.subItems.map(item => (
            <div className={styles.contractRow} key={item.entityId}>
              <div className="symbol__bull--before"></div>
              <div>
                <a href="/_" target="_blank" title="Карточка наряда">{item.name}</a>
                <span>{item.comment}</span>
              </div>
              <div title={parseInputDate(item.date)}>{parseDateString(item.date)}</div>
              <div>{getStatusText(item.orderStatus)}</div>
              <div>{formatPrice(estimate.toPrepay)}</div>
              <div>{formatPrice(estimate.planTotal)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PlanningListEstimate;