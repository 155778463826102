import React from 'react';
import styles from './Modal.module.scss';

interface ModalProps {
  title: string
  children: React.ReactNode
}

function Modal({title, children}: ModalProps) {
  return (
    <div className={`${styles.modal} ${styles.isOpen}`}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalWindow}>
          <h3 className={styles.modalTitle}>{title}</h3>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;