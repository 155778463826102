import React, {useMemo, useState} from 'react';
import styles from './OrderBox.module.scss';
import {useSelector} from 'react-redux';
import {IJobPlanChainOrder} from '../../../../types/JobPlan';
import {formatPrice} from '../../../../utils/formatPrice';
import {IDocument, IDocumentType} from '../../../../types/Documents';
import {parseDateString} from '../../../../utils/estimateUtils';
import {IStatus} from '../../../../types/Status';
// import {IUserRole} from '../../../../types/User';
import {getRoleTitle} from "../../../../utils/getRoleName";

interface IGalleryImg {
  url: string,
  alt: string
}

interface IDocumentsSection {
  type: IDocumentType
  items: IDocument[]
}

function OrderBox() {
  const order = useSelector<any>(state => state.order.order) as IJobPlanChainOrder;
  const documentTypes = useSelector<any>(state => state.order.documentTypes) as IDocumentType[];
  const complaintStatuses = useSelector<any>(state => state.order.complaintStatuses) as IStatus[];
  // const userRoles = useSelector<any>(state => state.roles.roles) as IUserRole[];
  const [activeImg, setActiveImg] = useState<number>(0);
  const [openedSections, setOpenedSections] = useState<string[]>([]);

  const documentsSections = useMemo(() => {
    const sections: IDocumentsSection[] = [];

    if (!order.documents?.length || !documentTypes?.length) {
      return sections;
    }

    documentTypes.map(documentTypeItem => {
      return {
        type: documentTypeItem,
        items: order.documents.filter(item => item.documentType === parseInt(documentTypeItem.code))
      }
    }).filter(item => item.items.length !== 0).map(item => {
      if (item.type.code === '10') {
        sections.unshift(item)
      } else {
        sections.push(item);
      }
    });

    return sections;
  }, [documentTypes, order.documents]);

  const complaintsDowntime = useMemo(() => {
    const value = order.complaints?.map(item => item.downtime)?.reduce((prev, next) => prev + next, 0);

    return value ? <span>{value}</span> : <span className="symbol__mdash--before"></span>;
  }, [order.complaints]);

  const galleryImages = useMemo(() => {
    const images: IGalleryImg[] = [];

    order?.documents?.map(item => {
      if (item.documentType === 10 && item.url) {
        images.push({
          url: item.url,
          alt: item.originalFileName
        });
      }
    });

    return images;
  }, [order.documents]);

  const handleClickPrevImg = (e: React.MouseEvent<HTMLElement>) => {
    setActiveImg(prev => prev - 1 < 0 ? 0 : prev - 1);
  };

  const handleClickNextImg = (e: React.MouseEvent<HTMLElement>) => {
    setActiveImg(prev => prev + 1 > galleryImages.length - 1 ? prev : prev + 1);
  };

  const handleClickImgThumb = (index: number) => (e: React.MouseEvent<HTMLElement>) => {
    setActiveImg(index);
  };

  const handleToggleSection = (code: string) => (e: React.MouseEvent<HTMLElement>) => {
    if (openedSections.includes(code)) {
      setOpenedSections(prev => ([...prev].filter(item => item !== code)));
    } else {
      setOpenedSections(prev => ([...prev, code]));
    }
  };

  const getComplaintStatusText = (status: number) => {
    if (!complaintStatuses) {
      return '';
    }

    const statusData = complaintStatuses.filter(item => item.currentStateCode === status)?.[0];

    if (statusData) {
      return statusData.currentStateText;
    }
  };

  /*const getUserRole = (roleId: number) => {
    if (!userRoles || !userRoles.length) {
      return '';
    }

    const role = userRoles.filter(item => item.id === roleId)?.[0];

    if (!role) {
      return ''
    } else {
      return role.name;
    }
  };*/

  return (
    <div className={styles.orderBox}>
      <div>
        {!!galleryImages.length && (
          <>
            <div className={styles.previewSelect}>
              <span className={styles.previewSelectPrev} onClick={handleClickPrevImg}/>
              <div className={styles.previewSelectImages}>
                {galleryImages.map((img, index) => (
                  <img
                    src={img.url}
                    alt={img.alt}
                    key={index}
                    onClick={handleClickImgThumb(index)}
                    className={activeImg === index ? styles.selected : ''}
                  />
                ))}
              </div>
              <span className={styles.previewSelectNext} onClick={handleClickNextImg}/>
            </div>
            <div className={styles.preview}>
              <img
                src={galleryImages[activeImg].url}
                alt={galleryImages[activeImg].alt}
              />
            </div>
          </>
        )}
      </div>

      <div>
        {documentsSections.map((section, index) => (
          <div className={styles.section} key={index}>
            <div className={`${styles.sectionHeader} ${openedSections.includes(section.type.code) ? 'open' : ''}`}>
              <span
                className="circle-triangle"
                title="Развернуть / Свернуть"
                onClick={handleToggleSection(section.type.code)}
              />
              <span>{section.type.name}</span>
              <span className="symbol__colon--after">Файлов</span>
              <span>{section.items.length}</span>
            </div>
            {openedSections.includes(section.type.code) && (
              <div className={`${styles.sectionFiles} ${styles.sectionContent}`}>
                {section.items.map(file => (
                  <div key={file.id}>
                    <span className="symbol__bull--before"></span>
                    <span><a href={file.url}>{file.originalFileName || 'Нет названия'}</a></span>
                    <span>{file.description}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}


        <div className={styles.section}>
          <div className={`${styles.sectionHeader} ${openedSections.includes('complaints') ? 'open' : ''}`}>
            <span
              className="circle-triangle"
              title="Развернуть / Свернуть"
              onClick={handleToggleSection('complaints')}
            />
            <span>Споры</span>
            <span className="symbol__colon--after">Всего</span>
            <span>{order.complaints?.length || 0}</span>
          </div>
          {openedSections.includes('complaints') && (
            <div className={`${styles.sectionComplaints} ${styles.sectionContent}`}>

              {!!order.complaints.length ? (
                <>
                  <div className={styles.captions}>
                    <span>Даты споров</span>
                    <span>Статус</span>
                    <span>Ответчик</span>
                    <span>Простой</span>
                  </div>

                  {order.complaints.map(complaintItem => (
                    <div className={styles.complaintRow}>

                      <a href={`/complaint-card?id=${complaintItem.id}`} title="Карточка спора">
                        <span>{parseDateString(complaintItem.startDate)}</span>
                        <span className="symbol__mdash--before"></span>

                        {/*'<!--*/}
                        {/*  Дата окончания:*/}
                        {/*    выводится для закрытых Споров, статусы:*/}
                        {/*    «Закрыт» / «Отклонён» / «Удовлетворён»;*/}
                        {/*    не выводится для открытых Споров: троеточие.*/}
                        {/*-->'*/}
                        {/*<!-- <span className="symbol__hellip--before"></span> -->*/}
                        <span>{parseDateString(complaintItem.endDate)}</span>
                      </a>

                      <span>{getComplaintStatusText(complaintItem.status)}</span>
                      <span>{getRoleTitle(complaintItem.respondentRole)}</span>

                      {complaintItem.downtime ? (
                        <span>{complaintItem.downtime}</span>
                      ) : (
                        <span className="symbol__mdash--before"></span>
                      )}

                      <span>дн.</span>
                    </div>
                  ))}
                </>
              ) : (
                <div className={styles.complaintRow}>
                  <span>Нет споров</span>
                </div>
              )}
              <div className={styles.totalRow}>

                <a
                  href={`/complaint-card?createId=${order.id}`}
                  className="button bttn__small"
                  type="button"
                  title="Откроется страница создания Спора"
                >
                  Начать спор
                </a>
                <span className="symbol__colon--after">Всего в простое</span>

                {complaintsDowntime}

                <span>дн.</span>
              </div>

            </div>
          )}
        </div>

        <div className={styles.prices}>
          <div className={styles.pricesHeader}>
            Работы (состав/расценки)
          </div>
          <div>
            {order.jobPrice.map((item, index) => (
              <div key={index}>
                <div className={`${styles.pricesRow} ${openedSections.includes('jobPrice'+index) ? 'open' : ''}`}>
                  <div>
                    <span
                      className="circle-triangle"
                      title="Развернуть / Свернуть"
                      onClick={handleToggleSection(`jobPrice${index}`)}
                    />
                  </div>
                  <div>{item.workName}</div>
                  <div>{item.count}</div>
                  <div>{item.unit}</div>
                  <div>{item.price}</div>
                  <div>{formatPrice(item.price * item.count)}</div>

                  <div>
                    {item.hours ? (
                      <span>{Math.ceil(item.hours / 8)}</span>
                    ) : (
                      <span className="symbol__mdash--before"/>
                    )}
                    <span title="Продолжительность: дней">дн.</span>
                  </div>
                </div>
                {openedSections.includes('jobPrice'+index) && (
                  <div className={styles.pricesStructure}>

                    <div className={styles.position}>
                      <div className="symbol__bull--before"></div>
                      <div>
                        {item.equipmentName}
                      </div>
                      {/*<div>00,00</div>*/}
                      {/*<div>ед. изм.</div>*/}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderBox;