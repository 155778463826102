import React, {useCallback, useState} from 'react';
import InputMask from "react-input-mask";
import Datetime from "react-datetime";
import "moment/locale/ru";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const defaultDateMask = {
    mask: "79.69.8999",
    formatChars: {
        9: "[0-9]",
        8: "[1-9]",
        7: "[0-3]",
        6: "[0-1]"
    }
};

export const DateInput = ({ onChange, field, value, valid }) => {
    const handleDate = ((e) => {
            if(typeof e === 'object'){
                onChange({target: {name: field, value:e.format("DD.MM.YYYY")}})
            } else onChange({target: {name: field, value:e}})

        });
    // console.log(value);
    return (
        <Datetime
            locale="ru"
            className={"inputDate"}
            dateFormat={"DD.MM.YYYY"}
            timeFormat={false}
            value={value}
            onChange={handleDate}
            renderInput={(props) => (
                <span>
                    <InputMask
                        style={{width:"120px", padding:"3px", border:!valid[field] && value ? '1px solid red' : '', marginLeft:"5px"}}
                        name={field}
                        mask={defaultDateMask.mask}
                        onChange={onChange}
                        value={value}
                        maskChar={"_"}
                        formatChars={defaultDateMask.formatChars}
                        {...props}
                    />
                </span>
            )}
        />
    );
};
