import React, {useEffect, useState} from 'react';
import styles from './ChangeData.module.scss';
import Modal from "../../../../../Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {IProfileState} from "../../../../../../redux/profile/types";
import {IProfileData} from "../../../../../../types/Profile";
import {saveProfile} from "../../../../../../redux/profile/profileActions";
import {baseNotification} from "../../../../../../utils/estimateUtils";
import {isValidEmail, isValidPhone, isValidInn, isValidKpp, isValidOkpo, isValidOgrn, isValidOktmo} from "../../../../../../utils/validators";

interface ChangeDataProps {
  onClose: Function
}

function ChangeData({onClose}: ChangeDataProps) {
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const [newData, setNewData] = useState<IProfileData>(Object.assign({}, profile.data));
  const [addressIsEqual, setAddressIsEqual] = useState<boolean>(!!profile.data?.contractor.legalAddress && !!profile.data?.contractor.actualAddress && profile.data?.contractor.legalAddress === profile.data?.contractor.actualAddress);
  const [saving, setSaving] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const getIsDataValid = () => {
    const isValid = !!newData.responsible.lastName
      && !!newData.responsible.firstName
      && !!newData.responsible.secondName
      && isValidEmail(newData.contractor.email)
      && !!newData.contractor.name
      && isValidInn(newData.contractor.inn)
      && isValidKpp(newData.contractor.kpp)
      && isValidOkpo(newData.contractor.okpo)
      && isValidOgrn(newData.contractor.ogrn)
      && isValidOktmo(newData.contractor.oktmo)
      && !!newData.contractor.legalAddress
      && !!newData.contractor.actualAddress
      && isValidPhone(newData.contractor.phone)
      && !!newData.contractor.signatoryLastName
      && !!newData.contractor.signatoryFirstName
      && !!newData.contractor.signatorySecondName
      && !!newData.contractor.signatoryPost
      && !!newData.contractor.signatoryReason;

    return isValid;
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose();
  };

  const handleChangeField = (parentObjectName: 'responsible' | 'contractor') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      parentObjectName === 'contractor'
      && e.target.name === 'legalAddress'
      && addressIsEqual
    ) {
      setNewData(prev => ({
        ...prev,
        contractor: {
          ...prev.contractor,
          legalAddress: e.target.value,
          actualAddress: e.target.value
        }
      }));
      return;
    }

    setNewData(prev => ({
      ...prev,
      [parentObjectName]: {
        ...(parentObjectName === 'responsible' ? {...prev.responsible} : {}),
        ...(parentObjectName === 'contractor' ? {...prev.contractor} : {}),
        [e.target.name]: e.target.value
      }
    }));
  };

  const handleChangeAddressEqual = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressIsEqual(e.target.checked);

    if (e.target.checked) {
      setNewData(prev => ({
        ...prev,
        contractor: {
          ...prev.contractor,
          actualAddress: prev.contractor.legalAddress
        }
      }));
    }
  };

  const handleSaveDataToStorage = () => {
    localStorage.setItem('profileChangeData', JSON.stringify(newData));
    onClose();
  };

  const handleSaveChanges = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (saving) {
      return;
    }

    setSaving(true);

    const changeProfileState = {
      "newState": 72,
      "actionText": "На проверку",
    };

    dispatch(saveProfile(newData, changeProfileState)).then(()=>
     {
      setSaving(false);
      localStorage.removeItem('profileChangeData');
      baseNotification("Успешно", `Данные успешно сохранены`,"success");
      onClose();
    }).catch(() => {
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
      setSaving(false);
    });
  };

  useEffect(() => {
    const storageNewData = localStorage.getItem('profileChangeData');

    if (storageNewData) {
      const parsedData = JSON.parse(storageNewData) as IProfileData;

      if (parsedData.contractor.id === profile.data?.contractor.id) {
        setNewData(parsedData);
        setAddressIsEqual(parsedData.contractor.legalAddress === parsedData.contractor.actualAddress);
      } else {
        localStorage.removeItem('profileChangeData');
      }
    }
  }, []);

  return (
    <Modal title="Регистрация">
      <div className={styles.changeData}>
        <div className={styles.changeDataNotification}>
          Все поля должны быть заполнены достоверными данными.
        </div>

        <form className={styles.changeDataContent}>

          <p className={styles.sectionTitle}>Ответственный от организации</p>

          <label>Фамилия</label>
          <input
            name="lastName"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.responsible.lastName ? profile.data?.responsible.lastName : newData.responsible.lastName}
            onChange={handleChangeField('responsible')}
            disabled={!!profile.data?.responsible.lastName}
          />

          <label>Имя</label>
          <input
            name="firstName"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.responsible.firstName ? profile.data?.responsible.firstName : newData.responsible.firstName}
            onChange={handleChangeField('responsible')}
            disabled={!!profile.data?.responsible.firstName}
          />

          <label>Отчество</label>
          <input
            name="secondName"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.responsible.secondName ? profile.data?.responsible.secondName : newData.responsible.secondName}
            onChange={handleChangeField('responsible')}
            disabled={!!profile.data?.responsible.secondName}
          />

          <label>E-mail</label>
          <input
            name="email"
            type="email"
            placeholder="(введите значение)"
            value={profile.data?.contractor.email ? profile.data?.contractor.email : newData.contractor.email}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.email}
          />

          <p className={styles.sectionTitle}>Реквизиты организации</p>

          <label>Наименование</label>
          <input
            name="name"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.name ? profile.data?.contractor.name : newData.contractor.name}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.name}
          />

          <label>ИНН</label>
          <input
            className={!profile.data?.contractor.inn && !isValidInn(newData.contractor.inn) ? styles.error : ''}
            name="inn"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.inn ? profile.data?.contractor.inn : newData.contractor.inn}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.inn}
          />

          <label>КПП</label>
          <input
            className={!profile.data?.contractor.kpp && !isValidKpp(newData.contractor.kpp) ? styles.error : ''}
            name="kpp"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.kpp ? profile.data?.contractor.kpp : newData.contractor.kpp}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.kpp}
          />

          <label>ОКПО</label>
          <input
            className={!profile.data?.contractor.okpo && !isValidOkpo(newData.contractor.okpo) ? styles.error : ''}
            name="okpo"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.okpo ? profile.data?.contractor.okpo : newData.contractor.okpo}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.okpo}
          />

          <label>ОГРН</label>
          <input
            className={!profile.data?.contractor.ogrn && !isValidOgrn(newData.contractor.ogrn) ? styles.error : ''}
            name="ogrn"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.ogrn ? profile.data?.contractor.ogrn : newData.contractor.ogrn}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.ogrn}
          />

          <label>ОКТМО</label>
          <input
            className={!profile.data?.contractor.oktmo && !isValidOktmo(newData.contractor.oktmo) ? styles.error : ''}
            name="oktmo"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.oktmo ? profile.data?.contractor.oktmo : newData.contractor.oktmo}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.oktmo}
          />

          <label>Адрес (юридический)</label>
          <input
            name="legalAddress"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.legalAddress ? profile.data?.contractor.legalAddress : newData.contractor.legalAddress}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.legalAddress}
          />

          <label>Адрес (фактический)</label>
          <input
            name="actualAddress"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.actualAddress ? profile.data?.contractor.actualAddress : newData.contractor.actualAddress}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.actualAddress || addressIsEqual}
          />

          <label>Совпадает с юридическим</label>
          <input
            name=""
            type="checkbox"
            checked={addressIsEqual}
            onChange={handleChangeAddressEqual}
            disabled={
              !!profile.data?.contractor.legalAddress && !!profile.data?.contractor.actualAddress && profile.data?.contractor.legalAddress === profile.data?.contractor.actualAddress
            }
          />

          <label>Телефон офиса</label>
          <input
            className={!profile.data?.contractor.phone && !isValidPhone(newData.contractor.phone) ? styles.error : ''}
            type="tel"
            placeholder="+7 (___) ___-__-__"
            name="phone"
            value={profile.data?.contractor.phone ? profile.data?.contractor.phone : newData.contractor.phone}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.phone}
          />

          <p className={styles.sectionTitle}>Подписант</p>

          <label>Фамилия</label>
          <input
            name="signatoryLastName"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.signatoryLastName ? profile.data?.contractor.signatoryLastName : newData.contractor.signatoryLastName}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.signatoryLastName}
          />

          <label>Имя</label>
          <input
            name="signatoryFirstName"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.signatoryFirstName ? profile.data?.contractor.signatoryFirstName : newData.contractor.signatoryFirstName}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.signatoryFirstName}
          />

          <label>Отчество</label>
          <input
            name="signatorySecondName"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.signatorySecondName ? profile.data?.contractor.signatorySecondName : newData.contractor.signatorySecondName}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.signatorySecondName}
          />

          <label>Должность</label>
          <input
            name="signatoryPost"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.signatoryPost ? profile.data?.contractor.signatoryPost : newData.contractor.signatoryPost}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.signatoryPost}
          />

          <label>Основание</label>
          <input
            name="signatoryReason"
            type="text"
            placeholder="(введите значение)"
            value={profile.data?.contractor.signatoryReason ? profile.data?.contractor.signatoryReason : newData.contractor.signatoryReason}
            onChange={handleChangeField('contractor')}
            disabled={!!profile.data?.contractor.signatoryReason}
          />

          <hr/>

          <button
            name="close__apply"
            type="button"
            title="Отправить данные для регистрации"
            disabled={saving || !getIsDataValid()}
            onClick={handleSaveChanges}
          >
            Зарегистрировать
          </button>

          <button
            name="close__save"
            type="button"
            title="Сохранить без отправки для регистрации"
            onClick={handleSaveDataToStorage}
          >
            Сохранить
          </button>

          <button
            name="close__cancel"
            type="button"
            onClick={handleClose}
          >
            Отмена
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default ChangeData;