import React, {useEffect, useLayoutEffect} from "react";

import {ReactNotifications} from "react-notifications-component";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Register from "./pages/register-page/register";
import Home from "./pages/home-page/home-page";
import Login from "./pages/login-page/login";
import NoMatch from "./pages/404/404";
import VerifyEmail from "./pages/email-verification-page/verification";
import PersonalInfo from "./pages/profile-page/personal-info";
import ContractorProfileReact from "./pages/profile-page2/contractor/contractor-profile";
import BuildingsListPage from "./pages/BuildingsListPage/BuildingsListPage";
import "react-notifications-component/dist/theme.css";
import {EstimateJob} from "./pages/estimateJob-page/EstimateJob";
import {ComplaintCard} from "./pages/complaintcard-page/ComplaintCard";
import {ComplaintList} from "./pages/complaintlist-page/ComplaintList";
import Header from "./components/header/header";
import {BreakdownManagement} from "./pages/BreakdownManagement/BreakdownManagement";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "./redux/user/actionUser";
import JobPlanPage from "./pages/JobPlanPage/JobPlanPage";
import Invite from "./pages/invite";
import {getEstimateTypes} from "./redux/actions/actionContractor";
import OrderPage from './pages/OrderPage/OrderPage';
import {getContractorInfo} from "./redux/actions/actionContractor";
import OrdersListPage from './pages/OrdersListPage/OrdersListPage';
import BillingPage from "./pages/BillingPage/BillingPage";
import {getOrderStatuses} from "./redux/statuses/statusesActions";
import ProfilePage from "./pages/ProfilePage/ProfilePage";

function App() {
  const dispatch = useDispatch<any>();
  const user = useSelector<any>((state) => state.userReducer.user);
  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes);

  useEffect(() => {
    if (!estimateTypes) {
      dispatch(getEstimateTypes());
    }
  },[estimateTypes, dispatch]);

  useLayoutEffect(() => {
    (async () => {
      if (!user) {
        dispatch(getUser());
      }
      await dispatch(getContractorInfo());
    })()
  }, []);

  useEffect(() => {
    dispatch(getOrderStatuses());
  }, []);

  return (
      <>
      <ReactNotifications />
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/invite" component={Invite} />
          <Route path="/register" component={Register} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route
            exact
            path="/profile-react"
            component={ContractorProfileReact}
          />
          <Route exact path="/profile2" component={PersonalInfo} />
          <Route exact path="/estimate-management/:params" component={EstimateJob} />
          <Route exact path="/breakdown-management/:id" component={BreakdownManagement} />
          <Route path="/complaint-card" component={ComplaintCard} />
          <Route exact path="/complaint-list" component={ComplaintList} />
          <Route
            path="/list-contractor"
            render={() => <BuildingsListPage tab={0} />}
          />

          <Route path="/verify" component={VerifyEmail} />
          {/* <Route exact path="/profile/personal" component={PersonalInfo}/> */}
          {/* <Route exact path="/profile/contractor" component={ContractorProfile}/> */}
          <Route exact path="/job-plan/:id" component={JobPlanPage} />
          <Route exact path="/order/:id" component={OrderPage} />
          <Route exact path="/orders" component={OrdersListPage} />
          <Route exact path="/billing" component={BillingPage} />
          <Route component={NoMatch} />

          {/*<Route path="/restore" component={Restore}/>*/}
          {/*<Route exact={true} path="/calc" component={Calc}/>*/}
        </Switch>
        {/* <Footer/> */}
      </BrowserRouter>
      {/*</div>*/}
    </>
  );
}

export default App;
