import React, {useState} from 'react';
import style from './workSchedule.module.scss';
import {ListFiles} from "../../../components/EstimateManagement/ListFiles";
import {useFilter} from "../../../hooks/useFilter";

const WorkSchedule = (props: any) => {
  const {state, modeStatus} = props
  const type = 10;

  const [open, setOpen] = useState(state);

  const openInfo = () => {
    setOpen(!open);
  }

  const arr = useFilter(props.estimate.documents, type, 'type')
  return (
  <React.Fragment>
    <div className={`section__files--spec ${style.size} ${open ? "open" : ""}`}>
      <div className={`${open ? style.activeContainer : style.container}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className={`circle-triangle`} onClick={openInfo} title="Развернуть / Свернуть"></span>
          <span style={{marginLeft: '8px'}}>График производства работ</span>
        </div>
        <div>
          <span className={`symbol__colon--after`}>Файлов</span>
          <span style={{marginLeft: '12px', fontWeight: 'bold'}}>{arr.length}</span>
        </div>
      </div>
      {open && <ListFiles arr={arr} edit={modeStatus} estimate={props.estimate} type={type}/>}
    </div>
  </React.Fragment>
  )
}

export {WorkSchedule}