import React from "react";
import 'moment/locale/ru';
import { handleDate } from "../../utils/estimateUtils";
import { useDispatch, useSelector } from "react-redux";
import { sendFlowEstimate } from "../../redux/actions/actionContractor";
import { useHistory } from "react-router-dom";
import styles from './estimates.module.scss';
import { SyntheticEvent } from "react";
import getHtmlParentId from "../../utils/getHtmlParentId";

const acceptedSumKP = [32, 33, 34, 35, 36, 37, 38, 39];

interface IEstimateWork {
  actionCode: number,
  actionText: string,
  currentStateCode: number,
  currentStateText: string,
  hideOn: string[],
  visible: boolean,
}

const Estimates = ({ estimates }: any): JSX.Element => {
  const dispatch  = useDispatch<any>();
  const history = useHistory();
  const estimateWork: IEstimateWork[] = useSelector((state:any) => state.contractorReducer.estimateWorkFlow);
  const handleEstimateBtn = (status: any) => {
    const arrFlow: { newState: number, text: string, visible: boolean }[] = [];
    estimateWork?.forEach((work: IEstimateWork) => {
      if ((work.currentStateCode === status) && (!work.hideOn.includes('list-contractor'))) {
        arrFlow.push({newState:work.actionCode, text:work.actionText, visible: work.visible});
      }
    })
    return arrFlow;
  }

  const handleClick = (id:string, newState: number) => {
    dispatch(sendFlowEstimate({entityId:id, newState}));
  }

  const goToPage = (event: SyntheticEvent) => {
    const estimateRowElement = event.target as HTMLElement;
    if(estimateRowElement.tagName === "A") return;
    const id = getHtmlParentId(estimateRowElement);
    if(id) history.push(`/estimate-management/${id}`);
  };

  if (!estimates.length) return <span>Сметы отсутствуют</span>;
  
  return (
    <div className="estimate__area">
      {estimates.map((estimate: any) => {
        const offerStyle = estimate.estimateStatus < 35 ? styles.container__offer : styles.container__jobplan;
        return (
          <div
            onClick={goToPage}
            key={estimate.id}
            id={estimate.id}
            className={`${styles.container} ${offerStyle}`}
          >
          <div className={`${styles.name} ${styles.borderLine}`}>
            { estimate.estimateStatus >= 50 ?
                <a href={`/estimate-management/${estimate.id}&job`} 
                   className={`limit__text--length ${styles.container__url}`}
                   title="Карточка сметы">
                    {estimate.title}
                </a>
                :
                <div className="limit__text--length">{estimate.title}</div>
            }
            <div className="limit__text--length">{estimate.note}</div>
          </div>

          <div className={`${styles.types} ${styles.borderLine}`}>
            <div className="limit__text--length" title="Тип Сметы">
              {estimate.types}
            </div>
            <div className="text__status--estimate">{estimate.estimateStatusText}
            </div>
          </div>

          <div className={`${styles.sumContainer} ${styles.borderLine}`} >
            {acceptedSumKP.find((el) => el === estimate?.estimateStatus) ?
              <div className={styles.sum}>
              <span>
              Сумма Кп
              </span>
                <span>{estimate?.signedEstimatesCost}</span>
              </div> : null
            }
            {(estimate?.estimateStatus === 26) ? 
              (<div>
                <span style={{width: "120px", display: 'flex', justifyContent: 'center'}}>Формирование</span>
                <span style={{width: "120px", display: 'flex', justifyContent: 'center'}}>{handleDate(estimate?.milestoneDate)}</span>
              </div>) :
              (<div className={`${styles.buttons} ${styles.borderLine}`}>
                { estimate?.jobPlanDate ?
                  (<span style={{width: "120px", display: 'flex', justifyContent: 'center'}}>- {handleDate(estimate?.jobPlanDate)}</span>) : 
                  null
                }
                <span style={{display:"flex", flexDirection:"column", gap:"5px"}}>
                  {handleEstimateBtn(estimate.estimateStatus).map((item) =>
                    item.visible && <button className="btn-small" style={{height:"auto"}} onClick={ () => handleClick(estimate.id, item.newState)}>{item.text}</button>)
                  }
                </span>
              </div>)
            }
          </div>

        </div>
      )})}

      {/*<div className="estimate__line --offer- animate__clickable status--offer-no-data">*/}

      {/*    <div className="limit__text--length">Наименование Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length">Примечание Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length" title="Тип Сметы">Кондиционирование (текст в элементе может*/}
      {/*        быть длинным)*/}
      {/*    </div>*/}

      {/*    <div className="text__status--estimate">Дозапрос данных</div>*/}

      {/*    <div className="limit__text--length">*/}
      {/*        Сообщение от Сметчика (текст в элементе может быть длинным)*/}
      {/*    </div>*/}

      {/*</div>*/}

      {/*<div className="estimate__line --offer- animate__clickable status--offer-requery">*/}

      {/*    <div className="limit__text--length">Наименование Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length">Примечание Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length" title="Тип Сметы">Кондиционирование (текст в элементе может*/}
      {/*        быть длинным)*/}
      {/*    </div>*/}

      {/*    <div className="text__status--estimate">Запрос к КП</div>*/}

      {/*    <div>*/}
      {/*        <span>Запрос отправлен</span>*/}
      {/*        <span>02 дек 21</span>*/}
      {/*    </div>*/}

      {/*</div>*/}

      {/*<div className="estimate__line --offer- animate__clickable status--offer-to-ratify alarm">*/}

      {/*    <div className="limit__text--length">Наименование Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length">Примечание Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length" title="Тип Сметы">Кондиционирование (текст в элементе может*/}
      {/*        быть длинным)*/}
      {/*    </div>*/}

      {/*    <div className="text__status--estimate">Кп на согласовании</div>*/}

      {/*    <div>*/}
      {/*        <span>Сумма КП</span>*/}
      {/*        <span>000 000 000</span>*/}
      {/*        <button className="bttn__small">Утвердить</button>*/}
      {/*        <button className="bttn__small">Отклонить</button>*/}
      {/*    </div>*/}

      {/*</div>*/}

      {/*<div className="estimate__line --offer- animate__clickable status--offer-accept">*/}

      {/*    <div className="limit__text--length">Наименование Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length">Примечание Сметы (текст в элементе может быть длинным)</div>*/}

      {/*    <div className="limit__text--length" title="Тип Сметы">Кондиционирование (текст в элементе может*/}
      {/*        быть длинным)*/}
      {/*    </div>*/}

      {/*    <div className="text__status--estimate">Кп принято</div>*/}

      {/*    <div>*/}
      {/*        <span>Сумма КП</span>*/}
      {/*        <span>000 000 000</span>*/}
      {/*    </div>*/}

      {/*</div>*/}
    </div>
  );
};

export default Estimates;
