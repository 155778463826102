import axios from 'axios';
import moment from 'moment';
import Config from '../../config';
import {
  GET_ORDERS_LIST_ERROR,
  GET_ORDERS_LIST_START,
  GET_ORDERS_LIST_SUCCESS,
  RESET_ORDERS_LIST,
  SET_ORDER_STATUS
} from './ordersListActionTypes';

const getOrdersListStart = () => ({
  type: GET_ORDERS_LIST_START
});

const getOrdersListSuccess = (payload) => ({
  type: GET_ORDERS_LIST_SUCCESS,
  payload,
});

const getOrdersListError = () => ({
  type: GET_ORDERS_LIST_ERROR
});

const getFiltersData = filters => {
  const filtersData = {
    hasOpenComplaints: filters.hasOpenComplaints
  };

  ['buildingFilter', 'estimateFilter', 'orderFilter', 'brigadeFilter'].map(key => {
    if (filters[key]) {
      filtersData[key] = filters[key];
    }
  });

  if (filters.dateFrom && filters.dateTo) {
    filtersData.dateFrom = moment(filters.dateFrom).toISOString();
    filtersData.dateTo = moment(filters.dateTo).toISOString();
  }

  if (filters.orderStatus !== -1) {
    filtersData.orderStatus = filters.orderStatus;
  }

  if (filters.withDowntimeType !== -1) {
    filtersData.withDowntimeType = filters.withDowntimeType;
  }

  return filtersData;
};

const getCurrentStatuses = (orders) => {
  const currentCodes = {};

  orders.map(item => {
    currentCodes[item.status.toString()] = item.status;
  });

  return Object.keys(currentCodes).map(key => currentCodes[key]);
};

export const getOrdersList = (page, filters) => async (dispatch, getState) => {
  dispatch(getOrdersListStart());

  try {
    const token = JSON.parse(localStorage.getItem("user"));

    const ordersList = await axios.post(Config.BACKEND_ADDRESS + `Foreman/GetOrders`, {
      meta: {
        page: page || 0,
        count: 10
      },
      ...getFiltersData(filters)
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const orderStatuses = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetOrderWorkFlow`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const currentStatuses = getState().ordersList.currentStatuses;

    dispatch(getOrdersListSuccess({
      orders: ordersList.data.foremanOrders || [],
      meta: ordersList.data.meta,
      orderStatuses: orderStatuses.data,
      ...(!currentStatuses && {currentStatuses: getCurrentStatuses(ordersList.data.foremanOrders)})
    }));
  } catch (e) {
    dispatch(getOrdersListError());
  }
};

export const setOrderStatus = (orderId, status) => ({
  type: SET_ORDER_STATUS,
  payload: {
    orderId,
    status
  }
});

export const updateOrderStatus = (data) => async (dispatch, getState) => {
  const token = JSON.parse(localStorage.getItem("user"));

  return await axios.post(Config.BACKEND_ADDRESS + 'Foreman/FlowOrder', data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    }
  });
};

export const resetOrdersList = () => ({
  type: RESET_ORDERS_LIST
});