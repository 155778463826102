import React from "react";
import style from './breakdownManagement.module.scss';
import {BreakdownStatusBar} from "./BreakdownStatusBar/BreakdownStatusBar";
import {ChainItems} from "./ChainItems/ChainItems";

const BreakdownManagement = () => {
  const arr = [1, 2]

  return (
      <React.Fragment>
        <main className={style.main}>
          <div className={style.header}>
            <span className={style.blockName}>Карточка разбивки</span>
            <span className={style.blockNameSecond}>Сметчик</span>
          </div>
          <div className={style.barRequest}>
            <div style={{width: '350px'}} className="limit__text--length">
              <a className={`link-light ${style.linkObject}`} href="/_" target="_blank" title="Карточка объекта">Название
                родительского
                Объекта</a>
              <div className={style.addressObject}>Адрес Объекта (текст может быть длинным)</div>
            </div>
            <div style={{width: '350px'}} className="limit__text--length">
              <a className={`link-light ${style.linkObject}`} href="/_" target="_blank" title="Страница Сметы">Название
                Сметы</a>
              <div className={`limit__text--length ${style.addressObject}`}>Примечание Сметы (текст может быть
                длинным)
              </div>
            </div>
            <div style={{width: '350px'}} className="limit__text--length">
              <a className={`link-light ${style.linkObject}`} href="/_" target="_blank" title="Карточка подрядчика">ООО
                «Подрядчиков и
                компания»</a>
              <div>
                <span style={{width: "120px", marginRight: '10px'}} className={style.addressObject}>+7 (931) 543-21-23</span>
                <span className={`limit__text--length ${style.addressObject}`}>Подрядчиков Ю.Л.</span>
              </div>
            </div>
            <div>
              <span title="Даты: Старт — Финиш">Плановые сроки</span>
              <div>
                <span className={style.date}>02 фев</span>
                <span className="symbol__mdash--before"></span>
                <span className={style.date}>15 мар</span>
              </div>
            </div>
          </div>
          <BreakdownStatusBar />
          {arr.map((el: number) => <ChainItems />)}
        </main>
      </React.Fragment>
  )
}

export {BreakdownManagement}