import Config from "../../config.js";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import qs from "qs";

export default function VerifyEmail(props: React.ComponentProps<any>) {
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const token = query["token"];

  useEffect(() => {
    (async () => {
      await fetch(Config.BACKEND_ADDRESS + "verify/email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          token,
        }),
      });
    })();
  });

  return (
    <div>
      Ваша почта подтверждена!
      <Link to="/">На главную</Link>
    </div>
  );
}
