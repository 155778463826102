import React from 'react';
import styles from './CaptionsBar.module.scss';

function CaptionsBar() {
  return (
    <div className={styles.captionsBar}>
      <div>Название объекта<br/>Адрес объекта</div>
      <div>Название сметы</div>
      <div>Название наряда</div>
      <div title="Период выполнения / Стоимость выполнения">Сроки<br/>Стоимость</div>
      <div title="Бригадир / Время выполнения работ">Бригадир<br/>Продолжительность</div>
      <div>Статус</div>
      <div title="Всего / Не решёно">Споры<br/>В / Н</div>
      <div title="По чьей вине простой / Продолжительность">Ответчики<br/>Дни простоя</div>
    </div>
  );
}

export default CaptionsBar;