import axios from "axios";
import Config from "../../config";
import {parseDateTimeString} from "../../utils/estimateUtils";
import {
  CANCEL_EDIT_JOB_PLAN, CLOSE_CREATE_CHAIN,
  GET_JOB_PLAN_FAIL,
  GET_JOB_PLAN_START,
  GET_JOB_PLAN_SUCCESS, OPEN_CREATE_CHAIN, SAVE_NEW_DATES_ERROR, SAVE_NEW_DATES_START, SAVE_NEW_DATES_SUCCESS,
  SET_ESTIMATE_STATUSES,
  SET_ORDER_STATUSES, SET_SELECTED_ITEM, START_EDIT_JOB_PLAN, UPDATE_CHAIN_COPY_DATA,
  UPDATE_CHAIN_DATA, UPDATE_ENTITY_COPY_STATUS_FAIL, UPDATE_ENTITY_COPY_STATUS_START, UPDATE_ENTITY_COPY_STATUS_SUCCESS,
  UPDATE_ENTITY_STATUS_FAIL,
  UPDATE_ENTITY_STATUS_START,
  UPDATE_ENTITY_STATUS_SUCCESS, UPDATE_ORDER_DATA
} from "./jobPlanActionTypes";
import {baseNotification} from '../../utils/estimateUtils';
import { now } from "moment";

const getJobPlanStart = () => ({
  type: GET_JOB_PLAN_START
});

const getJobPlanSuccess = (data) => ({
  type: GET_JOB_PLAN_SUCCESS,
  payload: data
});

const getJobPlanFail = (error) => ({
  type: GET_JOB_PLAN_FAIL,
  payload: error
});

const setEstimateStatuses = (data) => ({
  type: SET_ESTIMATE_STATUSES,
  payload: data
});

const setOrderStatuses = (data) => ({
  type: SET_ORDER_STATUSES,
  payload: data
});

export const getJobPlan = (id) => async (dispatch, getState) => {
  dispatch(getJobPlanStart());

  //прораб
  let urlPart = 'Foreman';

  //сметчик
  if (getState().userReducer.user.role === 5) {
    urlPart = 'Estimator';
  }

  //подрядчик
  if (getState().userReducer.user.role === 1) {
    urlPart = 'Contractor';
  }

  const isEstimator = getState().userReducer.user.role === 5;
  
  try {
    const token = JSON.parse(localStorage.getItem("user"));

    const estimateStatuses = await axios.get(Config.BACKEND_ADDRESS + `${urlPart}/GetEstimateWorkFlow?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const orderStatuses = await axios.get(Config.BACKEND_ADDRESS + `${isEstimator ? 'Estimator' : 'Contractor'}/GetOrderWorkFlow?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const result = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetJobPlanByEstimateId?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(setEstimateStatuses(estimateStatuses.data));
    dispatch(setOrderStatuses(orderStatuses.data));
    
    return dispatch(getJobPlanSuccess(result.data));
  } catch (e) {
    dispatch(getJobPlanFail(e));
  }
};

const updateEntityStatusStart = (entityId) => ({
  type: UPDATE_ENTITY_STATUS_START,
  payload: entityId
});

const updateEntityStatusSuccess = (entityId) => ({
  type: UPDATE_ENTITY_STATUS_SUCCESS,
  payload: entityId
});

const updateEntityStatusError = (entityId) => ({
  type: UPDATE_ENTITY_STATUS_FAIL,
  payload: entityId
});

const updateEntityCopyStatusStart = (entityId) => ({
  type: UPDATE_ENTITY_COPY_STATUS_START,
  payload: entityId
});

const updateEntityCopyStatusSuccess = (entityId) => ({
  type: UPDATE_ENTITY_COPY_STATUS_SUCCESS,
  payload: entityId
});

const updateEntityCopyStatusError = (entityId) => ({
  type: UPDATE_ENTITY_COPY_STATUS_FAIL,
  payload: entityId
});

const updateChainData = (chainData) => ({
  type: UPDATE_CHAIN_DATA,
  payload: chainData
});

const updateChainCopyData = (chainData) => ({
  type: UPDATE_CHAIN_COPY_DATA,
  payload: chainData
});

const updateOrderData = (orderData) => ({
  type: UPDATE_ORDER_DATA,
  payload: orderData
});

export const updateEntityStatus = (data, isChain, orderAjusts = {}) => async (dispatch, getState) => {
  const token = JSON.parse(localStorage.getItem("user"));
  const updatingEntitiesId = getState().jobPlan.updatingEntitiesId;
  const jobPlanId = getState().jobPlan.data.jobPlan.id;

  if (updatingEntitiesId.length) {
    baseNotification("Выполняется обновление", "","danger");
    return;
  }
 
  dispatch(updateEntityStatusStart(data.entityId));

  try {
    if(isChain){
      await Promise.all(data.chainOrders.map(async (element) => {
        await axios.post(Config.BACKEND_ADDRESS + 'Foreman/FlowOrder', {
          "entityId": element.id,
          "newState": data.newState,
          "actionText": data.actionText,
          "comment": data.message,
          "date": parseDateTimeString(new Date()),
          "ignoreRulesToken": ""
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          }
        });
      }));
    }else{
      await Promise.all(Object.entries(orderAjusts).map(async ([key, value]) => {
        await axios.post(Config.BACKEND_ADDRESS + 'Foreman/SaveOrder', {
          "id": key,
          "chainId":value.chainId,
          "ajustText": value.ajustText,
          "workStartPlan": value.workStartPlan,
          "workEndPlan": value.workEndPlan
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          }
        });
      }));
      await axios.post(Config.BACKEND_ADDRESS + 'Contractor/FlowEstimate', data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        }
      });
    }
    const result = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetJobPlanById?Id=${jobPlanId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(getJobPlanSuccess(result.data));

    console.log('UPDATE ENTITY RESULT => ', result);

    dispatch(updateOrderData(result.data));
    dispatch(updateEntityStatusSuccess(data.entityId));
    baseNotification("Успешно", '',"success");
  } catch (e) {
    dispatch(updateEntityStatusError(data.entityId));
    baseNotification("Произошла ошибка", "","danger");
    console.log('UPDATE ENTITY ERROR => ', e);
  }
};

export const startEditJobPlan = () => ({
  type: START_EDIT_JOB_PLAN
});

export const cancelEditJobPlan = () => ({
  type: CANCEL_EDIT_JOB_PLAN
});

export const changeChainStartDate = (chainId, workStartPlan) => async (dispatch, getState) => {
  const token = JSON.parse(localStorage.getItem("user"));
  const updatingCopyEntitiesId = getState().jobPlan.updatingCopyEntitiesId;
  const jobPlanId = getState().jobPlan.data.jobPlan.id;

  if (updatingCopyEntitiesId.length && updatingCopyEntitiesId.includes(chainId)) {
    baseNotification("Выполняется пересчет дат", "","danger");
    return;
  }

  dispatch(updateEntityCopyStatusStart(chainId));

  try {
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/CalculateOrderDates', {
      id: chainId,
      jobPlanId,
      workStartPlan,
      name: " "
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(updateChainCopyData(result.data));
    dispatch(updateEntityCopyStatusSuccess(chainId));
  } catch (e) {
    dispatch(updateEntityCopyStatusError(chainId));
    baseNotification("Произошла ошибка", "","danger");
  }
};

const saveNewDatesStart = () => ({
  type: SAVE_NEW_DATES_START
});

const saveNewDatesSuccess = (data) => ({
  type: SAVE_NEW_DATES_SUCCESS,
  payload: data
});

const saveNewDatesError = () => ({
  type: SAVE_NEW_DATES_ERROR
});

export const saveNewDates = () => async (dispatch, getState) => {
  const token = JSON.parse(localStorage.getItem("user"));
  const updatingCopyEntitiesId = getState().jobPlan.updatingCopyEntitiesId;
  const jobPlanId = getState().jobPlan.data.jobPlan.id;
  const originalChains = getState().jobPlan.data.chains;
  const copyChains = getState().jobPlan.dataCopy.chains;
  const savingNewDates = getState().jobPlan.savingNewDates;

  if (updatingCopyEntitiesId.length) {
    baseNotification("Выполняется пересчет дат", "","danger");
    return;
  }

  if (savingNewDates) {
    baseNotification("Идет сохранение", "","danger");
    return;
  }

  const changedChains = copyChains.filter((item, index) => item.workStartPlan !== originalChains[index].workStartPlan);

  if (changedChains.length) {
    dispatch(saveNewDatesStart());

    try {
      const promises = changedChains.map(item => {
        axios.post(Config.BACKEND_ADDRESS + 'Foreman/SaveChain', {
          id: item.id,
          jobPlanId,
          workStartPlan: item.workStartPlan,
          name: item.name
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          }
        })
      });

      Promise.all(promises).then(async () => {
        const result = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetJobPlanById?Id=${jobPlanId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          }
        });

        dispatch(saveNewDatesSuccess(result.data));
      }).catch(() => {
        baseNotification("Произошла ошибка", "","danger");
        dispatch(saveNewDatesError());
      });

      baseNotification("Данные сохранены", '',"success");
    } catch (e) {
      baseNotification("Произошла ошибка", "","danger");
      dispatch(saveNewDatesError());
    }
  } else {
    baseNotification("Нет измененных данных для сохранения", "","danger");
  }
};

export const setSelectedItem = (id, parentId, entityType) => ({
  type: SET_SELECTED_ITEM,
  payload: {
    id,
    parentId,
    entityType
  }
});

export const openCreateChainWindow = () => ({
  type: OPEN_CREATE_CHAIN
});

export const closeCreateChainWindow = () => ({
  type: CLOSE_CREATE_CHAIN
});

export const calculateNewChainDates = (jobPlanId, chainId, orderId, workStartPlan) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));

  try {
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/CalculateOrderDates', {
      id: chainId,
      jobPlanId,
      orderId,
      name: " ",
      workStartPlan: workStartPlan
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    return result.data;
  } catch (e) {
    baseNotification("Произошла ошибка", "", "danger");
    return e;
  }
}

export const saveNewChain = (jobPlanId, chainId, orderId, workStartPlan, name) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));

  try {
    await axios.post(Config.BACKEND_ADDRESS + 'Foreman/SaveChain', {
      id: chainId,
      jobPlanId,
      orderId,
      name,
      workStartPlan: workStartPlan
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const result = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetJobPlanById?Id=${jobPlanId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(getJobPlanSuccess(result.data));
    dispatch(closeCreateChainWindow());
    dispatch(cancelEditJobPlan());
  } catch (e) {
    baseNotification("Произошла ошибка", "", "danger");
    return e;
  }
}