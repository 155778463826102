import React, {useEffect, useState} from "react";
import style from "../../pages/EstimateManagement/TechnicalTaskBreakdown/technicalTaskBreakdown.module.scss";
import {DownloadFiles} from "./DownloadFiles";
import {downloadFileItem} from "../../utils/downloadItem";
import {useDispatch} from "react-redux";
import {deleteEstimateDocument, deleteItemSuccess, setDescriptionItem} from "../../redux/estimate/actionEstimate";
import {useDebounced} from "../../hooks/useDebounced";

interface Arr {
  documentId: string;
  estimateId: string;
  added?: boolean;
  document: {
    added: boolean;
    id: string;
    path: string;
    estimateId: string;
    description: any;
    originalFileName: boolean | string | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
  };
}

const ListFiles = ({arr, edit, estimate, type}: any) => {
  const dispatch = useDispatch<any>();

  const download = async (id: string, fileName: any) => {
    await downloadFileItem(id, fileName);
  }

  const deleteItem = (documentId: any, estimateId: any) => {
    dispatch(deleteEstimateDocument({documentId, estimateId}));
  }
  return (
    <div className={style.openMenu}>
      {arr.map((el: Arr) =>
        <div key={el.document.id} className={`row__file ${style.file}`}>
          <div style={{display: 'flex'}}>
            <span style={{display: 'inline', marginRight: '6px'}} className="symbol__bull--before"></span>
            <span style={{width: "470px"}}><a onClick={() => download(el.documentId, el.document.originalFileName)}
                                              target="_blank">{el.document.originalFileName}</a></span>
            {edit && el?.document?.added &&
              <button onClick={() => deleteItem(el.document.id, el.estimateId)} className={`bttn__small`}
                      style={{marginLeft: '74px', width: '76px', marginRight: '12px'}} type="button">
                Удалить
              </button>}
          </div>
          {!edit ?
            <span style={{fontSize: '12px', marginLeft: '14px', fontWeight: 'bold'}}>
                  {el.document?.description}
                </span> : el.document.added ?
              <TextArea desc={el.document.description} id={el.document.id} /> :
              <span style={{fontSize: '12px', marginLeft: '14px', fontWeight: 'bold'}}>
                  {el.document?.description}
                </span>
          }
        </div>
      )}
      {edit && (type !== 4 && type !== 5) && <DownloadFiles estimate={estimate} type={type} />}
    </div>
  )
};

const TextArea = ({desc, id}: any) => {
  const [description, setDescription] = useState<string>(desc);
  const dispatch = useDispatch<any>();
  const deboounced = useDebounced(description, 600);

  useEffect(() => {
    const result = {
      description: description,
      id: id
    }
    if (deboounced) {
      dispatch(setDescriptionItem(result));
    }
  }, [deboounced])

  return (
    <textarea onChange={(e) => setDescription(e.target.value)} className={style.textarea}
              placeholder="(указание необязательно)">
        {description}
      </textarea>
  )
};

export {ListFiles};