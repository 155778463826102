import React, {useState} from 'react';
import styles from './HourlyRate.module.scss';
import Modal from "../../../../../Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {IProfileState} from "../../../../../../redux/profile/types";
import {saveEmployee, saveProfile} from "../../../../../../redux/profile/profileActions";
import {baseNotification} from "../../../../../../utils/estimateUtils";
import {smsSend} from "../../../../../../utils/sendSms";

interface HourlyRateProps {
  onClose: Function
}

function HourlyRate({onClose}: HourlyRateProps) {
  const [newRate, setNewRate] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [smsCode, setSmsCode] = useState<string>("");
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const dispatch = useDispatch<any>();

  const handleChangeNewRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    setNewRate(isNaN(val) ? '' : val.toString());
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (saving) {
      return;
    }

    setSaving(true);

    dispatch(saveProfile({
      responsible: {...profile.data?.responsible, smsCode},
      contractor: {...profile.data?.contractor, hourlyRate: parseInt(newRate)},
    })).then(() => {
      setSaving(false);
      baseNotification("Успешно", `Ставка успешно изменена`,"success");
      onClose();
    }).catch(() => {
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
      setSaving(false);
    });
  };

  const handleChangeSmsCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSmsCode(e.target.value);
  };

  const askForSmsHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await smsSend(e, profile.data?.responsible.login)
    .catch(() => {
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
      setSaving(false);
    })
  }

  return (
    <Modal title="Изменение ставки нормо-часа">
      <div className={styles.hourlyRate}>
        <form className={styles.hourlyRateContent}>

          <label>Рекомендуемая ставка</label>
          <input type="text" placeholder="" value={profile.data?.recomendedHourlyRate} disabled/>

          <label>Текущая ставка</label>
          <input type="text" placeholder="" value={profile.data?.contractor.hourlyRate} disabled/>

          <label>Новая ставка</label>
          <input
            type="text"
            placeholder="(введите значение)"
            value={newRate}
            onChange={handleChangeNewRate}
          />

          <p className={styles.hourlyRateCodeTitle}>Код подтверждения</p>
          <button name="sms__query" type="button" disabled={!newRate || saving} onClick={askForSmsHandler}>Прислать СМС</button>
          <input name="sms__check" type="password" placeholder="(код из СМС)" disabled={!newRate} onChange={handleChangeSmsCode}/>

          <hr/>

          <button type="button" disabled={!newRate || !smsCode || saving} onClick={handleSave}>Изменить ставку</button>
          <button type="button" onClick={() => onClose()}>Отмена</button>
        </form>
      </div>
    </Modal>
  );
}

export default HourlyRate;