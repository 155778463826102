import React, {useState, useMemo} from 'react';
import styles from './Employees.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {IProfileState} from "../../../../redux/profile/types";
import ChangeBlockConfirm from "./components/ChangeBlockConfirm/ChangeBlockConfirm";
import CreateEmployee from "./components/CreateEmployee/CreateEmployee";
import {saveEmployee} from "../../../../redux/profile/profileActions";
import {baseNotification} from "../../../../utils/estimateUtils";

function Employees() {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [changeBlockOpen, setChangeBlockOpen] = useState<boolean>(false);
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState<boolean>(false);
  const [verifiedIds, setVerifiedIds] = useState<string[]>([]);
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const dispatch = useDispatch<any>();

  const handleSelectRow = (id: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedRowId(id);
  };

  const selectedEmployee = useMemo(() => {
    if (!profile.data?.employees.length || !selectedRowId) {
      return null;
    }

    return profile.data.employees.find(item => item.userId === selectedRowId);
  }, [profile.data?.employees, selectedRowId]);

  const handleChangeBlock = (e: React.MouseEvent<HTMLButtonElement>) => {
    setChangeBlockOpen(true);
  };

  const handleCloseChangeBlock = () => {
    setChangeBlockOpen(false);
  };

  const handleOpenCreateEmployee = (e: React.MouseEvent<HTMLButtonElement>) => {
    setCreateEmployeeOpen(true);
  };

  const handleCloseCreateEmployee = () => {
    setCreateEmployeeOpen(false);
  };

  const handleVerify = (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    if (verifiedIds.includes(id)) {
      return;
    }

    const employee = profile.data?.employees.find(item => item.userId === id);

    if (!employee) {
      return;
    }

    setVerifiedIds(prev => [...prev, id]);

    dispatch(saveEmployee({...employee, isVerified: true})).then(() => {
      setVerifiedIds(prev => [...prev].filter(item => item !== id));
      baseNotification("Успешно", `Аккаунт ${employee.login} подтвержден`,"success");
    }).catch(() => {
      setVerifiedIds(prev => [...prev].filter(item => item !== id));
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
    });
  };

  return (
    <div className={styles.employees}>

      <div className={styles.employeesCaptions}>

        <div>
          <span>Фамилия Имя Отчество</span>


          <button
            className="bttn__small"
            type="button"
            title="Регистрация пользователя с ролью «Прораб»"
            onClick={handleOpenCreateEmployee}
          >
            Добавить
          </button>

          {/*Кнопка изначально недоступна (disabled), текст «Не выбрано».*/}
          {/*Предполагается, что становится доступной при выборе любой строки Прораба*/}
          {/*(<label class="foreman__container">).*/}

          {/*Подразумевается, что клик по строке Прораба меняет атрибуты кнопки:*/}
          {/*  убирает атрибут «disabled» (кнопка становится доступной);*/}
          {/*  меняет значение атрибута «name» на:*/}
          {/*    foreman__lock     — если Прораб не заблокирован*/}
          {/*    foreman__unlock   — если Прораб заблокирован*/}
          {/*Значения «name» являются селекторами для открытия*/}
          {/*соответствующего модального окна (блокировка/разблокировка).*/}

          {/*Тексты кнопки прописаны во псевдоэлементе (CSS)*/}
          {/*и соответствуют значениям «name».*/}
          <div>
            {selectedEmployee && (
              <button
                className="bttn__small"
                type="button"
                title={`${selectedEmployee.isBlocked ? 'Разблокировка' : 'Блокировка' } учётной записи Прораба`}
                onClick={handleChangeBlock}
              >
                {selectedEmployee.isBlocked ? 'Разблокировать' : 'Блокировать'}
              </button>
            )}
          </div>
        </div>
        <span>Логин</span>
        <span>E-mail</span>
        <span>Телефон</span>
        <span>Аккаунт</span>
      </div>


      {/*Данные: строки Прорабов.*/}
      {/*Клик по строке делает её текущей.*/}
      {/*Предполагается, что становится доступной кнопка «Заблокировать» / «Разблокировать».*/}
      {/*2-й класс строки определяет статус аккаунта:*/}
      {/*Подтверждён — foreman__account--confirmed*/}
      {/*Не подтверждён — foreman__account--non-confirmed*/}
      {/*Заблокирован — account__locked*/}
      {/*Строки заблокированных Прорабов помещаются в конец списка.*/}

      <div className={styles.employeesData}>
        {profile.data?.employees.map(employee => (
          <div
            key={employee.userId}
            className={`
              ${styles.employeeItem} 
              ${employee.userId === selectedRowId ? styles.selected : ''} 
              ${employee.isBlocked ? styles.locked : ''}
            `}
            title="Для выбора кликните по строке"
            onClick={handleSelectRow(employee.userId)}
          >
            <span>{employee.lastName} {employee.firstName} {employee.secondName}</span>
            <span>{employee.login}</span>
            <span className={!employee.isVerified ? styles.notVerified : ''}>{employee.email}</span>
            <span className={!employee.isVerified ? styles.notVerified : ''}>{employee.phone}</span>
            <span className={styles.employeeItemStatus}>
              {employee.isVerified ? (
                <span className={styles.verified}>Подтвержден</span>
                ) : (
                <button
                  className="bttn__small"
                  type="button"
                  title="Запросить подтверждение"
                  onClick={handleVerify(employee.userId)}
                  disabled={verifiedIds.includes(employee.userId)}
                >
                  Подтвердить
                </button>
              )}
            </span>
          </div>
        ))}
      </div>

      {changeBlockOpen && selectedEmployee && (
        <ChangeBlockConfirm
          employee={selectedEmployee}
          onClose={handleCloseChangeBlock}
        />
      )}

      {createEmployeeOpen && profile.data?.contractor.id && (
        <CreateEmployee contractorId={profile.data?.contractor.id} onClose={handleCloseCreateEmployee}/>
      )}
    </div>
  );
}

export default Employees;