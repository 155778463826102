import {handlePlanDate, getShortFIO} from "../../utils/estimateUtils";

interface ParentPanelParams{
	complaintCardObject: any;
	contractorInfo: any;
}

const ParentPanel = (params: ParentPanelParams): JSX.Element => {
  const {buildingName, buildingAddress, estimateTitle, orderName, startDate, endDate, workStartPlan, workEndPlan} = 
  		params.complaintCardObject;
  const {contractor} = params.contractorInfo;
  const contractorNameObject = contractor?.employees ? contractor.employees[0] : {};
  const contractorFIO = getShortFIO(contractorNameObject);
  const commonStartDate = startDate || workStartPlan;
  const commonEndDate = endDate || workEndPlan;
  return (
    <div className="bar__parent-data">

		<div className="limit__text--length">
			<a
				className="link-light"
				href="/_"
				target="_blank"
				title="Карточка объекта"
			>{buildingName}</a>
		</div>

		<div className="limit__text--length">{buildingAddress}</div>

		<div className="limit__text--length">
			<a
				className="link-light"
				href="/_"
				target="_blank"
				title="Страница Сметы"
			>{estimateTitle}</a>
		</div>

		<div className="limit__text--length">
			<a
				className="link-light"
				href="/_"
				target="_blank"
				title="Страница Наряда"
			>{orderName}</a>
		</div>

		<div className="limit__text--length">
			<a
				className="link-light"
				href="/_"
				target="_blank"
				title="Карточка подрядчика"
			>{contractor?.name}</a>
		</div>

		<div>
			<span>{contractor?.phone}</span>
			<span className="limit__text--length">{contractorFIO}</span>
		</div>

		<div>
			<span title="Даты: Начало — Окончание">Плановые сроки</span>
			<span>{handlePlanDate(commonStartDate)}</span>
			<span className="symbol__mdash--before"></span>
			<span>{handlePlanDate(commonEndDate)}</span>
		</div>

	</div>
  );
};

export default ParentPanel;