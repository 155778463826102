import React, {useState, useMemo} from 'react';
import ObjectPageHead from '../ObjectPageHead/ObjectPageHead';
import ParentDataBar from './components/ParentDataBar/ParentDataBar';
import StatusBar from './components/StatusBar/StatusBar';
import ChainBox from './components/ChainBox/ChainBox';
import {IJobPlan} from '../../types/JobPlan';
import moment, {Moment} from 'moment';
import {useSelector} from 'react-redux';
import NewChain from './components/NewChain/NewChain';
// import {IUserRole} from "../../types/User";
import { ROLECODES } from '../../utils/getRoleName';

interface JobPlanProps {
  jobPlan: IJobPlan
}

function JobPlan({jobPlan}: JobPlanProps) {
  const [orderAjusts, setOrderAjusts] = useState({});

  // const userRole_ = useSelector<any>(state => state.roles.roles.find((item: IUserRole) => item.id === state.userReducer.user.role)) as IUserRole;
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;
  const updatingEntitiesId = useSelector<any>(state => state.jobPlan.updatingEntitiesId) as string[];
  const editMode = useSelector<any>(state => state.jobPlan.editMode) as boolean;
  const savingNewDates = useSelector<any>(state => state.jobPlan.savingNewDates) as boolean;
  const createChainOpen = useSelector<any>(state => state.jobPlan.createChainOpen) as boolean;

  //редактирование для роли "прораб"

  // const userRole: IUserRole = {...userRole_};
  
  const canEditPlan = userRoleCode === ROLECODES.FOREMAN;
  const startEndDates = useMemo((): [Moment, Moment] => {
    const dates = jobPlan.chains.map(chain => {
      return [moment(chain.workStartPlan), moment(chain.workEndPlan)];
    });

    const startDate = dates.sort((a, b) => {
      if (a[0].valueOf() < b[0].valueOf()) {
        return -1;
      }
      if (a[0].valueOf() > b[0].valueOf()) {
        return  1;
      }

      return 0;
    })[0][0];

    const endDate = dates.sort((a, b) => {
      if (a[1].valueOf() > b[1].valueOf()) {
        return -1;
      }
      if (a[1].valueOf() < b[1].valueOf()) {
        return  1;
      }

      return 0;
    })[0][1];

    return  [startDate, endDate];
  }, [jobPlan]);

  const titlePart = useMemo(() => {
    if (updatingEntitiesId.length) {
      return ': обновление...';
    }

    if (savingNewDates) {
      return ': сохранение...';
    }

    return editMode ? ': редактирование' : '';
  }, [editMode, updatingEntitiesId, savingNewDates]);

  return (
    <>
      <ObjectPageHead title={`Карточка разбивки${titlePart}`} roleCode={userRoleCode}/>
      <ParentDataBar jobPlanData={jobPlan.jobPlan}/>
      <StatusBar
        startEndDates={startEndDates}
        jobPlanData={jobPlan.jobPlan}
        canEditPlan={canEditPlan}
        orderAjusts = {orderAjusts} 
      />
      <ChainBox
        startEndDates={startEndDates}
        chains={jobPlan.chains}
        jobPlanStatus={jobPlan.jobPlan.status}
        orderAjusts = {orderAjusts} 
        setOrderAjusts = {setOrderAjusts}
        canEditPlan={canEditPlan}
      />
      {createChainOpen && (
        <NewChain/>
      )}
    </>
  );
}

export default JobPlan;