import {
  CANCEL_EDIT_JOB_PLAN, CLOSE_CREATE_CHAIN,
  GET_JOB_PLAN_FAIL,
  GET_JOB_PLAN_START,
  GET_JOB_PLAN_SUCCESS, OPEN_CREATE_CHAIN, SAVE_NEW_DATES_ERROR, SAVE_NEW_DATES_START, SAVE_NEW_DATES_SUCCESS,
  SET_ESTIMATE_STATUSES,
  SET_ORDER_STATUSES, SET_SELECTED_ITEM, START_EDIT_JOB_PLAN, UPDATE_CHAIN_COPY_DATA,
  UPDATE_CHAIN_DATA, UPDATE_ENTITY_COPY_STATUS_FAIL, UPDATE_ENTITY_COPY_STATUS_START, UPDATE_ENTITY_COPY_STATUS_SUCCESS,
  UPDATE_ENTITY_STATUS_FAIL,
  UPDATE_ENTITY_STATUS_START,
  UPDATE_ENTITY_STATUS_SUCCESS, UPDATE_ORDER_DATA
} from "./jobPlanActionTypes";

const initialState = {
  data: null,
  estimateStatuses: null,
  orderStatuses: null,
  error: false,
  loading: false,
  updatingEntitiesId: [],

  dataCopy: null,
  editMode: false,
  savingNewDates: false,
  updatingCopyEntitiesId: [],

  selectedItem: null,

  createChainOpen: false,
};

const updateChainData = (chainsArray, chain) => {
  return chainsArray.map(item => item.id === chain.id ? chain : item);
}

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_JOB_PLAN_START:
      return {...state, loading: true};

    case GET_JOB_PLAN_SUCCESS:
      return {...state, loading: false, error: false, data: payload};

    case GET_JOB_PLAN_FAIL:
      return {...state, loading: false, error: payload};

    case SET_ESTIMATE_STATUSES:
      return {...state, estimateStatuses: payload};

    case SET_ORDER_STATUSES:
      return {...state, orderStatuses: payload};

    case UPDATE_ENTITY_STATUS_START:
      return {...state, updatingEntitiesId: [...state.updatingEntitiesId, payload]};

    case UPDATE_ENTITY_STATUS_SUCCESS:
      return {...state, updatingEntitiesId: [...state.updatingEntitiesId].filter(item => item !== payload)};

    case UPDATE_ENTITY_STATUS_FAIL:
      return {...state, updatingEntitiesId: [...state.updatingEntitiesId].filter(item => item !== payload)};

    case UPDATE_ORDER_DATA:
      return {...state, data: payload};

    case UPDATE_CHAIN_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          chains: updateChainData(state.data.chains, payload)
        }
      };

    case UPDATE_ENTITY_COPY_STATUS_START:
      return {...state, updatingCopyEntitiesId: [...state.updatingCopyEntitiesId, payload]};

    case UPDATE_ENTITY_COPY_STATUS_SUCCESS:
      return {...state, updatingCopyEntitiesId: [...state.updatingCopyEntitiesId].filter(item => item !== payload)};

    case UPDATE_ENTITY_COPY_STATUS_FAIL:
      return {...state, updatingCopyEntitiesId: [...state.updatingCopyEntitiesId].filter(item => item !== payload)};

    case UPDATE_CHAIN_COPY_DATA:
      return {
        ...state,
        dataCopy: {
          ...state.dataCopy,
          chains: updateChainData(state.dataCopy.chains, payload)
        }
      };

    case START_EDIT_JOB_PLAN:
      return {...state, editMode: true, dataCopy: Object.assign({}, state.data)};

    case CANCEL_EDIT_JOB_PLAN:
      return {...state, editMode: false, dataCopy: null};

    case SAVE_NEW_DATES_START:
      return {...state, savingNewDates: true};

    case SAVE_NEW_DATES_SUCCESS:
      return {...state, savingNewDates: false, data: payload, dataCopy: null, editMode: false, updatingCopyEntitiesId: []};

    case SAVE_NEW_DATES_ERROR:
      return {...state, savingNewDates: false};

    case OPEN_CREATE_CHAIN:
      return {...state, createChainOpen: true};

    case CLOSE_CREATE_CHAIN:
      return {...state, createChainOpen: false};

    case SET_SELECTED_ITEM:
      return {...state, selectedItem: payload};  

    default:
      return { ...state };
  }
};

export default reducer;