import React from 'react';
import style from './chainItemsSubitems.module.scss';

const ChainItemsSubitems = () => {
  return (
      <React.Fragment>
        <div className={style.container}>
          <div style={{display: 'flex'}}>
            <div className="symbol__bull--before"></div>
            <div className={style.name}>Монтаж арматуры, муфтовая, ф15</div>
          </div>
          <div style={{width: '74px', display: 'flex', justifyContent: 'flex-end', marginLeft: '14px'}}>20000,00</div>
          <div style={{width: '74px', display: 'flex', justifyContent: 'flex-start', paddingLeft: "10px"}}>шт.</div>
          <div style={{width: '80px', display: 'flex', justifyContent: 'center'}}>100</div>
          <div style={{width: '80px', display: 'flex', justifyContent: 'flex-end'}}>200</div>
        </div>
      </React.Fragment>
  )
}

export {ChainItemsSubitems}