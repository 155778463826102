import axios from "axios";
import Config from "../../config";
import {baseNotification, parseDate} from "../../utils/estimateUtils";

export const GET_BUILDINGS_START = "GET_BUILDINGS_START";
export const GET_BUILDINGS_SUCCESS = "GET_BUILDINGS_SUCCESS";
export const GET_BUILDINGS_FAIL = "GET_BUILDINGS_FAIL";

export const GET_ESTIMATETYPES_START = "GET_ESTIMATETYPES_START";
export const GET_ESTIMATETYPES_SUCCESS = "GET_ESTIMATETYPES_SUCCESS";
export const GET_ESTIMATETYPES_FAIL = "GET_ESTIMATETYPES_FAIL";

export const GET_CONTRACTOR_INFO_START = "GET_CONTRACTOR_INFO_START";
export const GET_CONTRACTOR_INFO_SUCCESS = "GET_CONTRACTOR_INFO_SUCCESS";

export const GET_ESTIMATE_WORK_FLOW_START = "GET_ESTIMATE_WORK_FLOW_START";
export const GET_ESTIMATE_WORK_FLOW_SUCCESS = "GET_ESTIMATE_WORK_FLOW_SUCCESS";
export const GET_ESTIMATE_WORK_FLOW_FAIL = "GET_ESTIMATE_WORK_FLOW_FAIL";

export const GET_COMPLAINTCARD_START = "GET_COMPLAINTCARD_START";
export const GET_COMPLAINTCARD_SUCCESS = "GET_COMPLAINTCARD_SUCCESS";
export const GET_COMPLAINTCARD_FAIL = "GET_COMPLAINTCARD_FAIL";

export const GET_COMPLAINTLIST_START = "GET_COMPLAINTLIST_START";
export const GET_COMPLAINTLIST_SUCCESS = "GET_COMPLAINTLIST_SUCCESS";
export const GET_COMPLAINTLIST_FAIL = "GET_COMPLAINTLIST_FAIL";

export const GET_PARENT_INFO_BY_ORDERID_START = "GET_PARENT_INFO_BY_ORDERID_START";
export const GET_PARENT_INFO_BY_ORDERID_SUCCESS = "GET_PARENT_INFO_BY_ORDERID_SUCCESS";
export const GET_PARENT_INFO_BY_ORDERID_FAIL = "GET_PARENT_INFO_BY_ORDERID_FAIL";

export const GET_COMPLAINT_TOPICS_START = "GET_COMPLAINT_TOPICS_START";
export const GET_COMPLAINT_TOPICS_SUCCESS = "GET_COMPLAINT_TOPICS_SUCCESS";
export const GET_COMPLAINT_TOPICS_FAIL = "GET_COMPLAINT_TOPICS_FAIL";

export const SET_NEW_BANK_ACCOUNT_SUCCESS = "SET_NEW_BANK_ACCOUNT_SUCCESS";

export const SET_ERROR = 'SET_ERROR';

export const SET_COMPLAINT_DATA_SEND_START = "SET_COMPLAINT_DATA_SEND_START";
export const SET_COMPLAINT_DATA_SEND_SUCCESS = "SET_COMPLAINT_DATA_SEND_SUCCESS";
export const SET_COMPLAINT_DATA_SEND_FAIL = "SET_COMPLAINT_DATA_SEND_FAIL";

export const getBuildings = () => async (dispatch) => {
  dispatch(fetchBuildingStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const contractor = JSON.parse(localStorage.getItem("contractor"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/GetBuildings', {
      contractorId: contractor?.contractor?.id,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(fetchBuildingSuccess(result.data));
  } catch (e) {
    dispatch(fetchBuildingFail(e));
  }
}

export const getContractorInfo = () => async (dispatch) => {
  dispatch(fetchContractorInfoStart());

  const token = JSON.parse(localStorage.getItem("user"));

  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + "Contractor/GetContractorInfo", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      withCredentials: true
    });
    dispatch(fetchContractorInfoSuccess(result.data))
    return result.data
  }
    catch (e) {
    dispatch(fetchBuildingFail(e));
    return e;
  }
}

export const getEstimateWork = () => async (dispatch) => {
  dispatch(fetchEstimateWorkStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Contractor/GetEstimateWorkFlow', {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(fetchEstimateWorkSuccess(result.data));
  } catch (e) {
    dispatch(fetchEstimateWorkFail(e));
  }
}

export const getEstimateTypes = () => async (dispatch) => {
  dispatch(fetchEstimateTypesStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Contractor/GetEstimateTypes', {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    return dispatch(fetchEstimateTypesSuccess(result.data));
  } catch (e) {
    dispatch(fetchEstimateTypesFail(e));
  }
}

export const createEstimate = (body) => async (dispatch) => {
    const {estimateName, estimateTypeId, workStartPlan, workEndPlan, buildingId} = body;
    const token = JSON.parse(localStorage.getItem("user"));
    try {
      const res = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/CreateEstimate', {
        buildingId,
        estimateName,
        estimateTypeId: Number(estimateTypeId),
        workStartPlan: new Date(parseDate(workStartPlan)).toISOString(),
        workEndPlan: new Date(parseDate(workEndPlan)).toISOString()
      },{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        }
      });
      baseNotification("Смета создана", '',"success");
      //dispatch(getBuildings());
      return res;
    } catch (e) {
      baseNotification("Не удалось создать смету", "","danger");
    }
}

export const createObject = (body) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));
  const { estimateTypeId, workStartPlan, workEndPlan, longitude, latitude } = body;

  try {
    const res = await axios.post(Config.BACKEND_ADDRESS + 'Contractor/CreateBuilding', {
      ...body,
      longitude: Number(longitude),
      latitude: Number(latitude),
      estimateTypeId: Number(estimateTypeId),
      workStartPlan: new Date(parseDate(workStartPlan)).toISOString(),
      workEndPlan: new Date(parseDate(workEndPlan)).toISOString()
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    baseNotification("Объект создан", '',"success");
    //dispatch(getBuildings());
    return res;
  } catch (e) {
    baseNotification("Не удалось создать объект", "","danger");
  }
}

export const sendFlowEstimate = (body) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));

  try {
    await axios.post(Config.BACKEND_ADDRESS + 'Contractor/FlowEstimate', {
      ...body
        }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    baseNotification("Успешно", '',"success");
    dispatch(getBuildings());
  } catch (e) {
    baseNotification("Произошла ошибка", "","danger");
  }
}

export const loginEstimator = async () => {
  const response = await fetch(Config.BACKEND_ADDRESS + "api/auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    // credentials: "include",
    body: JSON.stringify({
      login:"estimator",
      password:"G@hjkmLkzGkfnajhvs22"
    }),
  });
  const result = await response.json();
  return result.token;
}

export const addNewContractorBankAccount = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.post(Config.BACKEND_ADDRESS + "Contractor/SaveContractorBankAccount", data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.token}`,
          },
        });
    dispatch(setNewBankAccountSuccess(response.data))
  } catch (e) {
    dispatch(setError(e))
  }
}

export const getComplaintCard = (id) => async (dispatch) => {
  if(!id) return;
  dispatch(fetchComplaintCardStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Foreman/GetComplaint?Id=' + id, { 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    });
    dispatch(fetchComplaintCardSuccess(result.data))
  } catch (e) {
    dispatch(fetchComplaintCardFail(e))
  }
}

export const getComplaintTopics = () => async (dispatch) => {
  dispatch(fetchComplaintTopicsStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + 'Estimator/GetComplaintTopics', { 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    });
    dispatch(fetchComplaintTopicsSuccess(result.data))
  } catch (e) {
    dispatch(fetchComplaintTopicsFail(e))
  }
}

export const getComplaintList = (filters) => async (dispatch) => {
  dispatch(getComplaintListStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const {estimate_order, building_address, theme, replica, status, dateFrom, dateTo, responder} = filters;
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/GetComplaints', {
      estimateOrderFilter: estimate_order,
      buildingFilter: building_address,
      topicId: parseInt(theme) || 0,
      messageFilter: replica,
      status: parseInt(status) || 0,
      dateFrom: dateFrom,
      dateTo: dateTo,
      respondentRoleFilter: parseInt(responder) || 0
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(getComplaintListSuccess(result.data));
  } catch (e) {
    dispatch(getComplaintListFail(e));
  }
}

export const getParentInfoByOrderId = (orderId) => async (dispatch) => {
  if(!orderId) return;
  dispatch(fetchParentInfoByOrderIdStart());
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const result = await axios.get(Config.BACKEND_ADDRESS + '/Foreman/GetOrderById?Id=' + orderId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(fetchParentInfoByOrderIdSuccess(result.data))
  } catch (e) {
    dispatch(fetchParentInfoByOrderIdFail(e))
  }
}

export const createComplaint = (createId, topicId, messageText) => async (dispatch) => {
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/CreateComplaint', {
      orderId: createId,
      topicId: topicId,
      messageText: messageText,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setComplaintDataSendSuccess());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e));
  }
}

export const sendFiles = (uploadFiles) => async (dispatch) => {
  if(!uploadFiles) return;
  dispatch(setComplaintDataSendStart());
  const formData = new FormData();
  uploadFiles.forEach(uploadFile => {
    formData.append('files', uploadFile.file);
  });
  try {
    const result = await axios.post(Config.BACKEND_ADDRESS + `File/UploadFile`, formData, {headers: {
        'content-type': 'application/octet-stream',
      }});
    dispatch(setComplaintDataSendSuccess());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e))
  }
}

export const joinFilesToMessage = (messageId, uploadFiles, uploadResult) => async (dispatch) => {
  if(!messageId) return;
  if(uploadFiles.length !== uploadResult.length) return;
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    for(let i = 0; i < uploadFiles.length; i++){
      await axios.post(Config.BACKEND_ADDRESS + `Foreman/SaveMessageDocument`, {
        description: uploadFiles[i].comment,
        url: null,
        documentType: 1,
        originalFileName: uploadResult[i].name,
        fileId: uploadResult[i].fileId,
        contentType: "application/octet-stream",
        messageId: messageId
      }, {headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }});
    };
    dispatch(setComplaintDataSendSuccess());
  } catch (e) {
    dispatch(setComplaintDataSendFail(e))
  }
}

export const addMessage = (id, postMessage, uploadResult) => async (dispatch) => {
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Foreman/AddComplaintMessage', {
      id: id,
      newMessage: postMessage,
      fileIds: uploadResult,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setComplaintDataSendSuccess());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e));
  }
}

export const changeComplaintState = (complaintId, newState, actionText, message, date) => async (dispatch) => {
  dispatch(setComplaintDataSendStart());
  try {
    const token = JSON.parse(localStorage.getItem("user"));
    const result = await axios.post(Config.BACKEND_ADDRESS + 'Estimator/FlowComplaint', {
      entityId: complaintId,
      newState: newState,
      actionText: actionText,
      comment: message,
      date: date,
      ignoreRulesToken: "",
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });
    dispatch(setComplaintDataSendSuccess());
    return result.data;
  } catch (e) {
    dispatch(setComplaintDataSendFail(e));
  }
}

const setError = (payload) => ({
  type: SET_ERROR,
  payload
})

const setNewBankAccountSuccess = (payload) => ({
  type: SET_NEW_BANK_ACCOUNT_SUCCESS,
  payload
})

const fetchBuildingSuccess = (payload) => ({
  type: GET_BUILDINGS_SUCCESS,
  payload,
})

const fetchBuildingStart = () => ({
  type: GET_BUILDINGS_START
})

const fetchBuildingFail = (payload) => ({
  type: GET_BUILDINGS_FAIL,
  payload,
})

const fetchEstimateTypesSuccess = (payload) => ({
  type: GET_ESTIMATETYPES_SUCCESS,
  payload,
})

const fetchEstimateTypesStart = () => ({
  type: GET_ESTIMATETYPES_START
})

const fetchEstimateTypesFail = (payload) => ({
  type: GET_ESTIMATETYPES_FAIL,
  payload,
})

const fetchEstimateWorkSuccess = (payload) => ({
  type: GET_ESTIMATE_WORK_FLOW_SUCCESS,
  payload,
})

const fetchEstimateWorkStart = () => ({
  type: GET_ESTIMATE_WORK_FLOW_START
})

const fetchEstimateWorkFail = (payload) => ({
  type: GET_ESTIMATE_WORK_FLOW_FAIL,
  payload,
})

const fetchContractorInfoSuccess = (payload) => ({
  type: GET_CONTRACTOR_INFO_SUCCESS,
  payload,
})

const fetchContractorInfoStart = () => ({
  type: GET_CONTRACTOR_INFO_START
})

const fetchComplaintCardSuccess = (payload) => ({
  type: GET_COMPLAINTCARD_SUCCESS,
  payload,
})

const fetchComplaintCardStart = () => ({
  type: GET_COMPLAINTCARD_START,
})

const fetchComplaintCardFail = (payload) => ({
  type: GET_COMPLAINTCARD_FAIL,
  payload,
})

const fetchParentInfoByOrderIdSuccess = (payload) => ({
  type: GET_PARENT_INFO_BY_ORDERID_SUCCESS,
  payload,
})

const fetchParentInfoByOrderIdStart = () => ({
  type: GET_PARENT_INFO_BY_ORDERID_START,
})

const fetchParentInfoByOrderIdFail = (payload) => ({
  type: GET_PARENT_INFO_BY_ORDERID_FAIL,
  payload,
})

const fetchComplaintTopicsSuccess = (payload) => ({
  type: GET_COMPLAINT_TOPICS_SUCCESS,
  payload,
})

const fetchComplaintTopicsStart = () => ({
  type: GET_COMPLAINT_TOPICS_START,
})

const fetchComplaintTopicsFail = (payload) => ({
  type: GET_COMPLAINT_TOPICS_FAIL,
  payload,
})

const setComplaintDataSendSuccess = (payload) => ({
  type: SET_COMPLAINT_DATA_SEND_SUCCESS,
  payload,
})

const setComplaintDataSendStart = () => ({
  type: SET_COMPLAINT_DATA_SEND_START,
})

const setComplaintDataSendFail = (payload) => ({
  type: SET_COMPLAINT_DATA_SEND_FAIL,
  payload,
})

const getComplaintListSuccess = (payload) => ({
  type: GET_COMPLAINTLIST_SUCCESS,
  payload,
})

const getComplaintListStart = () => ({
  type: GET_COMPLAINTLIST_START,
})

const getComplaintListFail = (payload) => ({
  type: GET_COMPLAINTLIST_FAIL,
  payload,
})