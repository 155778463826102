import {getComplaintCard, 
        createComplaint, 
        joinFilesToMessage,
        addMessage,
        sendFiles,
        changeComplaintState} from "../../redux/actions/actionContractor";
import {UploadFile} from "../../utils/uploadFileType";
import {COMPLAINTCODES} from "../../utils/getComplaints";

export type HandlersType = {
    setComplaintTopicHandler: Function;
    sendPostMessageHandler: Function;
    setUploadFilesHandler: Function;
    setOpenControlPanelHandler: Function;
    setCloseControlPanelHandler: Function;
    setPostMessageHandler: Function;
    changeComplaintStateHandler: Function;
    changeComplaintStateToArbitrateHandler: Function;
    changeComplaintStateToEndHandler: Function;
    changeComplaintStateToSatisfiedHandler: Function;
    changeComplaintStateToQueryHandler: Function,
    changeComplaintStateToQueryInitHandler: Function;
    changeComplaintStateToQueryRespHandler: Function;
    changeComplaintStateToOverruleHandler: Function;
    changeComplaintStateToStopHandler: Function;
    changeComplaintStateToRestartHandler: Function;
    changeComplaintStateToResponsHandler: Function,
    changeComplaintStateToPartyResponsHandler: Function,
    setDowntimeDateHandler: Function;
}

export const GetHandlers = (dispatch: any,
                            setComplaintTopic: any, 
                            setOpenControlPanel: any, 
                            setUploadFiles: any, 
                            setPostMessage: any,
                            setDowntimeDate: any,
                            createId: any,
                            postMessage: any,
                            complaintTopic: any,
                            id: any,
                            complaintCardObject: any,
                            uploadFiles: any,
                            downtimeDate: any) => {
    const handlers: HandlersType = {
        setComplaintTopicHandler: (e: any) => {
            setComplaintTopic(parseInt(e?.target?.value));
        },
        sendPostMessageHandler: async () => {
            let complaintId;
            let messageId;
            const uploadResult = await dispatch(sendFiles(uploadFiles));
            if(createId){
                const createResult = await dispatch(createComplaint(createId, complaintTopic, postMessage));
                complaintId = createResult?.id;
                messageId = createResult?.messages[0].message?.id;
                if(messageId) await dispatch(joinFilesToMessage(messageId, uploadFiles, uploadResult));
            }else{
                complaintId = id;
                const addMessageResult = await dispatch(addMessage(complaintId, postMessage, uploadResult));
                messageId = addMessageResult?.message?.id;
            }

            handlers.setUploadFilesHandler([]);
            
            if(createId){
                window.location.replace("/complaint-card?id=" + complaintId);
            }
            if(complaintCardObject?.status === COMPLAINTCODES.START) handlers.changeComplaintStateToResponsHandler();
            if(complaintCardObject?.status === COMPLAINTCODES.QUERY) handlers.changeComplaintStateToPartyResponsHandler();
        },
        setUploadFilesHandler: (uploadFiles: UploadFile[]) => {
            setUploadFiles([...uploadFiles]);
        },
        setOpenControlPanelHandler: () => {
            setOpenControlPanel(true);
        },
        setCloseControlPanelHandler: () => {
            setOpenControlPanel(false);
        },
        setPostMessageHandler: (postMessage: string) => {
            setPostMessage(postMessage);
        },
        changeComplaintStateHandler: async (newState: number, 
                                            message:string = "", 
                                            date: string|null = null, 
                                            actionText: string = "") => {
            await dispatch(changeComplaintState(id, newState, actionText, message, date ? date : null));
            setOpenControlPanel(false);
            await dispatch(getComplaintCard(id));
        },
        changeComplaintStateToArbitrateHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.ARBITRATE, "", null, "Арбитраж");
        },
        changeComplaintStateToEndHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.END, postMessage, downtimeDate, "Закрыть");
        },
        changeComplaintStateToSatisfiedHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.SATISFIED, postMessage, downtimeDate, "Закрыть");
        },
        changeComplaintStateToQueryHandler: (actionText: string) => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.QUERY, postMessage, null, actionText);
        },
        changeComplaintStateToOverruleHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.OVERRULE, postMessage, "Отклонить");
        },
        changeComplaintStateToStopHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.STOP, postMessage, downtimeDate, "Удовлетворить");
        },
        changeComplaintStateToRestartHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.RESTART, postMessage, null, "Возобновить");
        },
        changeComplaintStateToResponsHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.RESPOND, "", null, "Дан ответ");
        },
        changeComplaintStateToPartyResponsHandler: () => {
            handlers.changeComplaintStateHandler(COMPLAINTCODES.PARTYRESPOND, "", null, "Дан ответ арбитру");
        },
        setDowntimeDateHandler: (e: any) => {
            const date = e.target.value;
            if(date) setDowntimeDate(date + "T00:00:00.000Z");
        },
        changeComplaintStateToQueryInitHandler: () => {
            handlers.changeComplaintStateToQueryHandler("Запрос инициатору");
        },
        changeComplaintStateToQueryRespHandler: () => {
            handlers.changeComplaintStateToQueryHandler("Запрос ответчику");
        },
    }
    
    return handlers;
}

