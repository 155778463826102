import {GET_BILLING_ERROR, GET_BILLING_START, GET_BILLING_SUCCESS} from "./billingActionTypes";

const initialState = {
  data: null,
  error: false,
  loading: false,
};

const reducer =  (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BILLING_START:
      return {...state, loading: true};

    case GET_BILLING_SUCCESS:
      return {...state, loading: false, data: payload};

    case GET_BILLING_ERROR:
      return {...state, loading: false, error: true};

    default:
      return { ...state };
  }
};

export default reducer;