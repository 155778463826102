import {ROLECODES} from "./getRoleName";

export const COMPLAINTCODES: any = {
  INIT:         0,   // Начало спора
  START:        42,  // Открыт
  RESPOND:      16,  // Дан ответ
  END:          44,  // Закрыт
  RESTART:      48,  // Возобновлён
  ARBITRATE:    43,  // Арбитраж
  QUERY:        68,  // Запрос от арбитра
  PARTYRESPOND: 69,  // Дан ответ арбитру
  STOP:         47,  // Приостановка работ
  OVERRULE:     45,  // Отклонён
  SATISFIED:    46   // Удовлетворён
}

export type ComplaintStatus = "initiate" | "start" | "respond" | "end" | "restart" | "arbitrate" | 
                              "query" | "party-respond" | "stop" | "overrule" | "satisfied" | "none";

export const getComplaintStatus = (complaintCode: number) : ComplaintStatus => {
  switch(complaintCode) {
    case COMPLAINTCODES.INIT:         return "initiate";
    case COMPLAINTCODES.START:        return "start";
    case COMPLAINTCODES.RESPOND:      return "respond";
    case COMPLAINTCODES.END:          return "end";
    case COMPLAINTCODES.RESTART:      return "restart";
    case COMPLAINTCODES.ARBITRATE:    return "arbitrate";
    case COMPLAINTCODES.QUERY:        return "query";
    case COMPLAINTCODES.PARTYRESPOND: return "party-respond";
    case COMPLAINTCODES.STOP:         return "stop";
    case COMPLAINTCODES.OVERRULE:     return "overrule";
    case COMPLAINTCODES.SATISFIED:    return "satisfied";
    default:                          return "none";
  }
}

export const getComplaintsTitle = (complaintCode: number): string => {
  switch(complaintCode) {
    case COMPLAINTCODES.INIT:         return "Начало спора";
    case COMPLAINTCODES.START:        return "Открыт";
    case COMPLAINTCODES.RESPOND:      return "Дан ответ";
    case COMPLAINTCODES.END:          return "Закрыт";
    case COMPLAINTCODES.RESTART:      return "Возобновлён";
    case COMPLAINTCODES.ARBITRATE:    return "Арбитраж";
    case COMPLAINTCODES.QUERY:        return "Запрос от арбитра";
    case COMPLAINTCODES.PARTYRESPOND: return "Дан ответ арбитру";
    case COMPLAINTCODES.STOP:         return "Приостановка работ";
    case COMPLAINTCODES.OVERRULE:     return "Отклонён";
    case COMPLAINTCODES.SATISFIED:    return "Удовлетворён";
    default:                          return "";
  }
}

type ComplaintSide = "initiator" | "responder" | "arbitrator";

export const getComplaintSide = (authorRole: number, respondentRole: number): ComplaintSide => {
    if(authorRole == ROLECODES.ARBITRATE) return "arbitrator";
    if(authorRole == respondentRole) return "responder";
    return "initiator";
}

type QuestionSide = "--to-initiator" | "--to-responder" | "--arbitrate" | "";

export const getQuestionSide = (message: any, complaintRespondent: number): QuestionSide => {
  if(message.authorRole !== ROLECODES.ARBITRATE) return "";
  if(message.respondentRole === complaintRespondent) return "--to-responder";
  if(message.respondentRole === message.authorRole) return "--arbitrate";
  return "--to-initiator";
}