import React, {useState} from 'react';
import {useSelector} from "react-redux";
import ObjectPageHead from "../ObjectPageHead/ObjectPageHead";
import styles from './Billing.module.scss';
import MutualHeader from "./components/MutualHeader/MutualHeader";
import PlanningHeader from "./components/PlanningHeader/PlanningHeader";
import MutualCaptions from "./components/MutualCaptions/MutualCaptions";
import PlanningCaptions from "./components/PlanningCaptions/PlanningCaptions";
import MutualList from "./components/MutualList/MutualList";
import PlanningList from "./components/PlanningList/PlanningList";
import {IBillingState} from "../../redux/billing/types";
import Invoices from "./components/Invoices/Invoices";

const TABS = [
  {id: 0, title: 'Взаиморасчеты'},
  {id: 1, title: 'Запланированные платежи'},
  {id: 2, title: 'Счета к оплате'},
];

interface BillingProps {
  billing: IBillingState
  startDate: Date | null
  endDate: Date | null
  onChangeStartDate: (date: Date | null) => void
  onChangeEndDate: (date: Date | null) => void
}

function Billing({billing, startDate, endDate, onChangeStartDate, onChangeEndDate}: BillingProps) {
  const [activeTab, setActiveTab] = useState<number>(TABS[0].id);
  const userRoleCode = useSelector<any>(state => state.userReducer.user?.role) as number;

  const handleChangeTab = (tab: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveTab(tab);
  };

  return (
    <div className={`${styles.billing} ${billing.loading ? styles.billingLoading : ''}`}>
      <ObjectPageHead title={`Список начислений (биллинг)`} roleCode={userRoleCode}/>
      <div className={styles.tabs}>
        {TABS.map(tab => (
          <button className={`${styles.tab} ${activeTab === tab.id ? styles.isActive : ''}`} onClick={handleChangeTab(tab.id)}>{tab.title}</button>
        ))}
      </div>
      <div className={styles.content}>
        {activeTab === TABS[0].id && (
          <>
            <MutualHeader
              billingData={billing.data}
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={onChangeStartDate}
              onChangeEndDate={onChangeEndDate}
            />
            <MutualCaptions/>
            <MutualList items={billing.data.billingsObjects}/>
          </>
        )}

        {activeTab === TABS[1].id && (
          <>
            <PlanningHeader billingData={billing.data}/>
            <PlanningCaptions/>
            <PlanningList items={billing.data.planBillings}/>
          </>
        )}

        {activeTab === TABS[2].id && (
          <Invoices billingData={billing.data}/>
        )}
      </div>
    </div>
  );
}

export default Billing;