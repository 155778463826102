import React from "react";
import { Link } from "react-router-dom";

const FooterList = ({buildings}: any) => {
  function getEstimateCount() {
    let count = 0;
    buildings?.forEach((el: any) => {
      count += el.estimates.length
    })
    return count
  }

  return (
    <footer>
      <Link className="link-light" to="/help" target="_blank">
        Помощь
      </Link>
      <div>
        <span className="symbol__colon--after">Объекты</span>
        <span>{buildings?.length}</span>
        <span className="symbol__vertical--before"></span>
        <span className="symbol__colon--after">Сметы</span>
        <span>{getEstimateCount()}</span>
      </div>
      <Link className="link-light" to="/home">
        Выход
      </Link>
    </footer>
  );
};

export default FooterList;
