import React, {useMemo, useState} from 'react';
import styles from './OrderRow.module.scss';
import {IOrderListItem} from '../../../../types/Order';
import {baseNotification, getDaysDiff, handleDate} from '../../../../utils/estimateUtils';
import {formatPrice} from '../../../../utils/formatPrice';
import {useDispatch, useSelector} from 'react-redux';
import {IStatus} from '../../../../types/Status';
import {setOrderStatus, updateOrderStatus} from '../../../../redux/ordersList/ordersListActions';
import {ORDER_STATUSES_WITH_COMPLAINT_BTN} from "../../../../constants/order";

interface OrderRowProps {
  order: IOrderListItem
}

function OrderRow({ order }: OrderRowProps) {
  const orderStatuses = useSelector<any>(state => state.ordersList.orderStatuses) as IStatus[];
  const [changingStatus, setChangingStatus] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const statusText = useMemo(() => {
    if (!orderStatuses || !orderStatuses.length) {
      return '';
    }

    const statusData = orderStatuses.filter(item => item.currentStateCode === order.status)?.[0];

    return statusData?.currentStateText || '';
  }, [orderStatuses]);

  const statusClassName = useMemo(() => {
    if (order.status === 39 || order.status === 37 || order.status === 57) {
      return styles.error;
    }

    if (order.status === 61 || order.status === 62) {
      return styles.warning;
    }

    return '';
  }, [order.status]);

  const actions = useMemo(() => {
    return orderStatuses.filter(item => item.currentStateCode === order.status && !!item.actionText);
  }, [order.status, orderStatuses]);

  const handleClickAction = (code: number, text: string) => (e: React.MouseEvent<HTMLElement>) => {
    if (changingStatus) {
      return;
    }

    setChangingStatus(true);

    dispatch(updateOrderStatus({
      entityId: order.id,
      newState: code,
      actionText: text,
      message: ''
    })).then((res: any) => {
      setChangingStatus(false);

      if (res.status === 200) {
        dispatch(setOrderStatus(order.id, code));
      } else {
        baseNotification("Произошла ошибка", "","danger");
      }
    }).catch((err: any) => {
      setChangingStatus(false);
      baseNotification("Произошла ошибка", "","danger");
    });
  }

  return (
    <div className={`${styles.orderRow} ${statusClassName} animate__clickable status__order--prepaid`}>
      <div className="limit__text--length">{order.buildingName}</div>
      <div className="limit__text--length">{order.buildingAddress}</div>
      <div>{order.estimateTitle}</div>
      <div>{order.orderName}</div>

      <div>
        <span>{handleDate(order.workStartPlan)}</span>
        <span className="symbol__mdash--before"></span>
        <span>{handleDate(order.workEndPlan)}</span>
      </div>

      <div className="mark__curr-ruble--after">{formatPrice(order.cost || 0)}</div>

      {/*'<!--*/}
      {/*  Нет Бригады:        (бригада не подобрана)*/}
      {/*  Статус «К запуску»: (подбор бригады)*/}
      {/*  Есть Бригада:		ФИО Бригадира*/}
      {/*-->'*/}
      <div className="limit__text--length">
        {order.brigadeName || (order.status === 40 ? '(подбор бригады)' : '(бригада не подобрана)')}
      </div>

      <div>
        <span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span>
        <span>дн.</span>
      </div>

      {/*'<!--*/}
      {/*  Статус.*/}
      {/*  Алгоритм вывода текста и кнопок определяется через селекторы CSS.*/}
      {/*-->'*/}
      <div>
        {/*'<!-- Статус (текст прописан во псевдоэлементе, CSS). -->'*/}
        <span className={styles.orderRowStatus}>{statusText}</span>

        {actions.map((btn, index) => (
          <>
            {ORDER_STATUSES_WITH_COMPLAINT_BTN.includes(btn.currentStateCode) ? (
              <a
                key={index}
                className="button bttn__small"
                title={btn.actionText}
                href={`/complaint-card?createId=${order.id}`}
              >
                {btn.actionText}
              </a>
            ) : (
              <button
                key={index}
                className="bttn__small"
                type="button"
                title={btn.actionText}
                onClick={handleClickAction(btn.actionCode, btn.actionText)}
                disabled={changingStatus}
              >
                {btn.actionText}
              </button>
            )}
          </>
        ))}


        {/*'<!-- Кнопки роли «Подрядчик». -->'*/}

        {/*'<!-- Только для статуса «Оферта». -->'*/}



        {/*'<!-- Кнопки роли «Прораб». -->'*/}

        {/*'<!-- Только для статусов «Утверждён» и «Просрочен запуск». -->'*/}
        {/*<button className="bttn__small" type="button" name="control__order--launch"*/}
        {/*        title="Готовность к подбору бригады и оплаты аванса">Запустить*/}
        {/*</button>*/}

        {/*'<!-- Только для статуса «Предоплачен» (договор заключён). -->'*/}
        {/*<button className="bttn__small" type="button" name="control__order--work" title="Работы по Наряду начаты">В*/}
        {/*  работе*/}
        {/*</button>*/}

        {/*'<!-- Только для статуса «В работе». -->'*/}
        {/*<button className="bttn__small" type="button" name="control__complaint--initiate"*/}
        {/*        title="Страница создания Спора">Начать спор*/}
        {/*</button>*/}

        {/*'<!-- Только для статуса «Спор». -->'*/}
        {/*<button className="bttn__small" type="button" name="control__complaint--end" title="Завершение Спора">Завершить*/}
        {/*  спор*/}
        {/*</button>*/}

        {/*'<!-- Только для статуса «Сдача работ». -->'*/}
        {/*<button className="bttn__small" type="button" name="control__order--work-repair"*/}
        {/*        title="Замечания по работам">Замечания*/}
        {/*</button>*/}
        {/*<button className="bttn__small" type="button" name="control__order--work-accept"*/}
        {/*        title="Принять выполненные работы">Принять*/}
        {/*</button>*/}
      </div>

      {/*'<!-- Споры. -->'*/}
      <div title="Всего / Нерешённые">
        <span>{order.complaintTotal || 0}</span>
        <span className="symbol__slash--before"></span>
        <span>{order.complaintOpen || 0}</span>
      </div>

      {/*'<!-- Ответчики. -->'*/}
      <div title="Подрядчик / Бригада">
        <span>Подр.</span>
        <span className="symbol__slash--before"></span>
        <span>Бриг.</span>
        <span>{order.foremanDowntime}</span>
        <span className="symbol__slash--before"></span>
        <span>{order.brigadeDowntime}</span>
      </div>

    </div>
  );
}

export default OrderRow;