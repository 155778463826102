import React from "react";
import { Link } from "react-router-dom";

export default function NoMatch() {
  return (
    <>
      <div
        style={{ gridColumn: "1/3", gap: "10px" }}
        className="flex flex-center"
      >
        <div>
          <h1>
            :( Запрашиваемая страница <br /> не найдена
          </h1>
        </div>
        <h1>
          <Link to={"/profile"}>На главную страницу</Link>
        </h1>
      </div>
    </>
  );
}
