import React from 'react';
import styles from './MutualHeader.module.scss';
import {IBilling} from "../../../../types/Billing";
import {formatPrice} from "../../../../utils/formatPrice";
import logo from "../../../../assets/escrow_vtb_logo2018.svg";
import DatePicker from "../../../DatePicker/DatePicker";

interface MutualHeaderProps {
  billingData: IBilling
  startDate: Date | null
  endDate: Date | null
  onChangeStartDate: (date: Date | null) => void
  onChangeEndDate: (date: Date | null) => void
}

function MutualHeader({billingData, startDate, endDate, onChangeStartDate, onChangeEndDate}: MutualHeaderProps) {
  return (
    <div className={styles.header}>

      <div className={styles.headerCaptions}>
        <div>Период</div>
        <div>Перечислено</div>
        <div>Получено</div>
      </div>

      <div className={styles.headerPeriod}>
        <div className="symbol__colon--after">С</div>
        <DatePicker
          value={startDate}
          onChangeDate={onChangeStartDate}
          className={styles.headerPeriodField}
        />
        <div className="symbol__colon--after">По</div>
        <DatePicker
          value={endDate}
          onChangeDate={onChangeEndDate}
          className={styles.headerPeriodField}
        />
      </div>

      <div className={styles.headerTransfers}>
        <div className="symbol__colon--after">Оплата Платформе</div>
        <div>{formatPrice(billingData.paidPlatform)}</div>
        <div className="symbol__colon--after">Аванс СМР (Платформа)</div>
        <div>{formatPrice(billingData.prePaidPlatform)}</div>
        <div className="symbol__colon--after">
          <i className={styles.logo}><img src={logo} alt="ВТБ"/></i>
          Аванс СМР (Эскроу)
        </div>
        <div>{formatPrice(billingData.prePaidEscrowPlatform)}</div>
        <div className="symbol__colon--after">Итого</div>
        <div>{formatPrice(billingData.paidTotal)}</div>
      </div>

      <div className={styles.headerReceipts}>
        <div className="symbol__colon--after">Услуги Платформы</div>
        <div>{formatPrice(billingData.actPlatform)}</div>
        <div className="symbol__colon--after">Закрыто СМР</div>
        <div>{formatPrice(billingData.actSmr)}</div>
        <div className="symbol__colon--after">Возвраты д/с СМР</div>
        <div>{formatPrice(billingData.actSmrBack)}</div>
        <div className="symbol__colon--after">Итого</div>
        <div>{formatPrice(billingData.actTotal)}</div>
      </div>

    </div>
  );
}

export default MutualHeader;