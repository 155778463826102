import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import styles from './estimateJob.module.scss';
import FooterList from "../../components/BuildingsList/FooterList";
import { EstimateManagement } from '../EstimateManagement/EstimateManagement';
import { IJobPlanState } from '../../redux/jobPlan/types';
import { getJobPlan } from '../../redux/jobPlan/jobPlanActions';
import JobPlanPage from '../JobPlanPage/JobPlanPage';
import estimateJobParams from './estimateJobParams';

const EstimateJob = () => {
  const { params } = useParams<any>();
  const jobPlan = useSelector<any>(state => state.jobPlan) as IJobPlanState;
  const user = useSelector<any>(state => state.userReducer.user) as any;
  const dispatch = useDispatch<any>();
  const { id, activeTabNum } = estimateJobParams(params);

  const TABS = [
    {id: 0, title: 'Смета'},
    {id: 1, title: 'Разбивка'},
  ];
  const [activeTab, setActiveTab] = useState<number>(TABS[0].id);

  useEffect(() => {
    if (id && user) {
      dispatch(getJobPlan(id));
    }
    setActiveTab(activeTabNum);
  }, [id, activeTabNum, user, dispatch]);

  const buildings = useSelector((state: any) => state.contractorReducer);
  const {buildingsObject} = buildings;

  const handleChangeTab = (tab: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveTab(tab);
  };

  const isTabsDisabled = !!jobPlan.error;

  return (
      <>
        <main>
          <div className={styles.tabs}>
            {TABS.map((tab, index) => (
              <button
                key={index}
                className={`${styles.tab} ${activeTab === tab.id ? styles.isActive : ''}`}
                onClick={handleChangeTab(tab.id)}
                disabled={tab.id !== 0 && isTabsDisabled}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className={styles.content}>
            {activeTab === TABS[0].id && (
              <EstimateManagement/>
            )}
            {activeTab === TABS[1].id && (
              <JobPlanPage/>
            )}
          </div>
        </main>
        <FooterList buildings={buildingsObject.buildings} />
      </>
  )
}

export {EstimateJob};