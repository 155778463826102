import {useState} from 'react';

interface ComplaintArbitratorParams{
    setCloseControlPanelHandler: any,
    setPostMessageHandler: any,
    changeComplaintStateToQueryInitHandler: any,
    changeComplaintStateToQueryRespHandler: any,
    changeComplaintStateToOverruleHandler: any,
    changeComplaintStateToSatisfiedHandler: any,
    changeComplaintStateToStopHandler: any,
    setDowntimeDateHandler: any,
}

const ComplaintArbitrator = (params: ComplaintArbitratorParams): JSX.Element => {
  const {setCloseControlPanelHandler, 
         setPostMessageHandler, 
         changeComplaintStateToQueryInitHandler,
         changeComplaintStateToQueryRespHandler,
         changeComplaintStateToOverruleHandler,
         changeComplaintStateToSatisfiedHandler,
         changeComplaintStateToStopHandler,
         setDowntimeDateHandler} = params; 
  const [disableQueryTab, setDisableQueryTab] = useState<boolean>(false);
  const [disableRulingTab, setDisableRulingTab] = useState<boolean>(false);

  /*
  TODO: make different onclick handlers for initiator/responder requests
  */
  return (
    <div className="manage-complaint__arbitrator">
  
        <input id="tab__query" name="control__tab" type="radio" value="" disabled={disableQueryTab}/>
        <label htmlFor="tab__query"><span>Запрос участникам</span></label>
  
        <input id="tab__ruling" name="control__tab" type="radio" value="" disabled={disableRulingTab}/>
        <label htmlFor="tab__ruling"><span>Решение Арбитра</span></label>

        <div id="content__query">
            <textarea placeholder="(введите текст сообщения)"
                      onChange={ e => {
                        setDisableRulingTab(e.target.value !== "");
                        setPostMessageHandler(e.target.value);
                      }}>
            </textarea>
            <button
                name="control__query-to-initiator"
                type="button"
                title="Отравить текст сообщения в адрес Инициатора"
                disabled={!disableRulingTab}
                onClick={changeComplaintStateToQueryInitHandler}
            >Запрос Инициатору</button>
            <button
                name="control__query-to-responder"
                type="button"
                title="Отравить текст сообщения в адрес Ответчика"
                disabled={!disableRulingTab}
                onClick={changeComplaintStateToQueryRespHandler}
            >Запрос Ответчику</button>
        </div>
        <div id="content__ruling">
            <textarea placeholder="(укажите основание решения)"
                      onChange={ e => {
                        setDisableQueryTab(e.target.value !== "");
                        setPostMessageHandler(e.target.value);
                      }}>
            </textarea>
            <button
                name="control__complaint--overrule"
                type="button"
                title="Завершение Спора в пользу Ответчика"
                disabled={!disableQueryTab}
                onClick={changeComplaintStateToOverruleHandler}
            >Спор отклонён</button>
            <button
                name="control__complaint--satisfied"
                type="button"
                title="Завершение Спора и окончание простоя"
                disabled={!disableQueryTab}
                onClick={changeComplaintStateToSatisfiedHandler}
            >Спор удовлетворён</button>
            <div className="block__downtime--end">
                <label className="symbol__colon--after">Окончание простоя</label>
                <input
                    name="input__downtime--end"
                    type="date"
                    title="Дата окончания простоя" 
                    onChange={setDowntimeDateHandler}/>
            </div>
            <button
                name="control__complaint--downtime"
                type="button"
                title="Продолжение Спора в пользу Инициатора"
                disabled={!disableQueryTab}
                onClick={changeComplaintStateToStopHandler}
            >Простой по договору</button>
            <div className="block__downtime--start">
                <label className="symbol__colon--after">Начало простоя</label>
                <input
                    name="input__downtime--start"
                    type="date"
                    title="Дата начала простоя"
                    onChange={setDowntimeDateHandler}/>
            </div>
        </div>
        <button
            name="control__manage-close"
            type="button"
            title="Сворачивание сектора без сохранения изменений"
            onClick={setCloseControlPanelHandler}
        >Отмена</button>
    </div>
  );
};

export default ComplaintArbitrator;