import React from "react";

function IconCalendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M4 5h2V3.75a1 1 0 112 0V5h8V3.75a1 1 0 112 0V5h2a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V7a2 2 0 012-2zm0 14v-8h16v8H4zM20 7v2H4V7h16z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IconCalendar;