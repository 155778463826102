import React, {useState} from 'react';
import styles from './BankAccounts.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {IProfileState} from "../../../../redux/profile/types";
import {IProfileBankAccount} from "../../../../types/Profile";
import CreateBankAccount from "./components/CreateBankAccount/CreateBankAccount";
import {saveBankAccount} from "../../../../redux/profile/profileActions";

enum AccountTypes {
  Default,
  Escrow,
  Base,
  Blocked
}

function BankAccounts() {
  const [createIsOpen, setCreateIsOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const dispatch = useDispatch<any>();

  const handleOpenCreate = () => {
    setCreateIsOpen(true);
  };

  const handleCloseCreate = () => {
    setCreateIsOpen(false);
  };

  const handleSelectRow = (id: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedRowId(id);
  };

  const handleChangeType = (account: IProfileBankAccount) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newData: IProfileBankAccount = Object.assign({}, account);

    switch (parseInt(e.target.value)) {
      case AccountTypes.Base:
        newData.isDefault = false;
        newData.isBlocked = false;
        newData.escrow = false;
        break;
      case AccountTypes.Default:
        newData.isDefault = true;
        newData.isBlocked = false;
        newData.escrow = false;
        break;
      case AccountTypes.Escrow:
        newData.isDefault = false;
        newData.isBlocked = false;
        newData.escrow = true;
        break;
      case AccountTypes.Blocked:
        newData.isDefault = false;
        newData.isBlocked = true;
        newData.escrow = false;
        break;
      default:
        return;
    }

    dispatch(saveBankAccount({
      ...newData
    }));
  };

  const renderTypeText = (account: IProfileBankAccount) => {
    if (account.escrow) {
      return (
        <span className={styles.accountRowTypeText}>
          <strong>Эскроу</strong>
        </span>
      );
    }

    if (account.isDefault) {
      return (
        <span className={styles.accountRowTypeText}>
          <strong>Основной</strong>
        </span>
      );
    }

    if (account.isBlocked) {
      return (
        <span className={styles.accountRowTypeText}>
          (заблокирован)
        </span>
      );
    }

    return (
      <span className={styles.accountRowTypeText}>
        (обычный)
      </span>
    );
  };

  const getAccountType = (account: IProfileBankAccount) => {
    if (account.escrow) {
      return AccountTypes.Escrow;
    }

    if (account.isDefault) {
      return AccountTypes.Default;
    }

    if (account.isBlocked) {
      return AccountTypes.Blocked;
    }

    return AccountTypes.Base;
  };

  return (
    <div className={styles.accounts}>
      <div className={styles.accountsCaptions}>
        <div>
          <span>Банк</span>

          <button
            className="bttn__small"
            name="bank__add"
            type="button"
            title="Добавление банковского счёта"
            onClick={handleOpenCreate}
          >
            Добавить
          </button>
        </div>
        <span>Расчётный счёт</span>
        <span>Корреспондентский счёт</span>
        <span>БИК</span>
        <span title="Тип меняется в выбранной строке">Тип</span>
      </div>


      {/*Данные: строки Счетов.*/}
      {/*Клик по строке делает её текущей.*/}

      {/*Строки счётов имеют класс типа счёта:*/}
      {/*bank__type--escrow — эскроу (может быть только один).*/}
      {/*bank__type--primary — основной (может быть только один).*/}
      {/*bank__type--normal — обычный.*/}
      {/*bank__type--locked — заблокирован.*/}

      {/*В колонке «Тип»:*/}
      {/*при наведении на строку текст офорамляется под стиль меню;*/}
      {/*при выборе строки текст меняется на меню.*/}
      {/*В меню выводятся пункты типов счетов (см. выше).*/}

      {/*Подразумевается, что при выборе пункта тип меняется сразу.*/}
      {/*При этом меняется класс строки на соответствующий выбранному типу.*/}

      {/*Подразумевается, что при смене счетов, имеющих текущий тип «Эскроу» или «Основной»,*/}
      {/*у них выставляется тип «обычный».*/}

      {/*Строки отсортированы по типу (сверху-вниз):*/}
      {/*Эскроу*/}
      {/*Основной*/}
      {/*(обычный)*/}
      {/*(заблокированный)*/}

      <div className={styles.accountsData}>
        {profile.data?.bankAccounts.map(account => (
          <div
            key={account.id}
            className={`${styles.accountRow} ${account.id === selectedRowId ? styles.selected : ''} ${account.isBlocked ? styles.locked : ''}`}
            title="Для выбора кликните по строке"
            onClick={handleSelectRow(account.id)}
          >
            <span>{account.bankName}</span>
            <span>{account.paymentAccount}</span>
            <span>{account.correspondentAccount}</span>
            <span>{account.bik}</span>

            <span className={styles.accountRowType}>
              {account.id === selectedRowId ? (
                <select value={getAccountType(account)} onChange={handleChangeType(account)}>
                  <option value={AccountTypes.Default}>Основной</option>
                  <option value={AccountTypes.Escrow}>Эскроу</option>
                  <option value={AccountTypes.Base}>(обычный)</option>
                  <option value={AccountTypes.Blocked}>(заблокирован)</option>
                </select>
              ) : renderTypeText(account)}
            </span>
          </div>
        ))}
      </div>

      {createIsOpen && (
        <CreateBankAccount
          onClose={handleCloseCreate}
        />
      )}
    </div>
  );
}

export default BankAccounts;