import React, {memo, useLayoutEffect, useState} from 'react';
import style from './requestBreakdown.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {
  saveEstimateDocument,
  setModal,
  updateStatusEstimate
} from "../../../redux/estimate/actionEstimate";
import {getInitialForeman} from "../../../utils/getInitialForeman";
import {handleDate} from "../../../utils/estimateUtils";

const StatusBar = memo((({data, employees, modeEstimator, setEditMode, setModalChange, filesAdded, id, modeStatus}: any) => {
  const [state, setState] = useState({
    status: "",
    calculationCp: handleDate(data.jobPlanDate) || '-',
    foreman: getInitialForeman(employees, data.foremanId),
    payment: data.prepayment || '-',
    breakdown: data.jobPlanId || '-',
    calculation: data.detailing === 0 ? '-' : data.detailing,
    sum: 0,
    hourlyRate: data?.hourlyRate || 0,
  });
  const [edit, setEdit] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const setNewStatus = (value: string, mode: boolean) => {
    setState({...state, status: value});
    modeStatus.visible = mode;
    setEdit(mode);
    setEditMode(mode);
  };

  const requestKP = (value: string, mode: boolean, status: number) => {
    updateStatus(status)
    setNewStatus(value, mode);
  }

  const updateStatus = (newStatus: any) => {
    if (newStatus === 35) {
      addBreakdown()
    } else if (newStatus !== 1) {
      const data = {
        entityId: id,
        newState: newStatus
      }
      dispatch(updateStatusEstimate(data))
    } else {
      setNewStatus('Дозапрос Данных', true)
    }
  }

  const saveDocument = async (value: string, mode: boolean) => {
    dispatch(saveEstimateDocument(filesAdded));
    // await dispatch(getBuildings());
    // dispatch(getEstimateById(id));
    setNewStatus(value, mode);
  };

  const addBreakdown = () => {
    dispatch(setModal(true));
  };

  const handleEstimateBtn = (status: any) => {
    const arrFlow: { newState: number, text: string }[] = [];
    if (modeEstimator) {
      modeEstimator.forEach((work: any) => {
        if (work.currentStateCode === status) {
          arrFlow.push({newState: work.actionCode, text: work.actionText});
        }
      })
    }
    return arrFlow;
  }

  useLayoutEffect(() => {
    setState({...state, status: modeStatus.currentStateText})
  }, [modeStatus]);

  return (
    <React.Fragment>
      <div className={style.container}>
        <div title="Статус Сметы/Разбивки">
          <span className={style.status}>{modeStatus.currentStateText}</span>
        </div>
        {edit &&
          <>
            <div>
              <button onClick={() => setModalChange(true)} style={{height: "32px"}}
                      className={`bttn__small ${style.button}`} type="button" name="requisites__edit">Изменить
                реквизиты…
              </button>
            </div>
            <div>
              <button onClick={() => setNewStatus(state.status, false)}
                      className={`bttn__small ${style.button}`}
                      type="button" name="estimate__edit--cancel">Отмена
              </button>
            </div>
            <div>
              <button onClick={() => saveDocument(state.status, false)}
                      className={`bttn__small ${style.button}`}
                      type="button" name="estimate__edit--save">Сохранить
              </button>
            </div>
          </>
        }
        {(state.status === 'Новая' && !edit) &&
          <>
            <div>
              <button onClick={() => setNewStatus('Новая', true)} className={`bttn__small ${style.button}`}
                      type="button"
                      name="estimate__edit">Редактировать…
              </button>
            </div>
            <div>
              <button onClick={() => requestKP('Запрос КП', false, modeStatus.actionCode)}
                      className={`bttn__small ${style.button}`}
                      type="button"
                      name="estimate__query">Запрос КП
              </button>
            </div>
          </>
        }
        {!edit && (modeStatus.currentStateText !== 'Новая') &&
          <>
            {modeStatus.visible &&
              <div className={style.buttonContainer}>
                {handleEstimateBtn(modeStatus.currentStateCode).map((item) =>
                  <button
                    className="bttn__small"
                    style={{width: '120px', height: '20px', border: '1px solid white'}}
                    onClick={() => updateStatus(item.newState)}>{item.text}
                  </button>
                )}
              </div>
            }
          </>
        }
        {!modeStatus.visible && 
        (modeStatus.currentStateText !== 'Новая') &&
        (modeStatus.currentStateText !== 'Дозапрос данных') &&
          <div>
            <span className={style.noChange}>изменение сметы недоступно</span>
          </div>
        }
        <div title="Дата готовности Расчёта КП"
             style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span className={style.calculationCp}>Дата расчета</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>
            {state.calculationCp}
          </span>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span className={style.foreman}>Прораб</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{state.foreman}</span>
        </div>

        <div className="sign__prepayment--escrow"
             style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span style={{fontSize: '12px', color: 'white'}}>Авансирование</span>
          <span style={{fontSize: '14px', color: 'white', fontWeight: 'bold'}}>{state.payment !== '-' ? state.payment === 1 ? 'Эскроу' : 'Платформа' : state.payment}</span>
        </div>

        {/*<div>
          <a className={style.breakdown} href={`/job-plan/${data.id}`} target="_blank"
             title="Карточка разбивки (переход)">{state.breakdown !== '-' ? "Разбивка" : state.breakdown}</a>
      </div>*/}
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <span style={{fontSize: '12px', color: 'white'}}>Вариант расчёта</span>
          {
            state.calculation !== '-' ? <select className={`${style.select} ${state.calculation === 1 ? 'selected' : ''}`}>
              <>
              {
                state.calculation === 1 ? <option value="validate">с проверкой</option> : <option selected={false} value="noValidate">без проверки</option>
              }
              </>
            </select> : <div style={{color: 'white'}}>-</div>
          }

        </div>

        <div>
          <div style={{display: 'flex', fontSize: '16px', fontWeight: 'bold', color: 'white', justifyContent: 'flex-end'}}
               title="Стоимость Сметы по варианту расчёта">{state.sum}
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <span style={{justifySelf: "end", fontFamily: 'thin', fontSize: "12px", color: 'white', marginRight: '4px'}} className="symbol__colon--after" title="Стоимость нормо-часа (руб./час)">Ставка</span>
            <span style={{fontSize: '12px', justifySelf: 'end', color: 'white'}}>{state.hourlyRate}</span>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}))

export {StatusBar};