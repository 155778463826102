import React from 'react';
import styles from './footer.module.scss';
import buildInfo from "../../buildinfo";
import Config from "../../config";
import axios from "axios"

const NewFooter = ({props}: any) => {
  const hideExitButton:boolean = props?.hideExitButton;
  const logoutHandler = () => {
    localStorage.clear();
  }
  const showAuthorHandler = async(e: any) => {
    if(e.ctrlKey){
      const result = await axios.get(Config.BACKEND_ADDRESS + "ping", {
        headers: {
        "Content-Type": "application/json",
        },
      });
      e.target.title = `Info: ${buildInfo.info}\r\nBack: ${Config.BACKEND_ADDRESS}\r\nPong: ${result.data}`;
    }
  }
  return (
    <footer
      className={styles.container}
      style={{display: 'flex', alignItems: 'inherit', height: 'auto'}}
    >
      <div className={`${styles.openFooter}`}
           style={{display: 'flex', justifyContent: 'space-between', padding: '0 100px', alignItems: 'center'}}>

        <a className="link-light" href="/help" target="_blank" title="Описание интерфейса">Интерфейс</a>

        <a className="link-light" href="//" target="_blank" title="Регламентация работы на сайте">Правила</a>

        <a className="link-light" href="//" target="_blank" title="Политика конфиденциальности">Конфиденциальность</a>

        <a className="link-light" href="//" target="_blank" title="Соглашение на обработку персональных данных">Персональные
          данные</a>

        <a className="link-light" href="//" target="_blank" title="Нормативная техническая документация">Нормативы</a>

        <a className="link-light" href="//" target="_blank" title="Переход на страницу Форума">Форум</a>

        <a className="link-light" href="//" target="_blank" title="Цели и задачи сайта">О сайте</a>

        <a className="link-light" href="//" target="_blank" title="Телефоны, почта и пр.">Контакты</a>

      </div>
      <div
        style={{display: 'flex', justifyContent: 'space-between', padding: '10px 100px'}}
        className={styles.footerStatic}
      >
        <a className="link-light" href="/help" target="_blank" title="Информация по тематическим разделам">Справка</a>

        <address onClick={showAuthorHandler}>@Platforma, Inc. 2022</address>
        <a className="link-light" href="/" onClick={logoutHandler} title="Выйти из учётной записи">{hideExitButton ? "" : "Выход"}</a>

      </div>

    </footer>
  )
}

export {NewFooter}