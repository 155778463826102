import axios from "axios";
import Config from "../../config";

export const SET_ERROR = "SET_ERROR";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS";

const setError = (payload) => ({
  type: SET_ERROR,
  payload
})

const setUser = (payload) => ({
  type: SET_USER_SUCCESS,
  payload
})

export const getUser = () => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("user"));

    const result = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetUserInfo`, {
      headers: {
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(setUser(result.data));

  } catch (e) {
    dispatch(setError(e));
  }
}