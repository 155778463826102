import React from 'react';
import style from "../../pages/EstimateManagement/Equipment/equipment.module.scss";

const ListEquipment = ({arr}: any) => {
  return (
      <div className={`group__list--system list__childs--highlight ${style.openContainer}`}>

        <div className={`${style.firstHeader} list__row--headers`}>
          <span style={{width: '50px', paddingLeft: '6px'}} title="Номер позиции по спецификации">№</span>
          <span style={{width: "464px"}}>Оборудование / Материалы</span>
          <span style={{width: '70px'}} title="Единица измерения">Ед. изм.</span>
          <span style={{width: '60px'}} title="Количество штук">Кол-во</span>
          <span style={{width: '260px'}}>Работа</span>
          <span style={{width: '80px'}} title="Цена работы">Цена</span>
          <span style={{width: '80px', display: 'flex', justifyContent: 'end', paddingLeft: '4px'}}
                title="Стоимость работ">Стоимость</span>
        </div>

        {arr.map((el: any) =>
            <div className={`list__row--equip-1 ${style.mapSubitems}`}>
              <span className={style.mapOneItem} style={{width: '50px', paddingLeft: '6px'}}>1.6</span>
              <span className={style.mapOneItem} style={{width: "464px"}}>{el.equipmentName}</span>
              <span className={style.mapOneItem} style={{width: '70px'}}>{el.unit}</span>
              <span className={style.mapOneItem} style={{width: '60px'}}>{el.count}</span>
              <span className={style.mapOneItem} style={{width: '260px'}}>{el.workName}</span>
              <span className={style.mapOneItem} style={{width: '80px'}}>{el.price}</span>
              <span className={style.mapOneItem} style={{width: '80px'}}>{el.price * el.count}</span>
            </div>)}
      </div>
  )
}

export { ListEquipment };