import React, {useMemo} from 'react';
import styles from './ParentDataBar.module.scss';
import {useSelector} from 'react-redux';
import {IStatus} from '../../../../types/Status';
import {handleDate} from '../../../../utils/estimateUtils';
import {IJobPlanData} from '../../../../types/JobPlan';

function ParentDataBar() {
  const jobPlan = useSelector<any>(state => state.order.jobPlan.jobPlan) as IJobPlanData;
  const estimateStatuses = useSelector<any>(state => state.order.estimateStatuses) as IStatus[];

  const statusText = useMemo(() => {
    if (!estimateStatuses) {
      return '';
    }

    const status = estimateStatuses.filter(item => item.currentStateCode === jobPlan.status)?.[0];

    if (status) {
      return status.currentStateText;
    }
  }, [estimateStatuses, jobPlan.status]);

  return (
    <div className={styles.bar}>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Карточка объекта">{jobPlan.buildingName}</a>
      </div>

      <div className="limit__text--length">{jobPlan.buildingAddress}</div>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Страница Сметы">{jobPlan.estimateTitle}</a>
      </div>

      <div>
        <span className="symbol__colon--after"><a className="link-light" href="/_" title="Страница Разбивки">Статус</a></span>
        <span>{statusText}</span>
      </div>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Карточка подрядчика">{jobPlan.contractorName}</a>
      </div>

      <div>
        <span>{jobPlan.contractorPhone}</span>
        {/*<span className="limit__text--length">Подрядчиков Ю. Л.</span>*/}
      </div>

      <div>
        <span title="Даты: Начало — Окончание">Плановые сроки</span>
        <span>{jobPlan.workStartPlan ? handleDate(jobPlan.workStartPlan) : '.. ... ..'}</span>
        <span className="symbol__mdash--before"></span>
        <span>{jobPlan.workEndPlan ? handleDate(jobPlan.workEndPlan) : '.. ... ..'}</span>
      </div>

    </div>
  );
}

export default ParentDataBar;