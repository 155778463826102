import React, {useState} from 'react';
import styles from './AccountData.module.scss';
import {useSelector} from "react-redux";
import {IProfileState} from "../../../../redux/profile/types";
import HourlyRate from "./components/HourlyRate/HourlyRate";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ChangeData from "./components/ChangeData/ChangeData";
import {IProfileData} from "../../../../types/Profile";

function AccountData() {
  const [hourlyRateOpen, setHourlyRateOpen] = useState<boolean>(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [changeDataOpen, setChangeDataOpen] = useState<boolean>(false);
  const profile = useSelector<any>(state => state.profile) as IProfileState;

  const renderValue = (value: string | number | undefined) => {
    if (value) {
      return value;
    } else {
      return (
        <span className={styles.noValue}>(не указано)</span>
      );
    }
  };

  const handleOpenHourlyRate = () => {
    setHourlyRateOpen(true);
  };

  const handleCloseHourlyRate = () => {
    setHourlyRateOpen(false);
  };

  const handleOpenChangeData = () => {
    setChangeDataOpen(true);
  };

  const handleCloseChangeData = () => {
    setChangeDataOpen(false);
  };

  const handleOpenChangePassword = () => {
    setChangePasswordOpen(true);
  };

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
  };

  let data = profile.data;
  const storageData = localStorage.getItem('profileChangeData');

  if (storageData) {
    const storageParsedData = JSON.parse(storageData) as IProfileData;

    if (storageParsedData.contractor.id === profile.data?.contractor.id) {
      data = storageParsedData;
    }
  }

  return (
    <div className={styles.accountData}>
      <div className={styles.col1}>
        <section className={styles.colSection}>
          <h6>Аккаунт</h6>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Логин</span>
            <span>{renderValue(data?.responsible.login)}</span>
          </div>
          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Телефон</span>
            <span>{renderValue(data?.responsible.phone)}</span>
          </div>
          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">E-mail</span>
            <span>{renderValue(data?.contractor.email)}</span>
          </div>
        </section>

        <section className={styles.colSection}>
          <h6>Ответственный от организации</h6>

          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Фамилия</span>
            <span>{renderValue(data?.responsible.lastName)}</span>
          </div>
          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Имя</span>
            <span>{renderValue(data?.responsible.firstName)}</span>
          </div>
          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Отчество</span>
            <span>{renderValue(data?.responsible.secondName)}</span>
          </div>
        </section>

        <section className={styles.colSection}>
          <h6>Ставка нормо-часа</h6>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Рекомендуемая</span>
            <span>{renderValue(data?.recomendedHourlyRate)}</span>
          </div>
          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after"
                  title="Устанавливается по кнопке «Изменить ставку…»">Подрядчика</span>
            <span>{renderValue(data?.contractor.hourlyRate)}</span>
          </div>
        </section>
      </div>

      <div className={styles.col2}>
        <section className={styles.colSection}>
          <h6>Реквизиты организации</h6>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Наименование</span>
            <span>{renderValue(data?.contractor.name)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">ИНН</span>
            <span>{renderValue(data?.contractor.inn)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">КПП</span>
            <span>{renderValue(data?.contractor.kpp)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">ОКПО</span>
            <span>{renderValue(data?.contractor.okpo)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">ОГРН</span>
            <span>{renderValue(data?.contractor.ogrn)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">ОКТМО</span>
            <span>{renderValue(data?.contractor.oktmo)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Адрес (юр.)</span>
            <span>{renderValue(data?.contractor.legalAddress)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Адрес (факт.)</span>
            <span>{renderValue(data?.contractor.actualAddress)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Совпадает с юр.</span>
            {data?.contractor.legalAddress === data?.contractor.actualAddress ? (
              <span className={styles.check}></span>
            ) : (
              <span/>
            )}
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Телефон офиса</span>
            <span>{renderValue(data?.contractor.phone)}</span>
          </div>
        </section>
      </div>

      <div className={styles.col3}>
        <section className={styles.colSection}>
          <h6>Подписант</h6>

          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Фамилия</span>
            <span>{renderValue(data?.contractor.signatoryLastName)}</span>
          </div>

          <div className={`${styles.colSectionItem} ${styles.noMargin}`}>
            <span className="symbol__colon--after">Имя</span>
            <span>{renderValue(data?.contractor.signatoryFirstName)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Отчество</span>
            <span>{renderValue(data?.contractor.signatorySecondName)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Должность</span>
            <span>{renderValue(data?.contractor.signatoryPost)}</span>
          </div>

          <div className={styles.colSectionItem}>
            <span className="symbol__colon--after">Основание</span>
            <span>{renderValue(data?.contractor.signatoryReason)}</span>
          </div>
        </section>
      </div>

      <div className={styles.bottom}>
        <button
          className="symbol__hellip--after"
          name="pass__changing"
          type="button"
          title="Открыть окно смены пароля"
          onClick={handleOpenChangePassword}
        >
          Смена пароля
        </button>

        <button
          className="symbol__hellip--after"
          type="button"
          title="Открыть окно изменения ставки"
          onClick={handleOpenHourlyRate}
        >
          Изменить ставку
        </button>

        {data?.contractor.status === 71 ? (
          <button
            className="symbol__hellip--after"
            name="registering__data-entry"
            type="button"
            title="Открыть окно ввода данных"
            onClick={handleOpenChangeData}
          >
            Ввод данных
          </button>
        ) : (
          <span/>
        )}
      </div>

      {hourlyRateOpen && (
        <HourlyRate onClose={handleCloseHourlyRate}/>
      )}

      {changePasswordOpen && (
        <ChangePassword onClose={handleCloseChangePassword}/>
      )}

      {changeDataOpen && (
        <ChangeData onClose={handleCloseChangeData}/>
      )}
    </div>
  );
}

export default AccountData;