import {
    GET_INVITE_REGISTRATION_FAIL,
    GET_INVITE_REGISTRATION_START,
    GET_INVITE_REGISTRATION_SUCCESS
} from "./authActions";

const initialState = {
    error: null,
    data: null,
    isLoading: false
}

const authReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_INVITE_REGISTRATION_START:
            return {
                ...state,
                isLoading: true
            };
        case GET_INVITE_REGISTRATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload
            };
        case GET_INVITE_REGISTRATION_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

export default authReducer