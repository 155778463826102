import Config from "../../config.js";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import lineart from "../../assets/lineart.svg";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { NewFooter } from "../../components/footer/NewFooter";
import "../../styles/na_login.css";
import {getUser} from "../../redux/user/actionUser";
import {useDispatch} from "react-redux";

interface State {
  username: string;
  password: string;
  code: string;
  mode: string;
  showPassword: boolean;
  remember: boolean;
}

export default function Login(): JSX.Element {
  const history = useHistory();
  const [values, setValues] = useState<State>({
    username: "",
    password: "",
    code: "",
    mode: "password",
    showPassword: false,
    remember: false,
  });
  const tokenLifetimeDefault = 7;
  // const [alert, setAlert] = useState<JSX.Element>(<></>)

  const [usernameError, setUsrnmErr] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [smsError, setSMSError] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  async function submit(e: SyntheticEvent) {
    e.preventDefault();
    const login = values.username;
    const password = values.password;
    const code = values.code;
    const tokenLifetime = values.remember ? tokenLifetimeDefault : 0;
    const response = await fetch(Config.BACKEND_ADDRESS + "api/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // credentials: "include",
      body: JSON.stringify({
        login,
        password,
        code,
        tokenLifetime
      }),
    });
    const result = await response.json();
    if (response.ok) {
      localStorage.setItem("user", JSON.stringify(result));
      dispatch(getUser());
      history.push("/profile");
    } else {
      if (values.password) {
        setPassError(true);
      } else if (values.code) {
        setSMSError(true);
      }
    }
  }

  // async function checkUsername() {
  // 	const username = values.username
  // 	const response = await fetch(Config.BACKEND_ADDRESS + 'api/auth/checkUsername', {
  // 		method: 'POST',
  // 		headers: {'Content-Type': 'application/json'},
  // 		credentials: "include",
  // 		body: JSON.stringify({
  // 				username,
  // 			},
  // 		)
  // 	});
  // 	setUsrnmErr(!response.ok)
  // 	return response
  // }

  async function sendSMS(e: SyntheticEvent) {
    e.preventDefault();
    // checkUsername().then(async (response) => {
    // if (response.ok) {
    const login = values.username;
    const code = values.code;
    const response = await fetch(Config.BACKEND_ADDRESS + "api/auth/sendsms", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        login,
        code,
      }),
    });
    // }
    // })
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleRadioChange =
    (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  // const handleClickShowPassword = () => {
  // 	setValues({
  // 		...values,
  // 		showPassword: !values.showPassword,
  // 	});
  // };
  //
  // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  // 	event.preventDefault();
  // };

  const usernameSpellingError = ((): boolean => {
    if (values.username) {
      const re = /^[a-zA-Z0-9]*$/;
      if (re.test(values.username)) {
        return values.username.length < 6;
      } else {
        return true;
      }
    } else {
      return false;
    }
  })();
  const passSpellingError = ((): boolean => {
    if (values.password) {
      const re = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
      if (re.test(values.password)) {
        return values.password.length < 8;
      } else {
        return true;
      }
    } else {
      return false;
    }
  })();
  const codeSpellingError = values.code ? values.code.length < 6 : true;

  let element = document.getElementById("root");
  if (element !== null) {
    element.classList.value = "page__login non-authorized";
  }

  return (
    <>
      <main>
        <h2>Вход в систему</h2>

        <form name="account__modes--switches">
          <h3>
            Режим
            <br />
            входа
          </h3>
          <label className="clickable">
            <input
              type="radio"
              name="mode-switch"
              value="password"
              defaultChecked
              onChange={handleRadioChange("mode")}
            />
            Пароль
          </label>
          <label className="clickable">
            <input
              type="radio"
              name="mode-switch"
              onChange={handleRadioChange("mode")}
              value="sms"
            />
            Код СМС
          </label>
        </form>

        {values.mode === "password" && (
          <form name="account__mode--pass">
            <h3>Логин</h3>
            <input
              type="text"
              name="login"
              className={usernameError ? "error__box" : ""}
              onChange={handleChange("username")}
              value={values.username}
              // onBlur={() => {
              // 	checkUsername()
              // }}
              placeholder="(не указано)"
            />

            <h3>Пароль</h3>
            <input
              type="password"
              name="pass"
              placeholder="(не указано)"
              className={passError ? "error__box" : ""}
              onChange={handleChange("password")}
              disabled={!values.username || usernameSpellingError}
            />

            <h3>Авторизация</h3>
            
            <label htmlFor="remember"><input
              id = "remember"
              type="checkbox"
              name="remember"
              onChange={handleChange("remember")}></input>Помнить 7 дней</label>

            <button
              disabled={
                !values.password || passSpellingError || usernameSpellingError
              }
              onClick={submit}
            >
              Войти
            </button>
          </form>
        )}
        {values.mode === "sms" && (
          <form className="account__mode--sms">
            <h3>Логин</h3>
            <input
              type="text"
              name="login"
              className={usernameError ? "error__box" : ""}
              onChange={handleChange("username")}
              value={values.username}
              placeholder="(не указано)"
            />

            <h3>Получение кода</h3>
            <button
              disabled={!values.username || usernameSpellingError}
              onClick={sendSMS}
            >
              Отправить в СМС
            </button>

            <h3>Код из СМС</h3>
            <input
              type="text"
              name="sms"
              placeholder="(не указано)"
              onChange={handleChange("code")}
              disabled={false}
            />

            <button disabled={codeSpellingError} onClick={submit}>
              Войти
            </button>
          </form>
        )}

        <div className="pic__back">
          <img src={lineart} alt="Фоновая картинка" />
        </div>

        <div
          className={
            usernameError || passError ? "info__box error__text" : "info__box"
          }
        >
          {!passError && !usernameError && (
            <>
              <h3>Еще не зарегистрированы?</h3>
              <p>
                <Link to="/register" title="Страница регистрации">
                  Зарегистрироваться!
                </Link>
              </p>
            </>
          )}
          {usernameError && (
            <>
              <h3>Незарегистрированный логин</h3>
              <p>
                Возможно, вы допустили опечатку.
                <br />
                Если вы ещё не регистрировались, перейдите на страницу
                <Link to="/register" title="Страница регистрации">
                  {" "}
                  регистрации
                </Link>
                .
              </p>
            </>
          )}
          {passError && (
            <>
              <h3>Неверный пароль</h3>
              <p>
                Возможно, вы допустили опечатку.
                <br />
                Если забыли пароль, войдите по коду СМС.
              </p>
            </>
          )}
          {smsError && (
            <>
              <h3>Неверный код</h3>
              <p>
                Код не соответствует отправленному в СМС!
                <br />
                Введите код из СМС или
                <a href="/_" title="Отправка нового кода">
                  запросите новый код
                </a>
                .
              </p>

              <h3>СМС не приходит</h3>
              <p>Отправить код повторно.</p>
            </>
          )}
        </div>
      </main>
      <NewFooter props = {{hideExitButton: true}}/>
    </>
  );
}
