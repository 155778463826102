import style from "../Specification/specification.module.scss";
import React, {useEffect, useState} from "react";
import {ListFiles} from "../../../components/EstimateManagement/ListFiles";
import {useFilter} from "../../../hooks/useFilter";

const acceptedCodes = [33, 34]

const Contract = (props: any) => {
  const [open, setOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const type = 5;

  useEffect(() => {
    if (acceptedCodes.find((el) => el === props.estiamateCode)) {
      setAccept(true);
    }
  }, [props.estiamateCode])

  const openInfo = () => {
    if (acceptedCodes.find((el) => el === props.estiamateCode)) {
      setOpen(!open);
    }
  }

  const arr = useFilter(props.estimate.documents, 5, 'type')

  return (
      <React.Fragment>
        <div className={`section__files--spec ${style.size} ${open ? "open" : ""}`}>
          <div className={`${!accept ? style.notAccept : open ? style.activeContainer : style.container}`}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span className={`circle-triangle ${!accept ? style.noAccessSpan : ''}`} onClick={openInfo} title="Развернуть / Свернуть"></span>
              <span style={{marginLeft: '8px'}}>Договор</span>
            </div>
            <div>
              <span className={`symbol__colon--after`}>Файлов</span>
              <span style={{marginLeft: '12px', fontWeight: 'bold'}}>{arr.length}</span>
            </div>
          </div>
          {open && <ListFiles arr={arr} edit={props.state} type={type}/>}
        </div>
      </React.Fragment>
  )
}

export {Contract}