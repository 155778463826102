import axios from 'axios';
import Config from '../../config';
import {GET_ORDER_ERROR, GET_ORDER_START, GET_ORDER_SUCCESS} from './orderActionTypes';

const getOrderStart = () => ({
  type: GET_ORDER_START
});

const getOrderSuccess = (payload) => ({
  type: GET_ORDER_SUCCESS,
  payload,
});

const getOrderError = () => ({
  type: GET_ORDER_ERROR
});

export const getOrder = (id) => async (dispatch) => {
  dispatch(getOrderStart());

  try {
    const token = JSON.parse(localStorage.getItem("user"));

    const order = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetOrderById?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const orderStatuses = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetOrderWorkFlow?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const estimateStatuses = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetEstimateWorkFlow?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const complaintStatuses = await axios.get(Config.BACKEND_ADDRESS + `Estimator/GetComplaintWorkFlow?Id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const jobPlan = await axios.get(Config.BACKEND_ADDRESS + `Foreman/GetJobPlanByEstimateId?Id=${order.data.estimateId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    const documentTypes = await axios.get(Config.BACKEND_ADDRESS + `Contractor/GetDocumentTypes`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(getOrderSuccess({
      order: order.data,
      jobPlan: jobPlan.data,
      estimateStatuses: estimateStatuses.data,
      orderStatuses: orderStatuses.data,
      documentTypes: documentTypes.data,
      complaintStatuses: complaintStatuses.data,
    }));
  } catch (e) {
    dispatch(getOrderError());
  }
};