import {COMPLAINTCODES, getComplaintsTitle} from "../../utils/getComplaints";
import {ROLECODES} from "../../utils/getRoleName";
import {ListCaptionItem} from "./Types";
import DataRangePicker from "../DateRangePicker/DateRangePicker";

interface FilterPanelParams {
	listCaptions: ListCaptionItem[];
	changeHandler: any,
	complaintTopics: any,
	dateFrom?: Date,
	dateTo?: Date,
}

const TextInput = (captionItem: ListCaptionItem, changeHandler: any): JSX.Element => {
  return(<input
	className="live-search"
	key={captionItem.captionId}
	name={`filter__${captionItem.captionId}`}
	type="text"
	placeholder="(начните вводить текст)" 
	onChange={changeHandler}/>);
}

const StatusInput = (captionItem: ListCaptionItem, changeHandler: any): JSX.Element => {
  const statusItems = Object.keys(COMPLAINTCODES); 
  statusItems.splice(statusItems.indexOf("INIT"), 1); 
  const statusArr = statusItems.map(status => {return {"status": status, "title": getComplaintsTitle(COMPLAINTCODES[status])}});
  return(		
	<select name={`filter__${captionItem.captionId}`} onChange={changeHandler} className="selected">
		<option value="all-statuses" key="all" selected>(все)</option>
		{statusArr.map(statusItem => (
			<option value={COMPLAINTCODES[statusItem.status]} 
					key={statusItem.status} 
					className={["ARBITRATE","STOP"].includes(statusItem.status) ? "important--item" : ""}>
					{statusItem.title}
			</option>	
		))}
	</select>
  );
}

const ComplaintTopicInput = (captionItem: ListCaptionItem, changeHandler: any, complaintTopics: any): JSX.Element => {
	if(!complaintTopics) return(<></>);
	const foremanComplaints = complaintTopics[0]?.items;
	const brigadireComplaints = complaintTopics[1]?.items;
	if(!foremanComplaints || !brigadireComplaints) return (<></>);
	const allComplaints = foremanComplaints.concat(brigadireComplaints);
	return(		
		<select name={`filter__${captionItem.captionId}`} onChange={changeHandler} className="selected">
			<option value="all-buildings" key="all" selected>(все)</option>
			{allComplaints.map((complaint: any) => (
				<option value={complaint.id} 
						key={complaint.id}>
						{complaint.name}
				</option>	
			))}
		</select>
	);
}


const PeriodInput = (captionItem: ListCaptionItem, 
					 dateFrom: Date | undefined, 
					 dateTo: Date | undefined, 
					 changeHandler: any): JSX.Element => {
  return DataRangePicker({className: `date-picker--${captionItem.captionId}`, 
  						  startDate: dateFrom ? dateFrom : null, 
						  endDate: dateTo ? dateTo : null, 
						  onChangeDates: changeHandler})
}
 
const ResponderInput = (captionItem: ListCaptionItem, changeHandler: any): JSX.Element => {
  	return(
  		<select name={`filter__${captionItem.captionId}`} onChange={changeHandler} className="selected">
  			<option value="all-statuses" selected>(все)</option>
  			<option value={ROLECODES.FOREMAN}>Подрядчик</option>
  			<option value={ROLECODES.BRIGADIER}>Бригада</option>
		</select>
	);
}

const FilterPanel = (params: FilterPanelParams): JSX.Element => {
  const {listCaptions, changeHandler, complaintTopics, dateFrom, dateTo} = params;
  return (
	<div className="bar__filters">
		{listCaptions.map((captionItem: ListCaptionItem) => {
			switch(captionItem.filtertype){
				case "text": return TextInput(captionItem, changeHandler);
				case "status": return StatusInput(captionItem, changeHandler);
				case "complaintTopic": return ComplaintTopicInput(captionItem, changeHandler, complaintTopics);
				case "period": return PeriodInput(captionItem, dateFrom, dateTo, changeHandler);
				case "responder": return ResponderInput(captionItem, changeHandler);
				default: return (<></>);
			}
		})}
	</div>
  );
};
export default FilterPanel;