import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {saveEstimateSuccess, setModalChangeRequisites} from "../../../../redux/estimate/actionEstimate";
import ReactDOM from "react-dom";
import {ModalEstimateType} from "../ModalTypeEstimate/ModalEstimateType";
import moment from "moment";
import {parseDate} from "../../../../utils/estimateUtils";

interface Value {
  id: string;
  title: string;
  typeId: number;
  workStartPlan: string;
  workEndPlan: string;
  note: string;
  prepayment: number;
  detailing: number;
  foremanId: string,
  bankAccountId: string,
}

const initialState = { id: 0, name: '', code: '0', items: null }

const ModalChangeRequisites = ({isOpen, setModal}: any) => {
  const estimate = useSelector((state: any) => state.estimateReducer.estimate.info);
  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes);
  const [selectedTypeValue, setSelectedTypeValue] = useState(initialState);
  const dispatch = useDispatch<any>();
  const [valid, setValid] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<Value>({
    id: estimate.id,
    title: estimate.title,
    typeId: selectedTypeValue?.id,
    workStartPlan: '',
    workEndPlan: '',
    note: estimate.estimateNote,
    prepayment: estimate.prepayment,
    detailing: estimate.detailing,
    foremanId: estimate.foremanId,
    bankAccountId: estimate.bankAccountId,
  })

  const getInitialType = () => {
    let item = initialState
    if (estimateTypes?.length) {
      for (let i = 0; i < estimateTypes.length; i++) {
        if (estimateTypes[i]?.items?.find((el: any) => el.name === estimate.types)) {
          item = estimateTypes[i]?.items?.find((el: any) => el.name === estimate.types)
          setValues({...values, typeId: item?.id})
          setSelectedTypeValue(item);
        }
      }
    }
  }

  useEffect(() => {
    getInitialType();
  }, [estimateTypes]);

  let element = document.getElementById("root");
  if (element !== null && isOpen) {
    element.classList.value = "page__estimate--card role__contractor status__estimate--new mode__edit modal__open--creating-estimate";
  }

  const saveEstimate = () => {
    dispatch(saveEstimateSuccess(values))
    setModal(false);
  }

  useEffect(() => {
    setValues({...values, typeId: selectedTypeValue?.id});
  }, [selectedTypeValue]);

  const setNewValue = (e: any) => {
    const {name, value} = e.target;
    if (values.hasOwnProperty(name)) {
      if (name === 'workEndPlan' || name === 'workStartPlan') {
        setValues({
          ...values,
          [name]: new Date(value).toISOString()
        })
      } else  {
        setValues({
          ...values,
          [name]: value,
        })
      }
    }

    const currErrors = {...valid}
    switch (name) {
      case 'title':
        currErrors[name] = !!value.trim();
        break;
      case 'workStartPlan':
      case 'workEndPlan':
        currErrors[name] = moment(value, 'DD.MM.YYYY', true).isValid();
        break;
      default:
        return;
    }
    setValid(currErrors);
  }

  useEffect(() => {
    const {workStartPlan, workEndPlan} = values;
    if (workEndPlan && workStartPlan){
      setValid({
        ...valid,
        // @ts-ignore
        workData: moment(parseDate(workEndPlan)).isAfter(parseDate(workStartPlan), [
          "day",
          "month",
          "year"
        ])
      })
    }
  }, [values.workStartPlan, values.workEndPlan])

  if (!isOpen) return null;
  return ReactDOM.createPortal(
      <div id="modal__box">
        <div className="modal__back"/>
        <div className="modal__window creating__estimate">

          <h3>Реквизиты Сметы</h3>

          <div>
            Реквизиты можно менять только в статусе <span className="symbol__quotes">Новая</span>.
          </div>
          <form>

            <label>Название</label>
            <input name="title" type="text" onChange={setNewValue} value={values.title}/>

            <label>Примечание</label>
            <input name="note" type="text" onChange={setNewValue} value={values.note}/>

            <label><span onClick={() => setOpen(true)} className="control__adding--estimate-type">Тип сметы</span></label>
            <input onChange={setNewValue} type="text" value={selectedTypeValue?.name}/>

            <label>Плановые сроки</label>
            <label className="symbol__colon--after">Старт</label>
            <input name="workStartPlan" type="date" onChange={setNewValue}
                   value={values.workStartPlan.split('T')[0]} title="Дата начала"/>
            <label className="symbol__colon--after">Финиш</label>
            <input name="workEndPlan" type="date" onChange={setNewValue} value={values.workEndPlan.split('T')[0]}
                   title="Дата окончания"/>

            <button type="button" onClick={saveEstimate} className="control__modal--close">Сохранить</button>
            <button onClick={() => setModal(false)} type="button" className="control__modal--close">Отмена</button>

          </form>
          <ModalEstimateType
            setSelectedTypeValue={setSelectedTypeValue}
            selectedTypeValue={selectedTypeValue}
            types={estimateTypes}
            isOpen={open}
            setOpen={setOpen}
          />
        </div>
      </div>,
      document.body
  )
}

export {ModalChangeRequisites};