import React, {useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import "./header.scss";
import styles from './header.module.scss';
import {ModalObject} from "../BuildingsList/Modals/ModalObject";
import {useDispatch, useSelector} from "react-redux";
import {ModalEstimateType} from "../../pages/EstimateManagement/StatusBar/ModalTypeEstimate/ModalEstimateType";
import {IProfileState} from "../../redux/profile/types";

const generateTabs = (
  buildings: number,
  orders: number,
  complaints: number,
  billingCount: number
) => {
  return [
    {
      id: 0,
      path: "/list-contractor",
      name: "Объекты",
      notifications: buildings,
    },
    {
      id: 1,
      path: "/orders",
      name: "Наряды",
      notifications: orders,
    },
    {
      id: 2,
      path: "/complaint-list",
      name: "Споры",
      notifications: complaints,
    },
    {
      id: 3,
      path: "/billing",
      name: "Биллинг",
      notifications: billingCount,
    },
  ];
};

export default function Header({
                                 isList = false,
                                 tab = -1,
                                 buildingsCount = 0,
                                 ordersCount = 0,
                                 complaintsCount = 0,
                                 billingCount = 0,
                               }): JSX.Element {
  const buildings = useSelector((state: any) => state.contractorReducer.buildingsObject);
  const [login, setLogin] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTypes, setModalTypes] = useState(false);
  const dispatch = useDispatch<any>()
  const location = useLocation();
  const [selectedTypeValue, setSelectedTypeValue] = useState({});
  const profile = useSelector<any>(state => state.profile) as IProfileState;

  const tabs = useMemo(
    () => generateTabs(buildings.buildings?.length || buildingsCount, ordersCount, complaintsCount, billingCount),
    [buildingsCount, ordersCount, complaintsCount, billingCount, buildings]
  );

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/invite') {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [login, dispatch, location]);

  const estimateTypes = useSelector((state: any) => state.contractorReducer.estimateTypes);
  const user = useSelector((state: any) => state.userReducer.user);
  const contractorInfo = useSelector((state: any) => state.contractorReducer.contractorInfo);

  const upperFirstLetter = (word: string) => {
    return word ? word[0]?.toUpperCase() : "";
  }

  const contractor = JSON.parse(localStorage.getItem('contractor') as string);

  return (
    <>
      {login ? <header className="header">
          <ModalObject selectedTypeValue={selectedTypeValue} setEstimateType={setModalTypes} types={estimateTypes}
                       isOpen={modal} setModal={setModal}/>
          <ModalEstimateType selectedTypeValue={selectedTypeValue} setSelectedTypeValue={setSelectedTypeValue}
                             types={estimateTypes} isOpen={modalTypes} setOpen={setModalTypes}/>
          <nav>
            {tabs.map((item: any) => (
              <Link
                key={item.id}
                className={`
                  nav__tab-box 
                  ${tab === item.id ? "current" : ""} 
                  ${profile.data?.contractor.status && profile.data?.contractor.status !== 73 ? styles.disabledTab : ''} 
                  tabs
                `}
                to={item.path}
              >
                <span>{item.name}</span>
                <span>{item.notifications}</span>
              </Link>
            ))}
            {isList && (
              <button
                className="control__creating--building"
                title="Окно создания Объекта"
                disabled={!contractorInfo?.contractor?.isVerified}
                onClick={() => setModal(true)}
              >
                Новый объект
              </button>
            )}
          </nav>
          <div></div>
          <div className="top-bar__user--box current">
          <span>
            {/*<span>ООО</span>*/}
            <span className="symbol__quotes">{contractorInfo?.contractor?.name}</span>
          </span>
            {!!user?.firstName ? <a href="/profile" title="Открыть страницу профиля">
                {user?.lastName}
                <span
                  className="symbol__space-thin--after"> {upperFirstLetter(user?.firstName)}.
              </span>{upperFirstLetter(user?.secondName)}.
              </a>
              :
              <a href="/profile" title="Открыть страницу профиля">
                {user?.login}
              </a>
            }
            <span>
              <span className="symbol__colon--after">Счёт</span>
              <span className="symbol__space-thin--after">{contractorInfo.amount}</span>руб.
            </span>
          </div>
        </header>
        :
        <header>
          <div className="logo__box">
            <p>Монтаж</p>
            <p>платформа</p>
            <p>Оплата</p>
          </div>
          <div></div>
          <h1>Качественный монтаж <span style={{fontFamily: 'bold-italic'}} className="symbol__mdash--before">гарантированная оплата</span>
          </h1>
        </header>}
    </>
  );
}
