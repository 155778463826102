import React, {useEffect, useState} from 'react';
import Billing from "../../components/Billing/Billing";
import {useDispatch, useSelector} from "react-redux";
import {getBilling} from "../../redux/billing/billingActions";
import {IBillingState} from "../../redux/billing/types";
import {useDebounced} from "../../hooks/useDebounced";
import {NewFooter} from "../../components/footer/NewFooter";

function BillingPage() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const userId = useSelector<any>(state => state.userReducer.user?.id) as string;
  const billing = useSelector<any>(state => state.billing) as IBillingState;
  const dispatch = useDispatch<any>();

  const debouncedStartDate = useDebounced(startDate, 1000);
  const debouncedEndDate = useDebounced(endDate, 1000);

  useEffect(() => {
    dispatch(getBilling(debouncedStartDate, debouncedEndDate, userId));
  }, [debouncedStartDate, debouncedEndDate, userId]);

  return (
    <>
      <main>
        {billing.loading && !billing.data && (
          <div><h1>LOADING...</h1></div>
        )}
        {!billing.loading && (billing.error || !billing.data) ? (
          <div><h1>ERROR...</h1></div>
        ) : (
          <>
            {billing.data && (
              <Billing
                billing={billing}
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={setStartDate}
                onChangeEndDate={setEndDate}
              />
            )}
          </>
        )}
      </main>
      <NewFooter/>
    </>
  );
}

export default BillingPage;