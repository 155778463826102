import React, {useState} from 'react';
import styles from './ChangeBlockConfirm.module.scss';
import Modal from "../../../../../Modal/Modal";
import {IProfileEmployee} from "../../../../../../types/Profile";
import {useDispatch} from "react-redux";
import {saveEmployee} from "../../../../../../redux/profile/profileActions";
import {baseNotification} from "../../../../../../utils/estimateUtils";

interface ChangeBlockConfirmProps {
  employee: IProfileEmployee
  onClose: Function
}

function ChangeBlockConfirm({employee, onClose}: ChangeBlockConfirmProps) {
  const [changing, setChanging] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose();
  };

  const handleChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (changing) {
      return;
    }

    setChanging(true);

    dispatch(saveEmployee({...employee, isBlocked: !employee.isBlocked})).then(() => {
      setChanging(false);
      baseNotification("Успешно", `Прораб ${employee.login} ${!employee.isBlocked ? 'заблокирован' : 'разблокирован'}`,"success");
      onClose();
    }).catch(() => {
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
      setChanging(false);
    });
  };

  return (
    <Modal title={`${employee.isBlocked ? 'Разблокировка' : 'Блокировка'} Прораба`}>
      <div className={styles.changeBlockModal}>
        <div className={styles.changeBlockModalNotification}>
          {employee.isBlocked ? (
            <>
              <p>
                Внимание!
              </p>
              <p>
                После разблокировки<br/>
                учётная запись Прораба<br/>
                должна быть подтверждена.
              </p>
            </>
          ) : (
            <div className={styles.changeBlockModalUsername}>
              {employee.lastName} {employee.firstName} {employee.secondName}
            </div>
          )}
        </div>
        <div className={styles.changeBlockModalContent}>
          {employee.isBlocked ? (
            <div className={styles.changeBlockModalUsername}>
              {employee.lastName} {employee.firstName} {employee.secondName}
            </div>
          ) : (
            <div className={styles.changeBlockModalText}>
              <p>Подтвердите блокировку учётной записи Прораба.</p>
            </div>
          )}
          <hr/>
          <div className={styles.changeBlockModalFooter}>
            <button
              type="button"
              disabled={changing}
              onClick={handleChange}
            >
              {employee.isBlocked ? 'Разблокировать' : 'Блокировать'}
            </button>
            <button type="button" onClick={handleClose}>Отмена</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeBlockConfirm;