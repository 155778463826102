import React from 'react';
import styles from './PlanningHeader.module.scss';
import {IBilling} from "../../../../types/Billing";
import {formatPrice} from "../../../../utils/formatPrice";

interface PlanningHeaderProps {
  billingData: IBilling
}

function PlanningHeader({billingData}: PlanningHeaderProps) {
  return (
    <div className={styles.header}>

      <div className={styles.headerCaptions}>
        <div>К авансированию</div>
        <div>Запланировано всего</div>
      </div>

      <div className={styles.headerTotal}>
        <div>{formatPrice(billingData.toPrepay)}</div>
        <div>{formatPrice(billingData.planTotal)}</div>
      </div>

    </div>
  );
}

export default PlanningHeader;