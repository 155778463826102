import React, {useState} from 'react';
import style from './breakdownStatusBar.module.scss'

const BreakdownStatusBar = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
      <React.Fragment>
        <div className={style.container}>
          <div className={style.status}>Статус разбивки</div>
          <div className={style.firstButtons}>
            <a className={`link-light ${style.linkObject}`} href="/_" title="Открыть страницу внесения изменений">История…</a>
            {open &&
                <div>
                  <button className="bttn__small" name="control__status--adjust-add" type="button"
                          title="Перевод в статус «Внесение замечаний»">Замечания…
                  </button>
                  <button className="bttn__small" name="control__status--accept" type="button"
                          title="Перевод в статус «Разбивка утверждена»">Утвердить
                  </button>
                  <button className="bttn__small" name="control__status--adjust" type="button"
                          title="Перевод в статус «Замечания по разбивке» с сохранением замечаний">Отправить
                  </button>
                  <button className="bttn__small" name="control__status--done" type="button"
                          title="Возврат в статус «Разбивка выполнена» без сохранений">Отмена
                  </button>
                  <button className="bttn__small" name="control__chain--creating" type="button"
                          title="Цепочка на основании выделенного Наряда">Новая Цепочка
                  </button>
                </div>
            }
          </div>
          <div style={{color: 'rgb(234, 232, 230)', fontSize: '14px', fontFamily: 'bold'}}>3 750 000</div>
          <div className={style.containerDate}>
            <div className={style.date}>07 фев</div>
            <div>
              {open &&
                  <div>
                    <button className="bttn__small" name="control__dates--change" type="button"
                            title="Войти в режим изменения дат">Изменение дат…
                    </button>
                    <button className="bttn__small" name="control__changes--save" type="button"
                            title="Сохранить изменения">Сохранить
                    </button>
                    <button className="bttn__small" name="control__mode--read" type="button"
                            title="Выйти из режима редактирования">Выйти
                    </button>
                  </div>
              }
            </div>
            <div className={style.date}>29 мар</div>
          </div>
        </div>
      </React.Fragment>
  )
}

export {BreakdownStatusBar};