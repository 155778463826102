import React from 'react';
import style from "../Equipment/equipment.module.scss";
import {TitleEquipment} from "../../../components/EstimateManagement/TitleEquipment";

const EquipmentSpecification = ({arr}: any) => {
  const arr2: never [] = [];

  return (
      <div className={style.header} id="content__grouping--1">
        {arr2.map((el) =>
            <TitleEquipment arr={arr} title="Название Системы"/>,
        )}
      </div>
  )
};

export {EquipmentSpecification}