interface ComplaintInitiatorParams{
    setCloseControlPanelHandler: any,
    changeComplaintStateToArbitrateHandler: any,
    changeComplaintStateToEndHandler: any,
    changeComplaintStateToSatisfiedHandler: any,
    setDowntimeDateHandler: any,
}

const ComplaintInitiator = (params: ComplaintInitiatorParams): JSX.Element => {
  const {setCloseControlPanelHandler, 
         changeComplaintStateToArbitrateHandler, 
         changeComplaintStateToEndHandler,
         changeComplaintStateToSatisfiedHandler,
         setDowntimeDateHandler} = params;
  return (
    <div className="manage-complaint__initiator">
        <button
            name="control__complaint--arbitrate"
            type="button"
            title="К решению Спора подключается Арбитр"
            onClick={changeComplaintStateToArbitrateHandler}
        >Арбитраж</button>
        <div className="block__downtime--date-end">
            <label className="symbol__colon--after">Окончание простоя</label>
            <input
                name="input__downtime--date-end"
                type="date"
                title="Дата окончания простоя"
                onChange={setDowntimeDateHandler}
                />
        </div>
        <button
            name="control__complaint--end"
            type="button"
            title="Закрытие Спора, вопрос решён"
            onClick={changeComplaintStateToEndHandler}
        >Закрыть спор</button>
        <button
            name="control__complaint--satisfied"
            type="button"
            title="Завершение Спора и окончание простоя"
            onClick={changeComplaintStateToSatisfiedHandler}
        >Завершить спор</button>
        <button
            name="control__manage-close"
            type="button"
            title="Сворачивание сектора без сохранения изменений"
            onClick={setCloseControlPanelHandler}
        >Отмена</button>
    </div>
  );
};

export default ComplaintInitiator;