import axios from "axios";
import Config from "../../config";
import {GET_BILLING_ERROR, GET_BILLING_START, GET_BILLING_SUCCESS} from "./billingActionTypes";
import moment from "moment/moment";

const getBillingStart = () => ({
  type: GET_BILLING_START
});

const getBillingError = () => ({
  type: GET_BILLING_ERROR
});

const getBillingSuccess = (payload) => ({
  type: GET_BILLING_SUCCESS,
  payload
});


export const getBilling = (startDate, endDate, userId) => async (dispatch, getState) => {
  dispatch(getBillingStart());

  try {
    const token = JSON.parse(localStorage.getItem("user"));

    const billing = await axios.post(Config.BACKEND_ADDRESS + `Contractor/GetBillings`, {
      dateFrom: startDate ? moment(startDate).toISOString() : null,
      dateTo: endDate ? moment(endDate).toISOString() : null,
      contractorId: userId
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      }
    });

    dispatch(getBillingSuccess(billing.data));
  } catch (e) {
    dispatch(getBillingError());
  }
};