import React, { Fragment, useState } from "react";
import styles from './Pagination.module.scss';

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

interface PaginationProps {
  totalRecords: number
  pageLimit: number
  pageNeighbours: number
  currentPage: number
  onPageChanged: (page: number) => void
  disabled?: boolean
}

function Pagination({
  totalRecords,
  pageLimit,
  pageNeighbours,
  currentPage,
  onPageChanged,
  disabled
}: PaginationProps) {
  const totalPages = Math.ceil(totalRecords / pageLimit);

  const gotoPage = (page: number) => {
    if (disabled) {
      return;
    }

    const newPage = Math.max(0, Math.min(page, totalPages));
    onPageChanged(newPage);
  };

  const handleClick = (page: number) => {
    gotoPage(page);
  };

  const handleMoveLeft = () => {
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = () => {
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <Fragment>
        <nav aria-label="pagination" className={`${styles.paginationWrap} ${disabled ? styles.disabled : ''}`}>
          <ul className={styles.pagination}>
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li key={index}>
                    <button
                      className={styles.button}
                      aria-label="Previous"
                      onClick={handleMoveLeft}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                );

              if (page === RIGHT_PAGE)
                return (
                  <li key={index}>
                    <button
                      className={styles.button}
                      aria-label="Next"
                      onClick={handleMoveRight}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                );

              return (
                <li
                  key={index}
                >
                  <button
                    className={`${styles.button} ${currentPage === page ? styles.active : ''}`}
                    onClick={e => handleClick(typeof page === 'string' ? 0 : page)}
                  >
                    {page}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
      </Fragment>
  );
}

export default Pagination;