import React, {useState} from 'react';
import styles from './ChangePassword.module.scss';
import Modal from "../../../../../Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {IProfileState} from "../../../../../../redux/profile/types";
import {saveProfilePassword} from "../../../../../../redux/profile/profileActions";
import {baseNotification} from "../../../../../../utils/estimateUtils";
import {smsSend} from "../../../../../../utils/sendSms";

interface ChangePasswordProps {
  onClose: Function
}

function ChangePassword({onClose}: ChangePasswordProps) {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [smsCode, setSmsCode] = useState<string>("");
  const profile = useSelector<any>(state => state.profile) as IProfileState;
  const dispatch = useDispatch<any>();

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose();
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangeSmsCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSmsCode(e.target.value);
  };

  const handleChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(e.target.value);
  };

  const askForSmsHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await smsSend(e, profile.data?.responsible.login)
    .catch(() => setError('Не удалось отправить СМС'))
  }

  const handleSaveNewPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setError('');

    if (saving) {
      return;
    }

    if (password !== passwordConfirm) {
      setError('Пароли не совпадают');
    }

    setSaving(true);

    dispatch(saveProfilePassword(password, profile.data?.responsible.id, smsCode)).then(() => {
      setSaving(false);
      baseNotification("Успешно", `Пароль успешно изменен`,"success");
      onClose();
    }).catch(() => {
      baseNotification("Ошибка", `Произошла ошибка`,"danger");
      setSaving(false);
    });
  };

  return (
    <Modal title="Смена пароля">
      <div className={styles.changePassword}>
        {error && (
          <div className={styles.changePasswordNotification}>
            {error}
          </div>
        )}
        <form className={styles.changePasswordContent}>

          <label>Новый пароль</label>
          <input
            name="pass__new"
            type="password"
            placeholder="(введите новый пароль)"
            value={password}
            onChange={handleChangePassword}
          />

          <label>Новый пароль (проверка)</label>
          <input
            name="pass__new-check"
            type="password"
            placeholder="(повторите новый пароль)"
            value={passwordConfirm}
            disabled={!password}
            onChange={handleChangePasswordConfirm}
          />

          <p className="header__sector">Код подтверждения</p>
          <button 
            name="sms__query" 
            type="button" 
            onClick={askForSmsHandler}
            disabled={!password || !passwordConfirm || saving}  
          >Прислать СМС</button>
          <input 
            name="sms__check" 
            type="password" 
            placeholder="(код из СМС)"
            onChange={handleChangeSmsCode}
          />
          <hr/>

          <button
            name="close__apply"
            type="button"
            onClick={handleSaveNewPassword}
            disabled={!password || !passwordConfirm || !smsCode || saving}
          >Сменить пароль</button>
          <button name="close__cancel" type="button" onClick={handleClose}>Отмена</button>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePassword;