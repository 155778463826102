import React, {memo, useState} from 'react';
import style from './equipment.module.scss';
import {EquipmentSpecification} from "../EquipmentSpecification/EquipmentSpecification";
import {EquipmentEstimate} from "../EquipmentEstimate/EquipmentEstimate";
import {useEquipmentFilter} from "../../../hooks/useFilter";

const Equipment = memo(({estimate}: any) => {
  const [tab, setTab] = useState('1');

  const arr = useEquipmentFilter(estimate);

  return (
      <div style={{marginTop: '12px'}}>
        <div className={style.container}>
          <div onClick={() => setTab('1')} className={`${tab === '1' ? style.activeTab : style.tab}`}>
            <label className={style.label}
                   htmlFor="tab__grouping--1"><span>Состав сметы по Системам</span>
            </label>
          </div>
          <div onClick={() => setTab('2')} className={`${tab === '2' ? style.activeTab : style.tab}`}>
            <label className={style.label} htmlFor="tab__grouping--2">
              <span>Состав сметы по Работам
              </span>
            </label>
          </div>
        </div>
        {tab === '1' && <EquipmentSpecification arr={arr} />}
        {tab === '2' && <EquipmentEstimate all={estimate} arr={arr} />}
      </div>
  )
})

export {Equipment}