import React from 'react';
import {IBillingPlanBuilding} from "../../../../types/Billing";
import PlanningListBuilding from "./components/PlanningListBuilding/PlanningListBuilding";

interface PlanningListProps {
  items: IBillingPlanBuilding[]
}

function PlanningList({items}: PlanningListProps) {
  return (
    <>
      {items.map(building => (
        <PlanningListBuilding key={building.entityId} building={building}/>
      ))}
    </>
  );
}

export default PlanningList;