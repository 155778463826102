import React from 'react';
import styles from './ParentDataBar.module.scss';
import {IJobPlanData} from '../../../../types/JobPlan';
import {handleDate} from '../../../../utils/estimateUtils';

interface ParentDataBarProps {
  jobPlanData: IJobPlanData
}

function ParentDataBar({jobPlanData}: ParentDataBarProps) {
  return (
    <div className={styles.bar}>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Карточка объекта">{jobPlanData.buildingName}</a>
      </div>

      <div className="limit__text--length">{jobPlanData.buildingAddress}</div>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Страница Сметы">{jobPlanData.estimateTitle}</a>
      </div>

      <div className="limit__text--length">{jobPlanData.estimateNote}</div>

      <div className="limit__text--length">
        <a className="link-light" href="/_" target="_blank" title="Карточка подрядчика">{jobPlanData.contractorName}</a>
      </div>

      <div>
        <span>{jobPlanData.contractorPhone}</span>
        {/*<span className="limit__text--length">Подрядчиков Ю. Л.</span>*/}
      </div>

      <div>
        <span title="Даты: Начало — Окончание">Плановые сроки</span>
        <span>{jobPlanData.workStartPlan ? handleDate(jobPlanData.workStartPlan) : '.. ... ..'}</span>
        <span className="symbol__mdash--before"></span>
        <span>{jobPlanData.workEndPlan ? handleDate(jobPlanData.workEndPlan) : '.. ... ..'}</span>
      </div>

    </div>
  );
}

export default ParentDataBar;