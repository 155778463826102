import React, {useCallback, useEffect, useRef, useState} from 'react';
import { NewFooter } from '../../components/footer/NewFooter';
import {useDispatch, useSelector} from 'react-redux';
import OrdersList from '../../components/OrdersList/OrdersList';
import {getOrdersList, resetOrdersList} from '../../redux/ordersList/ordersListActions';
import {IOrdersListState} from '../../redux/ordersList/types';
import { useDebounced } from '../../hooks/useDebounced';

export interface IOrdersListFilters {
  buildingFilter: string
  estimateFilter: string
  orderFilter: string
  brigadeFilter: string
  dateFrom: Date | null
  dateTo: Date | null
  orderStatus: number 
  hasOpenComplaints: boolean
  withDowntimeType: number
}

function OrdersListPage() {
  const [filters, setFilters] = useState<IOrdersListFilters>({
    buildingFilter: '',
    estimateFilter: '',
    orderFilter: '',
    brigadeFilter: '',
    dateFrom: null,
    dateTo: null,
    orderStatus: -1,
    hasOpenComplaints: false,
    withDowntimeType: -1
  });

  const [page, setPage] = useState<number>(1);
  const prevPage = useRef<number>();

  const ordersList = useSelector<any>(state => state.ordersList) as IOrdersListState;
  const dispatch = useDispatch<any>();

  const filtersDebounced = useDebounced(filters, 1000);

  const changeFilterField = useCallback((fieldName: string, fieldValue: string | boolean) => {
    setFilters(prev => ({...prev, [fieldName]: fieldValue}));
  }, []);

  useEffect(() => {
    if (prevPage.current !== page) {
      prevPage.current = page;
      dispatch(getOrdersList(page, filtersDebounced));
    } else {
      if (page !== 1) {
        setPage(1);
      } else {
        dispatch(getOrdersList(page, filtersDebounced));
      }
    }
  }, [page, filtersDebounced, dispatch])
  
  useEffect(() => {
    return () => {
      dispatch(resetOrdersList());
    }
  }, []);

  return (
    <>
      <main>
        {ordersList.loading && !ordersList.orders && (
          <div><h1>LOADING...</h1></div>
        )}
        {!ordersList.loading && ordersList.error ? (
          <div><h1>ERROR...</h1></div>
        ) : (
          <>
            {ordersList.orders !== null && (
              <OrdersList
                filters={filters}
                changeFilterField={changeFilterField}
                page={page}
                changePage={setPage}
              />
            )}
          </>
        )}
      </main>
      <NewFooter />
    </>
  );
}

export default OrdersListPage;