import React, {useEffect, useState} from 'react';
import style from "../../pages/EstimateManagement/History/history.module.scss";
import {parseDateHistory} from "../../utils/estimateUtils";
import {useDispatch} from "react-redux";
import {useDebounced} from "../../hooks/useDebounced";
import {setMessageItem} from "../../redux/estimate/actionEstimate";

const HistoryList = ({arr, edit, id}: any) => {
  return (
      <div className={style.openMenu}>
        {edit &&
            <div>
              <TextAreaHistory id={id} />
            </div>}
        {arr.map((el: any) =>
            <div
                style={{display: 'flex', flexDirection: 'column', padding: '10px'}}
                className={`message__event ${style.blockMessage}`}
            >
              <div style={{display: 'flex'}}>
                <div className={style.date}>{parseDateHistory(el?.message?.createdAt)}</div>
                <div className={style.contractor}>Подрядчик</div>
              </div>
              <div style={{fontSize: '12px'}}>
                {el?.message?.text}
              </div>
            </div>
        )}
      </div>
  )
}

const TextAreaHistory = ({mess, id}: any) => {
  const [message, setMessage] = useState<string>(mess);
  const dispatch = useDispatch<any>();
  const deboounced = useDebounced(message, 600);

  useEffect(() => {
    const result = {
      messageText: message,
      estimateId: id,
      documentId: null
    }
    if (deboounced) {
      dispatch(setMessageItem(result));
    }
  }, [deboounced])

  return (
      <textarea onChange={(e) => setMessage(e.target.value)} className={`message__new ${style.textarea}`} placeholder="(указание необязательно)">
        {message}
      </textarea>
  )
};

export { HistoryList }