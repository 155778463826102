import React from 'react';
import styles from './PlanningCaptions.module.scss';

function PlanningCaptions() {
  return (
    <div className={styles.captions}>
      <div title="Объекты бизнес-процесса: Стройка, Сметы, Договоры">Объект / Документы</div>
      <div>Дата<br/>документа</div>
      <div>Статус<br/>Наряда</div>
      <div>К<br/>авансированию</div>
      <div>Запланировано<br/>всего</div>
    </div>
  );
}

export default PlanningCaptions;