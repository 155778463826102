import React from 'react';
import {Dialog, DialogContentText} from "@mui/material";
import styles from './modalEstimateType.module.scss';

const ModalEstimateType = ({setOpen, isOpen, types, selectedTypeValue, setSelectedTypeValue}: any) => {
  if (!types) {
    return null;
  }

  const setSelect = (value: object) => {
    setSelectedTypeValue(value)
  }

  return (
    <Dialog open={isOpen} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "780px",
          maxWidth: "780px",
          maxHeight: '480px',
          height: "480px",
          background: "#eae8e6",
        },
      },
    }}>
      <div className={styles.container}>
        <h3 className={`modal__window--title ${styles.title}`}>Типы Смет</h3>

        <div className={styles.description}>
          Выберите тип сметы.
        </div>
        <DialogContentText>
          <div className={styles.content}>
            {types.map((el: any) =>
              <div className={styles.item}>
                <legend className={styles.lagendName}>{el.name}</legend>
                {el.items.map((item: any) =>
                  <label onClick={() => setSelect(item)} className={`${styles.label} clickable`}>
                    <input className={styles.input} type="radio" name="type-switch" value={item.code} />{item.name}
                  </label>
                )}
              </div>
            )}
          </div>
        </DialogContentText>
        <div className={styles.buttonContainer}>
          <button onClick={() => setOpen(false)} disabled={!selectedTypeValue} type="button" className="control__modal--close">Готово</button>
          <button onClick={() => setOpen(false)} type="button" className="control__modal--close">Отмена</button>
        </div>
      </div>
    </Dialog>
  )
};

export {ModalEstimateType};