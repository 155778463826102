import React from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import styles from './DatePicker.module.scss';
import IconCalendar from "../Icons/IconCalendar";
import useUniqueId from "../../hooks/useUniqueId";

interface DatePickerProps {
  className?: string,
  value: Date | null,
  onChangeDate: Function
}

function DatePicker({className, value, onChangeDate} : DatePickerProps) {
  const id = useUniqueId();

  return (
    <label className={`${styles.datePicker} ${className}`} htmlFor={id}>
      <ReactDatePicker
        id={id}
        selected={value}
        locale={ru}
        dateFormat="dd.MM.yy"
        onChange={(date) => {
          onChangeDate(date);
        }}
      />
      <IconCalendar/>
    </label>
  );
}

export default DatePicker;