import React, {useMemo} from 'react';
import styles from './StatusBar.module.scss';
import {useSelector} from 'react-redux';
import {IJobPlanChainOrder} from '../../../../types/JobPlan';
import {getDaysDiff, getDowntimeDays, parseDateString} from '../../../../utils/estimateUtils';
import {formatPrice} from '../../../../utils/formatPrice';
import {IStatus} from '../../../../types/Status';

function StatusBar() {
  const order = useSelector<any>(state => state.order.order) as IJobPlanChainOrder;
  const orderStatuses = useSelector<any>(state => state.order.orderStatuses) as IStatus[];

  const statusText = useMemo(() => {
    if (!orderStatuses) {
      return '';
    }

    const statusData = orderStatuses.filter(item => item.currentStateCode === order.status)?.[0];

    if (statusData) {
      return statusData.currentStateText;
    }
  }, [order.status, orderStatuses]);

  return (
    <div className={styles.statusBar}>

      <div>{order.orderName}</div>

      <div>
        <span>{order.workStartPlan ? parseDateString(order.workStartPlan) : '.. ...'}</span>
        <span className="symbol__mdash--before"></span>
        <span>{order.workEndPlan ? parseDateString(order.workEndPlan) : '.. ...'}</span>
      </div>

      <div>
        <span>Продолжительность</span>
        <span>{getDaysDiff(order.workStartPlan, order.workEndPlan)}</span>
        <span>дн.</span>
      </div>

      <div>
        <span>{formatPrice(order.cost)}</span>
        <span>{order.status === 40 ? 'оплачено' : 'не оплачено'}</span>
      </div>

      <div>
        {statusText && (
          <span className="text__status--order">{statusText}</span>
        )}
      </div>

      <div>

        {/*"<!--*/}
        {/*  Нет Бригады:          (бригада не подобрана)*/}
        {/*  Статус «К запуску»:   (подбор бригады)*/}
        {/*  Есть Бригада, ссыдка: ФИО Бригадира*/}
        {/*-->*/}
        {/*<!-- <span>(бригада не подобрана)</span> -->*/}
        {/*<!-- <span>(подбор бригады)</span> -->"*/}
        {order.brigadeName ? (
          <a className="link-light" href="/_" title="Открыть страницу Бригады">{order.brigadeName}</a>
        ) : (
          <>
            {order.status === 57 && (
              <span>(бригада не подобрана)</span>
            )}
            {order.status === 40 && (
              <span>(подбор бригады)</span>
            )}
          </>
        )}

      </div>

      {/*'<!-- Простой. -->'*/}
      <div title="Всего дней простоя в работе">
        <span>Простой</span>

        {/*'<!-- Если нет простоя, выводится тире. -->'*/}
        {/*<!-- <span className="symbol__mdash--before"></span> -->*/}
        <span>{getDowntimeDays(order.workStartPlan)}</span>

        <span>дн.</span>
      </div>

    </div>
  );
}

export default StatusBar;