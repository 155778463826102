import React, {useState} from 'react';
import style from "../Equipment/equipment.module.scss";
import {TitleEquipment} from "../../../components/EstimateManagement/TitleEquipment";

const EquipmentEstimate = ({arr, all}: any) => {
  const [open, setOpen] = useState({first: false, second: false});

  const showInfoFirst = () => {
    setOpen({...open, first: !open.first});
  };

  return (
      <div className={style.header} id="content__grouping--1">
        {arr.map((el: any) =>
            <TitleEquipment arr={arr} estimate={all} showInfo={showInfoFirst} title={el} />,
        )}
      </div>
  )
};

export {EquipmentEstimate}