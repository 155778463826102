import style from "../../pages/EstimateManagement/Equipment/equipment.module.scss";
import React, {useState} from "react";
import {ListEquipment} from "./ListEquipment";
import {useFilter} from "../../hooks/useFilter";

const TitleEquipment = ({title, arr, estimate}: any) => {

  const [open, setOpen] = useState(false);

  const filtered = useFilter(estimate, title,'equipment');

  function calculation() {
    let all = 0
    filtered.forEach((el: any) => {
      all += el.count
    })
    return all
  }

  return (
      <React.Fragment>
      <div className={`group__header--system ${style.headerContainer}`}>

        <div style={{display: 'flex', alignItems: 'center', marginRight: '6px', paddingLeft: '4px'}}
             className={`${open ? 'open' : ''}`}
             onClick={() => setOpen(!open)}>
          <span className="circle-triangle" title="Развернуть / Свернуть"></span>
        </div>

        <div className={`${style.headerSystem}`}>{title}</div>
        <div className={style.subitems}>
          <div style={{marginLeft: '40px', width: '96px'}}>
            <span style={{marginRight: '16px'}} className="symbol__colon--after">Позиций</span>
            <span>{filtered.length}</span>
          </div>
          <div style={{width: '135px'}}>
            <span style={{marginRight: '16px'}} className="symbol__colon--after">Количество</span>
            <span>{calculation()}</span>
          </div>
          <div style={{marginRight: '10px', width: '125px'}}>
            <span style={{marginRight: '16px'}} className="symbol__colon--after">Сумма</span>
            <span>000 000</span>
          </div>
        </div>
      </div>
        {open &&
            <ListEquipment arr={filtered} />
        }
      </React.Fragment>
  )
}

export { TitleEquipment }