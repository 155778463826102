import React from 'react';
import { useSelector } from "react-redux";
import ProfileContractor from "../../components/Profile/ProfileContractor";
import {NewFooter} from "../../components/footer/NewFooter";

function ProfilePage() {
  const user = useSelector((state: any) => state.userReducer.user);
  return (
    <>
      <main>
        <ProfileContractor/>
      </main>
      <NewFooter />
    </>
  );
}

export default ProfilePage;